import React, { ReactElement } from "react"
import { Link } from "react-router-dom"
import { app } from "~context/core"
import { modal } from "~context/modal"
import { Dialog } from "~parts/dialog"
import { SpinnerButton } from "~parts/spinner"
import { useBinder } from "~util/form"
import { formatBytes, toHoursString } from "~util/format"
import { useLoader, useLoader2, useSender } from "~util/hook"
import { CastifyRoute } from "~core/castify-route"

const maxMediaBytes = 5 * 1024 ** 3

const muxOverheads: Record<string, number> = {
  "MKV"  : 0.960,
  "MP4"  : 0.967,
  "MP2T" : 1.125,
  "ASF"  : 1.002,
}

export function ExportDialog(props: { id: string }): ReactElement {

  const [names] = useLoader(() => app.api.listVariantNames(props.id))

  const name = useBinder(names?.value?.[0] ?? "")

  const type = useBinder("MP4")

  const sender = useSender(async () => {
    await app.api.requestExport(
      props.id,
      name.value,
      type.value ? type.value: undefined
    )
    modal.show(
      <Dialog title="エクスポート処理が予約されました">
        <div className="m-3">
          サイドメニューの<Link to={CastifyRoute.projects.project.jobs.getPath(app.project.id)}>ジョブ</Link>から進行状況をご確認ください。
          <small className="mt-2 d-block text-muted">
            処理が完了するまでに動画の長さに応じて 5~30 分程度かかります。
          </small>
        </div>
        <div className="mt-2 p-2 d-flex justify-content-end border-top">
          <button onClick={modal.dismiss} type="button" className="btn btn-secondary btn-sm">
            閉じる
          </button>
        </div>
      </Dialog>
    )
  })

  const [info] = useLoader2(async (id, name) => {
    if (name === "") {
      return
    }
    else {
      return app.api.getVariantInfo(id, name)
    }
  }, [props.id, name.value])

  const large = (info?.value?.size ?? 0) > maxMediaBytes && type.value !== ""

  return (
    <Dialog title="メディアのエクスポート" style={{width: "30rem"}}>
      <div className="mx-3 mt-3 d-flex flex-column">
        <label className="form-label">メディアのバリエーションを選択</label>
        <select {...name} className="form-select mt-1" disabled={!names?.value}>
        {
          names?.value?.map(e => <option key={e} value={e}>{e}</option>)
        }
        </select>
        <div className="align-self-end mt-2 me-1 small text-muted">
          <i className="fa-regular fa-clock me-1" />{info?.value ? toHoursString(info.value.duration): "-"}
        </div>
      </div>
      <div className="mx-3 mt-1 d-flex flex-column">
        <label className="form-label">ファイル形式</label>
        <select {...type} className="form-select mt-1">
          <option value="MP4">
            MP4
          </option>
          <option value="MKV">
            Matroska (.mkv)
          </option>
          <option value="MP2T">
            MPEG2-TS (.ts)
          </option>
          <option value="ASF">
            Windows Media (.wmv, .wma)
          </option>
          <option value="">
            Fragmented MP4
          </option>
        </select>
        <div className="align-self-end mt-2 me-1 small text-muted">
          予測サイズ {info?.value?.size ? formatBytes(info.value.size * (muxOverheads[type.value] ?? 1)): "-"}
        </div>
      </div>
      {
        large && (
          <div className="mx-3 mt-3 alert alert-danger small p-2 d-flex">
            <div className="align-self-center ms-1 me-3">
              <i className="fa-solid fa-circle-exclamation" />
            </div>
            <div>
              <strong>{maxMediaBytes / 1024**3}GiB</strong> を超える変換は指定の形式ではご利用いただけません。サイズ無制限の <strong>Fragmented MP4</strong> をご選択ください。
            </div>
          </div>
        )
      }
      <div className="mt-3 p-2 d-flex justify-content-end border-top">
        <SpinnerButton
          onClick={modal.dismiss}
          disabled={!sender.loaded}
          className="btn btn-secondary btn-sm"
        >
          キャンセル
        </SpinnerButton>
        <SpinnerButton
          onClick={sender.send}
          animated={!sender.loaded}
          disabled={!name.value || !info || large}
          className="btn btn-primary btn-sm ms-2"
        >
          エクスポートを開始
        </SpinnerButton>
      </div>
    </Dialog>
  )
}
