import React from "react"
import { AudioTranscoderPreset, BroadcastOptionsForm, PresetAudioOutput, PresetVideoOutput, VideoTranscoderPreset } from "~core/castify/api"
import { PlanBind, PlanType } from "~core/castify/api/msg/plan"
import { useFormContext } from "react-hook-form"
import { FieldErrors } from "react-hook-form/dist/types/errors"

type ListSetting = {
  index: number,
}

export const BroadcastOptionForm = (props: { plan?: PlanBind }): JSX.Element => {

  const id = React.useId()
  const form = useFormContext<BroadcastOptionsForm>()
  const [variants, setVariants] = React.useState<ListSetting[]>([{ index: 0 }])
  const [previews, setPreviews] = React.useState<ListSetting[]>([])

  const onDelete = (index: number, kind: "variants" | "previews") => {
    if (!confirm("この設定を削除します。よろしいですか？")) {
      return
    }
    if (kind === "variants") {
      setVariants(variants.filter(x => x.index !== index))
      const values = form.getValues("options.variants")
      if (values === undefined) {
        return
      }
      delete values[index]
      form.setValue(`options.variants`, values)
    } else {
      setPreviews(previews.map(x => x.index === index ? { index: x.index } : x))
      const values = form.getValues("options.previews")
      if (values === undefined) {
        return
      }
      delete values[index]
      form.setValue(`options.previews`, values)
    }
  }

  return <>
    <div className="d-flex flex-column gap-3">
      <label className="form-label">
        <h5>映像・音声の設定</h5>
      </label>

      {variants.map(variant => (
        <VariantSelection key={`${id}-variants-${variant.index}`} setting={variant} plan={props.plan} onDelete={() => onDelete(variant.index, "variants")} />
      ))}

      <button
        type="button"
        className="btn btn-outline-primary"
        onClick={() => setVariants(variants.concat([{ index: (variants[variants.length - 1]?.index ?? 0) + 1 }]))}
      >
        設定を追加
      </button>
    </div>
  </>
}

const VariantSelection = (props: { setting: ListSetting, onDelete: () => void, plan?: PlanBind }) => {

  const id = React.useId()
  const form = useFormContext<BroadcastOptionsForm>()
  const errorVariant = form.formState.errors.options?.variants?.[props.setting.index]
  const errorVideo = errorVariant?.video as FieldErrors<PresetVideoOutput>
  const errorAudio = errorVariant?.audio as FieldErrors<PresetAudioOutput>

  return <div className="d-flex flex-column gap-1">
    <div className="form-floating">
      <input
        type="text"
        className="form-control"
        disabled={form.formState.isSubmitting}
        id={`${id}-variants-${props.setting.index}-name`}
        {...form.register(`options.variants.${props.setting.index}.name`, {
          required: {
            value: true,
            message: "必須項目です。",
          },
        })}
      />
      <label htmlFor={`${id}-variants-${props.setting.index}-name`} className="form-label">設定名</label>

      {errorVariant?.name?.message &&
        <p className="text-danger">{errorVariant.name.message}</p>
      }
    </div>

    <div>
      <input type="hidden" defaultValue="preset" {...form.register(`options.variants.${props.setting.index}.video.type`)} />

      <div className="form-floating">
        <select
          className="form-select" id={`${id}-variants-${props.setting.index}-video`}
          defaultValue={VideoTranscoderPreset.PassThrough}
          {...form.register(`options.variants.${props.setting.index}.video.preset`, {
              validate: {
                trial: (value) => {
                  if (props.plan?.type !== PlanType.Trial) {
                    return true
                  }
                  if (value === VideoTranscoderPreset.PassThrough || value === VideoTranscoderPreset.None) {
                    return true
                  }
                  return "トライアルモードではエンコーダーを設定できません。"
                },
              },
            },
          )}
        >
          {Object.keys(VideoTranscoderPreset).map(x =>
            <option key={x} value={x}>{x}</option>,
          )}
        </select>
        <label htmlFor={`${id}-variants-${props.setting.index}-video`} className="form-label">映像</label>
      </div>

      {errorVideo?.preset?.message &&
        <p className="text-danger">{errorVideo.preset.message}</p>
      }
    </div>

    <div>
      <input type="hidden" defaultValue="preset" {...form.register(`options.variants.${props.setting.index}.audio.type`)} />

      <div className="form-floating">
        <select
          className="form-select"
          id={`${id}-variants-${props.setting.index}-audio`}
          defaultValue={AudioTranscoderPreset.PassThrough}
          {...form.register(`options.variants.${props.setting.index}.audio.preset`, {
            validate: {
              trial: (value) => {
                if (props.plan?.type !== PlanType.Trial) {
                  return true
                }
                if (value === AudioTranscoderPreset.PassThrough || value === AudioTranscoderPreset.None) {
                  return true
                }
                return "トライアルモードではエンコーダーを設定できません。"
              },
            },
          })}>
          {Object.keys(AudioTranscoderPreset).map(x =>
            <option key={x} value={x}>{x}</option>,
          )}
        </select>
        <label htmlFor={`${id}-variants-${props.setting.index}-audio`} className="form-label">音声</label>
      </div>
    </div>

    {errorAudio?.preset?.message &&
      <p className="text-danger">{errorAudio.preset.message}</p>
    }

    <button type="button" className="btn btn-danger" onClick={props.onDelete}>
      この設定を削除
    </button>
  </div>
}

/*
const PreviewSelection = (props: { setting: ListSetting, onDelete: () => void, plan?: PlanBind }) => {

  const id = React.useId()
  const form = useFormContext<BroadcastOptionsForm>()
  const errorPreview = form.formState.errors.options?.previews?.[props.setting.index]

  return <div className="d-flex flex-column gap-1">
    <div className="form-floating">
      <input
        type="text"
        className="form-control"
        disabled={form.formState.isSubmitting}
        id={`${id}-previews-${props.setting.index}-name`}
        {...form.register(`options.previews.${props.setting.index}.name`)}
      />
      <label htmlFor={`${id}-previews-${props.setting.index}-name`} className="form-label">設定名</label>

      {errorPreview?.name?.message &&
        <p className="text-danger">{errorPreview.name.message}</p>
      }
    </div>

    <button type="button" className="btn btn-danger" onClick={props.onDelete}>
      この設定を削除
    </button>
  </div>
}
*/
