import { useParams } from "react-router-dom"
import { DiskEntry } from "~core/castify/api/msg/disk"

const p = (projectId: string) => `/projects/${projectId}`
const a = (accountId: string) => `/accounts/${accountId}`

export const CastifyRoute = {
  about: {
    path: "/about"
  },

  register: {
    path: "/register",
    pathWithFrom: (from: string) => "/register?from=" + encodeURIComponent(from),
  },

  player: {
    path: "/player/:broadcastId",
    getPath: (broadcastId: string) => `/player/${broadcastId}`,
    usePath: useParams as () => Record<"broadcastId", string>
  },

  me: {
    path: "/me",

    invitations: {
      path: "/me/invitations"
    }
  },

  accounts: {
    path: "/accounts",

    account: {
      path: "/accounts/:accountId/*",
      getPath: (accountId: string) => a(accountId),
      usePath: useParams as () => Record<"accountId", string>,

      subscriptions: {
        path: "subscriptions",
        getPath: (accountId: string) => a(accountId) + "/subscriptions",

        subscription: {
          path: "subscriptions/:subscriptionId",
          getPath: (accountId: string, subscriptionId: string) => a(accountId) + `/subscriptions/${subscriptionId}`,
          usePath: useParams as () => Record<"accountId" | "subscriptionId", string>,
        }
      },

      invoices: {
        path: "invoices",
        getPath: (accountId: string) => a(accountId) + "/invoices",

        invoice: {
          path: "invoices/:chargeId",
          getPath: (accountId: string, chargeId: number) => a(accountId) + `/invoices/${chargeId}`,
          usePath: useParams as () => Record<"accountId" | "chargeId", string>,
        }
      },

      paymentMethods: {
        path: "payment-methods",
        getPath: (accountId: string) => a(accountId) + "/payment-methods",
      },

      members: {
        path: "members",
        getPath: (accountId: string) => a(accountId) + "/members",
      }
    }
  },

  projects: {
    path: "/projects",

    project: {
      path: "/projects/:projectId/*",
      getPath: (projectId: string) => p(projectId),
      usePath: useParams as () => Record<"projectId", string>,

      overview: {
        path: "overview",
        getPath: (projectId: string) => p(projectId) + "/overview",
      },

      recorder: {
        path: "recorder",
        getPath: (projectId: string) => p(projectId) + "/recorder",
      },

      newBroadcast: {
        path: "new-broadcast",
        getPath: (projectId: string) => p(projectId) + `/new-broadcast`,

        import: {
          path: "new-broadcast/import/:path",
          getPath: (projectId: string, disk: DiskEntry) => p(projectId) + `/new-broadcast/import/${disk.path}`,
          usePath: useParams as () => Record<"projectId" | "path", string>,
        }
      },

      broadcasts: {
        path: "broadcasts",
        getPath: (projectId: string) => p(projectId) + "/broadcasts",

        broadcast: {
          path: "broadcasts/:broadcastId/*",
          getPath: (projectId: string, broadcastId: string) => p(projectId) + `/broadcasts/${broadcastId}`,
          usePath: useParams as () => Record<"projectId" | "broadcastId", string>,

          overview: {
            path: "overview",
            getPath: (projectId: string, broadcastId: string) => p(projectId) + `/broadcasts/${broadcastId}/overview`,
          },

          logs: {
            path: "logs",
            getPath: (projectId: string, broadcastId: string) => p(projectId) + `/broadcasts/${broadcastId}/logs`,
          },

          debug: {
            path: "debug",
            getPath: (projectId: string, broadcastId: string) => p(projectId) + `/broadcasts/${broadcastId}/debug`,
          },
        }
      },

      webhooks: {
        path: "webhooks",
        getPath: (projectId: string) => p(projectId) + "/webhooks",

        webhook: {
          path: "webhooks/:webhookId/*",
          getPath: (projectId: string, webhookId: string) => p(projectId) + `/webhooks/${webhookId}`,
          usePath: useParams as () => Record<"projectId" | "webhookId", string>,

          overview: {
            path: "overview",
            getPath: (projectId: string, webhookId: string) => p(projectId) + `/webhooks/${webhookId}/overview`,
          },

          logs: {
            path: "logs",
            getPath: (projectId: string, webhookId: string) => p(projectId) + `/webhooks/${webhookId}/logs`,
          },

          debug: {
            path: "debug",
            getPath: (projectId: string, webhookId: string) => p(projectId) + `/webhooks/${webhookId}/debug`,
          },
        }
      },

      tokens: {
        path: "tokens",
        getPath: (projectId: string) => p(projectId) + "/tokens",
      },

      jobs: {
        path: "jobs",
        getPath: (projectId: string) => p(projectId) + "/jobs",

        job: {
          path: "jobs/:jobId",
          getPath: (projectId: string, jobId: string) => p(projectId) + `/jobs/${jobId}`,
          usePath: useParams as () => Record<"projectId" | "jobId", string>
        }
      },

      auditLogs: {
        path: "audit-logs",
        getPath: (projectId: string) => p(projectId) + "/audit-logs",

        auditLog: {
          path: "audit-logs/:logId",
          getPath: (projectId: string, logId: string) => p(projectId) + "/audit-logs/" + logId,
          usePath: useParams as () => Record<"projectId" | "logId", string>
        },
      },

      members: {
        path: "members",
        getPath: (projectId: string) => p(projectId) + "/members",
      },

      invitations: {
        path: "invitations",
        getPath: (projectId: string) => p(projectId) + "/invitations",
      },

      usage: {
        path: "usage",
        getPath: (projectId: string) => p(projectId) + "/usage",
      },

      catalog: {
        path: "catalog",
        getPath: (projectId: string) => p(projectId) + "/catalog",
      },

      disk: {
        path: "disks",
        getPath: (projectId: string) => p(projectId) + "/disks/default",

        file: {
          path: "disks/default/:file",
          getPath: (projectId: string, file: string) => p(projectId) + "/disks/default/" + file,
          usePath: useParams as () => Record<"projectId" | "file", string>,
        },
      },

      supports: {
        path: "supports",
        getPath: (projectId: string) => p(projectId) + "/supports",

        inquiry: {
          path: "supports/inquiry",
          getPath: (projectId: string) => p(projectId) + "/supports/inquiry",
        },
      },

      debug: {
        path: "debug",
        getPath: (projectId: string) => p(projectId) + "/debug",
      },

    }
  }
}
