import firebase from "firebase/compat/app"
import React, { ReactElement, useEffect } from "react"
import { concat, defer, from, Observable, ReplaySubject } from "rxjs"
import * as Rx from "rxjs/operators"
import { app, Session } from "~context/core"
import { EMailNotTrustedPage } from "~pages/auth/email-trust"
import { EMailVerificationPage } from "~pages/auth/email-verify"
import { LoginPage } from "~pages/auth/login"
import { useBehavior } from "~util/form"
import { letterBox } from "~util/style"
import { Box } from "~parts/box"
import { Spinner } from "~parts/spinner"
import { Outlet } from "react-router-dom"

const fbUser = new ReplaySubject<firebase.User | null>(1)

firebase.auth().onIdTokenChanged((e) => {
  fbUser.next(e)
})

function load(user: firebase.User | null): Observable<Session> {
  return concat(
    from(Session.fromUser(user)).pipe(
      Rx.switchMap((session) => session.liveness.pipe(Rx.startWith(session)))
    ),
    defer(() => load(user))
  )
}

export function SessionGuard(): ReactElement {
  useEffect(() => {
    const subscription = fbUser.pipe(Rx.switchMap(load)).subscribe({
      next(session) {
        app.$session.next(session)
      },
      complete() {
        app.$session.next(null)
      },
    })

    return () => subscription.unsubscribe()
  }, [])

  const session = useBehavior(app.$session)
  if (session === null) {
    return (
      <Box height="100%" {...letterBox}>
        <Spinner />
      </Box>
    )
  }
  if (session.user === undefined) {
    return <LoginPage />
  }
  if (session.emailNotTrusted) {
    return <EMailNotTrustedPage />
  }
  if (!session.user.emailVerified) {
    return <EMailVerificationPage user={session.user} />
  } else {
    return <><Outlet /></>
  }
}
