import firebase from "firebase/compat/app"
import React, { ReactElement, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Box } from "~parts/box"
import { useQuery } from "~parts/paged"
import { SpinnerButton } from "~parts/spinner"
import { useBinder } from "~util/form"
import { useSender } from "~util/hook"
import { AlertBox, AuthBox } from "./parts"
import { CastifyRoute } from "~core/castify-route"

const fbAuth = firebase.auth()

function RegisterByEMailForm(props: { destination: string }): ReactElement {
  const username = useBinder()
  const password = useBinder()

  const empty = username.value === "" || password.value === ""
  const navigate = useNavigate()

  const sender = useSender(async () => {
    let credential: firebase.auth.UserCredential
    try {
      credential = await fbAuth.createUserWithEmailAndPassword(
        username.value,
        password.value,
      )
    } catch (error: any) {
      if (error?.code === "auth/email-already-in-use") {
        throw new Error("このメールアドレスは既に登録済みです。")
      } else {
        throw error
      }
    }
    const token = await credential.user?.getIdToken()
    if (token === undefined) {
      throw new Error("This is bad.")
    }
  })

  if (sender.result?.failed === false) {
    navigate(props.destination)
    return <></>
  }

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <fieldset disabled={!sender.loaded}>
        <div className="input-group">
          <span className="input-group-text">
            <i className="fas fa-user" />
          </span>
          <input
            {...username}
            type="text"
            className="form-control"
            placeholder="メールアドレス"
          />
        </div>
        <div className="input-group mt-2">
          <span className="input-group-text">
            <i className="fas fa-lock" />
          </span>
          <input
            {...password}
            type="password"
            className="form-control"
            placeholder="パスワード"
          />
        </div>
        <div className="mt-3">
          <SpinnerButton
            onClick={sender.send}
            animated={!sender.loaded}
            disabled={empty}
            className="form-control btn btn-primary"
          >
            登録
          </SpinnerButton>
        </div>
        {sender.result?.failed && (
          <Box marginTop="1.5rem">
            <AlertBox>
              {sender.result.error.message ?? "登録に失敗しました。"}
            </AlertBox>
          </Box>
        )}
      </fieldset>
    </form>
  )
}

export function RegisterPage(): ReactElement {
  const from = useQuery().get("from") ?? CastifyRoute.projects.path
  const [usePassword, setUsePassword] = useState<boolean>(false)

  return (
    <AuthBox>
      <Box marginTop="1rem" marginBottom="1rem">
        <Link to={from}>
          <button className="form-control btn btn-primary">Google/GitHub アカウントで登録/ログイン</button>
        </Link>
      </Box>
      <hr />
      {usePassword
        ? <RegisterByEMailForm destination={from} />
        : (
          <Box textAlign="center" fontSize="0.7rem">
            <button className="btn btn-secondary btn-sm" onClick={() => setUsePassword(true)}>
              パスワードで登録 (非推奨)
            </button>
          </Box>
        )
      }
      <Box textAlign="right" marginTop="0.5rem" fontSize="0.7rem">
        <Link to={CastifyRoute.projects.path + "?mode=password"}>パスワードでログイン</Link>
      </Box>
    </AuthBox>
  )
}
