import firebase from "firebase/compat/app"
import React, { ReactElement } from "react"
import { modal } from "~context/modal"
import { Box, VBox } from "~parts/box"
import { Dialog } from "~parts/dialog"
import { SpinnerButton } from "~parts/spinner"
import { sleep } from "~util/async"
import { useSender } from "~util/hook"
import { elevate } from "~util/style"
import { AlertBox } from "./parts"

export function EMailVerificationPage(props: {
  user: firebase.User
}): ReactElement {
  const sender = useSender(async () => {
    await sleep(1000)
    await props.user.sendEmailVerification()
    modal.show(
      <Dialog title="確認メールの送信">
        <Box className="p-3" maxWidth="30rem">
          <p>
            確認メールが送信されました。メール内のリンクを開くことで確認の手続きが完了します。
          </p>
          <p>
            手続き完了の後、このページをリロードすることで Castify
            をご利用を開始できます。
          </p>
        </Box>
      </Dialog>
    )
  })

  return (
    <Box
      className="row g-0 justify-content-md-center align-items-md-center"
      minHeight="100%"
      backgroundColor="#f1f1f1"
    >
      <Box className="col-md-4 col-sm-12">
        <VBox {...elevate(2)} backgroundColor="white" className="py-5 px-4">
          <Box className="h5" alignSelf="center">
            メールアドレス所有者の確認
          </Box>
          <p className="text-muted small mt-3">
            Castify のご利用の前に、ご入力頂いたメールアドレス{" "}
            <code>{props.user.email}</code>{" "}
            に送信される確認メールを通して本人確認を行う必要があります。
          </p>
          <Box className="mt-3" alignSelf="center">
            <SpinnerButton
              className="btn btn-primary"
              onClick={sender.send}
              animated={!sender.loaded}
            >
              確認メールを送信
            </SpinnerButton>
          </Box>
          {sender.result?.failed && (
            <Box className="mt-3">
              <AlertBox>
                確認メールの送信に失敗しました。時間をおいて再度お試しください。
              </AlertBox>
            </Box>
          )}
        </VBox>
      </Box>
    </Box>
  )
}
