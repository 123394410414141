import { CSSProperties, FormEvent, ReactElement, ReactNode, useEffect } from "react"
import { modal } from "~context/modal"
import { Sender, useSender } from "~util/hook"
import { Box, HBox, VBox } from "./box"
import { SpinnerButton } from "./spinner"

export function ModalBackdrop(props: { children?: ReactNode }): ReactElement {
  return (
    <div className="cas-modal-backdrop">
      <VBox alignItems="center" marginTop="5rem">
        {props.children}
      </VBox>
    </div>
  )
}

export type DialogProps = {
  style?: CSSProperties
  title?: string
  onEnd?(): void
  onCancel?(): void
}

export function Dialog(props: DialogProps & { children?: ReactNode }): ReactElement {
  useEffect(() => props.onEnd, [props.onEnd])
  return (
    <div className="mt-5 border border-1 rounded-1 d-flex flex-column bg-white" style={props.style}>
      {props.title && (
        <HBox
          minWidth="20rem"
          padding="0.66rem"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box fontSize="14pt" fontWeight="bold">
            {props.title}
          </Box>
          <div>
            <button
              onClick={props.onCancel ?? modal.dismiss}
              type="button"
              className="btn-close"
            />
          </div>
        </HBox>
      )}
      <Box borderTop="1px solid #e0e0e0">{props.children}</Box>
    </div>
  )
}

type SenderDialogProps = DialogProps & { sender: Sender<any> }

export function SenderDialog(props: SenderDialogProps & { disabled?: boolean, children?: ReactNode }): ReactElement {
  const { sender: base, ...rest } = props

  const sender = useSender(base.send)

  function submit(event: FormEvent) {
    event.preventDefault()
    if (props.onCancel) {
      sender.send()
    }
    else {
      sender.send().then(modal.dismiss)
    }
  }
  return (
    <Dialog {...rest}>
      <form onSubmit={submit}>
        {props.children}
        <div className="mt-2 p-2 d-flex align-items-center justify-content-end border-top">
          {sender.result?.failed && (
            <div className="flex-grow-1 text-danger small pe-2">
              <i className="fas fa-exclamation-circle me-2" />
              {sender.result.error?.message ?? "不明なエラーです。"}
            </div>
          )}
          <SpinnerButton
            disabled={!sender.loaded}
            className="btn btn-secondary btn-sm"
            onClick={props.onCancel ?? modal.dismiss}
          >
            Cancel
          </SpinnerButton>
          <SpinnerButton
            type="submit"
            animated={!sender.loaded}
            className="btn btn-primary btn-sm ms-2"
            disabled={props.disabled === true}
          >
            OK
          </SpinnerButton>
        </div>
      </form>
    </Dialog>
  )
}

type ActionDialogProps = DialogProps & { action: () => Promise<void> }

export function ActionDialog(
  props: ActionDialogProps & { children?: ReactNode }
): ReactElement {
  const { action, ...rest } = props

  const sender = useSender(action)

  return (
    <SenderDialog {...rest} sender={sender}>
      <div className="px-4 pt-4">{props.children}</div>
    </SenderDialog>
  )
}

export function AlertText(props: { children?: ReactNode }) {
  return <div className="mx-3 mt-3 alert alert-warning small p-2 d-flex">
    <div className="align-self-center ms-1 me-3">
      <i className="fa-solid fa-circle-exclamation" />
    </div>
    <div className="px-3 m-0">
      {props.children}
    </div>
  </div>
}
