export enum Operation {
  // project
  PROJECT_UPDATE = "PROJECT_UPDATE",

  // broadcast
  BROADCAST_DELETE = "BROADCAST_DELETE",
  BROADCAST_UPDATE = "BROADCAST_UPDATE",
  BROADCAST_GET = "BROADCAST_GET",
  BROADCAST_GET_LESS = "BROADCAST_GET_LESS",

  // playback
  PLAYBACK_EXPIRE = "PLAYBACK_EXPIRE",

  // member
  MEMBER_ADD = "MEMBER_ADD",
  MEMBER_REMOVE = "MEMBER_REMOVE",

  // api token
  API_TOKEN_ADD = "API_TOKEN_ADD",
  API_TOKEN_REMOVE = "API_TOKEN_REMOVE",
  API_TOKEN_LIST = "API_TOKEN_LIST",

  // webhook
  WEBHOOK_ADD = "WEBHOOK_ADD",
  WEBHOOK_REMOVE = "WEBHOOK_REMOVE",
  WEBHOOK_LIST = "WEBHOOK_LIST",

  // invoice
  INVOICE_SHOW = "INVOICE_SHOW",
}

// noinspection JSUnusedGlobalSymbols
export type AuthorizedScope = {
  projectId: string
  allowedOperations: Operation[]
}

// noinspection JSUnusedGlobalSymbols
export type UserUpdate = {
  displayName?: string
}

// noinspection JSUnusedGlobalSymbols
export type UserNew = {
  displayName: string
}

// noinspection JSUnusedGlobalSymbols
export type TrustedEmail = {
  id: string
  email: string
  createdAt: number
  updatedAt: number
}

export type Project = {
  id: string
  displayName: string
  description?: string
  serviceURL?: string
  createdAt: number
  updatedAt: number
}

// noinspection JSUnusedGlobalSymbols
export type ProjectCreate = {
  displayName: string,
  token?: string
}

// noinspection JSUnusedGlobalSymbols
export type ProjectUpdate = {
  displayName?: string
  description?: string
  serviceURL?: string
}
export type User = {
  id: string
  federationId: string
  email: string
  isEmailVerified: boolean
  displayName: string
  photoURL: string
  createdAt: number
  updatedAt: number
}

// noinspection JSUnusedGlobalSymbols
export type Member = {
  id: string
  project: Project
  user: User
  role: string
  createdAt: number
  updatedAt: number
}

// noinspection JSUnusedGlobalSymbols
export type Invitation = {
  id: string
  user: User // inviter
  project: Project
  email: string
  role: string
  createdAt: number
  updatedAt: number
}

// noinspection JSUnusedGlobalSymbols
export type InvitationCreate = {
  email: string
  role: string
}
