import { ReactElement, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { app } from "~context/core"
import { AudioTranscoderPreset, BroadcastCreateResult, VideoTranscoderPreset } from "~core/castify/api"
import { Box, VBox } from "~parts/box"
import { SenderDialog } from "~parts/dialog"
import { useBinder } from "~util/form"
import { useSender } from "~util/hook"
import { CastifyRoute } from "~core/castify-route"

export function BroadcastCreateDialog(props: { onResult?(_?: BroadcastCreateResult): void }): ReactElement {
  const name = useBinder()
  const called = useRef(false)
  const navigate = useNavigate()

  const sender = useSender(async () => {
    const result = await app.api.createBroadcast({
      name: name.value,
      deferred: true,
      options: {
        type: "av",
        variants: [
          {
            name: "default",
            audio: {
              type: "preset",
              preset: AudioTranscoderPreset.HLSCompat,
            },
            video: {
              type: "preset",
              preset: VideoTranscoderPreset.HLSCompat,
            },
          },
        ],
      }
    })

    if (!called.current) {
      called.current = true
      if (props.onResult) {
        props.onResult(result)
      }
      else {
        navigate(CastifyRoute.projects.project.broadcasts.broadcast.getPath(app.project.id, result.broadcastId))
      }
    }
  })

  useEffect(
    () => () => {
      if (!called.current) {
        props.onResult?.()
      }
    },
    []
  )
  return (
    <SenderDialog title="配信を作成" sender={sender}>
      <VBox minWidth="24rem">
        <Box margin="1.5rem 1.5rem 0 1.5rem">
          <input
            {...name}
            type="text"
            className="form-control"
            placeholder="タイトル"
          />
        </Box>
      </VBox>
    </SenderDialog>
  )
}
