export enum PredefinedRole {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  FINANCE = "FINANCE",
  MEMBER = "MEMBER",
  OUTSIDER = "OUTSIDER",
  VIEWER = "VIEWER",
}

export type RoleGetResult = {
  name: string
  description: string
}
