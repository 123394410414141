import { ReactElement, useEffect, useMemo, useState } from "react";
import { app } from "~context/core";
import { BroadcastGetResult } from "~core/castify/api";
import { UsageMetric } from "~core/castify/api/msg/usage";
import { HBox } from "~parts/box";
import { waitFor } from "~parts/spinner";
import { formatDecimal } from "~util/format";
import { useLoader2 } from "~util/hook";
import { UsageKind } from "~core/castify/api/msg/usage"

const metricTitles = {

  // cdn 1
  [UsageKind.CDN1_EGRESS]: "CDN #1 利用量 (キャッシュ読込)",
  [UsageKind.CDN1_CACHE_PUT]: "CDN #1 利用量 (キャッシュ書込)",
  [UsageKind.CDN1_CACHE_GET]: "CDN #1 利用量 (キャッシュ参照)",
  
  // cdn 2
  [UsageKind.CDN2_EGRESS]: "CDN #2 利用量 (キャッシュ読込)",
  [UsageKind.CDN2_CACHE_PUT]: "CDN #2 利用量 (キャッシュ書込)",
  [UsageKind.CDN2_CACHE_GET]: "CDN #2 利用量 (キャッシュ参照)",

  //cdn 3
  [UsageKind.CDN3_EGRESS]: "CDN #3 利用量 (キャッシュ読込)",
  [UsageKind.CDN3_CACHE_PUT]: "CDN #3 利用量 (キャッシュ書込)",
  [UsageKind.CDN3_CACHE_GET]: "CDN #3 利用量 (キャッシュ参照)",

  // network
  [UsageKind.V1_RECORDER_TRAFFIC]: "ネットワーク入力",
  [UsageKind.V2_RECORDER_TRAFFIC]: "ネットワーク入力",
  [UsageKind.V1_STREAMER_TRAFFIC]: "ネットワーク出力",
  [UsageKind.V2_STREAMER_TRAFFIC]: "ネットワーク出力",
  
  // transcode
  [UsageKind.TRANSCODE_H264_30F_P240]: "トランスコード (H.264 p240)",
  [UsageKind.TRANSCODE_H264_30F_P480]: "トランスコード (H.264 p480)",
  [UsageKind.TRANSCODE_H264_30F_P720]: "トランスコード (H.264 p720)",
  [UsageKind.TRANSCODE_H264_30F_P1080]: "トランスコード (H.264 p1080)",
  [UsageKind.TRANSCODE_H264_30F_P1440]: "トランスコード (H.264 p1440)",
  [UsageKind.TRANSCODE_AUDIO]: "トランスコード (音声)",
  
  // disk
  [UsageKind.STORAGE_USAGE]: "ディスク使用量"
}

function UsageMetricView(props: { src: UsageMetric }): ReactElement {

  return (
    <tr>
      <td>
        {metricTitles[props.src.type] ?? "-"}
      </td>
      <td className="cas-numeric">
        { formatDecimal(props.src.value) }
      </td>
      <td className="px-2">
        { props.src.unit ?? "" }
      </td>
    </tr>
  )
}

type BroadcastUsageSummaryProps = {
  src: BroadcastGetResult
};

export function BroadcastUsageSummary(props: BroadcastUsageSummaryProps): ReactElement {

  const [since, setSince] = useState<Date>()

  const [usage] = useLoader2(async (id, since) => {
    if (since === undefined) {
      return
    }
    else {
      const until = new Date(since)
      until.setMonth(until.getMonth() + 1)
      return app.api.getBroadcastUsage(id, since, until.getTime())
    }
  }, [props.src.broadcastId, since?.getTime()])
  
  return <>
    <p className="small text-muted">
      この配信の月次データ使用量を照会します。過去3ヶ月前まで選択可能です。
    </p>
    <HBox className="mx-1">
      <label style={{ whiteSpace: "nowrap", alignSelf: "center" }} className="me-3">
        対象月
      </label>
      <MonthSelector since={props.src.startedAt} onChange={setSince} />
    </HBox>
    <div className="mt-3">
      { waitFor(usage, src =>
        src && <>
          <table className="table table-sm">
            <thead>
              <tr>
                <th>内容</th>
                <th style={{textAlign: "right"}}>利用量</th>
                <th />
              </tr>
            </thead>
            <tbody>
              { src.filter(e => e.value >= 1).map((e, i) => <UsageMetricView key={i} src={e} />) }
            </tbody>
          </table>
        </>  
      ) }
    </div>
  </>
}

function MonthSelector(props: { since: number, until?: number, onChange?: (_: Date) => any }): ReactElement {
  const months = useMemo(() => {
    return listMonthsBetween(props.since, props.until ?? Date.now())
  }, [props.since, props.until])

  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (index !== undefined) {
      props.onChange?.(months[index]!)
    }
  }, [props.onChange, months, index])

  return <select className="form-select form-select-sm" value={index} onChange={e => setIndex(e.currentTarget.selectedIndex)}>
    { months.map((e, i) => 
      <option key={i} value={i}>{e.getFullYear()}/{e.getMonth() + 1}</option>
    ) }
  </select>
}

function listMonthsBetween(since_ms: number, until_ms: number, limit = 3) {
  const since = new Date(since_ms)
  const until = new Date(until_ms)
  roundToMonth(since)
  roundToMonth(until)
  const months: Date[] = []
  while (months.length != limit && since.getTime() <= until.getTime()) {
    months.push(new Date(until))
    until.setMonth(until.getMonth() - 1)
  }
  return months
}

function roundToMonth(src: Date) {
  src.setMilliseconds(0)
  src.setSeconds(0)
  src.setMinutes(0)
  src.setHours(0)
  src.setDate(1)
}
