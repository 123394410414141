import { app } from "~context/core"
import { IceCandidate } from "~core/castify/api/msg/rtc"
import { waitForEvent } from "~util/async"

async function waitForICECandidate(src: RTCPeerConnection): Promise<IceCandidate> {
  for (;;) {
    const event = await waitForEvent<RTCPeerConnectionEventMap>(src)("icecandidate")
    if (!event.candidate) {
      throw new Error("No suitable ICE candidate.")
    }
    const isServerReflexive = (
      event.candidate?.type      === "srflx" &&
      event.candidate?.component === "rtp"   &&
      event.candidate?.protocol  === "udp"
    )
    if (!isServerReflexive) {
      continue
    }
    const { candidate, sdpMLineIndex, sdpMid } = event.candidate
    if (sdpMLineIndex !== null && sdpMid !== null) {
      const address = event.candidate?.["address"]
      if (address?.match(/^(\d+\.){3}\d+/)) {
        return { candidate, sdpMLineIndex, sdpMid }
      }
    }
  }
}

export type RecorderTarget = { broadcastId: string; token: string }

export class RecordingSession {
  private rtc = new RTCPeerConnection({
    iceServers: [{ urls: "stun:stun1.l.google.com:19302" }],
    rtcpMuxPolicy: "require",
    bundlePolicy: "max-bundle",
  })

  public onStateChange?: (host: RecordingSession) => void

  constructor(private target: RecorderTarget, private stream: MediaStream) {
    this.rtc.addEventListener("connectionstatechange", this.handler)
  }

  get state(): RTCPeerConnectionState {
    return this.rtc.connectionState
  }

  async start() {
    const candidateRequest = waitForICECandidate(this.rtc)

    this.rtc.addTrack(this.stream.getAudioTracks()[0]!)
    this.rtc.addTrack(this.stream.getVideoTracks()[0]!)

    const offer = await this.rtc.createOffer({
      offerToReceiveAudio: false,
      offerToReceiveVideo: false,
    })
    if (offer.sdp === undefined) {
      throw new Error("No sdp!")
    }
    this.rtc.setLocalDescription(offer)

    const candidate = await candidateRequest
    const answer = await app.api.createRTCOffer({
      offer: {
        sdp: offer.sdp,
        candidates: [candidate],
      },
      ...this.target,
    })

    this.rtc.setRemoteDescription({ sdp: answer.sdp, type: "answer" })
    for (const candidate of answer.candidates) {
      this.rtc.addIceCandidate(candidate)
    }
  }

  close() {
    this.rtc.removeEventListener("connectionstatechange", this.handler)
    this.rtc.close()
  }

  private handler = () => {
    this.onStateChange?.(this)
  }
}
