import React, { CSSProperties } from "react"
import { Navigate, Route, Routes } from "react-router"
import { NavLink } from "react-router-dom"
import { HBox, VBox } from "./box"

export class TabMenuItem {
  constructor(
    public readonly name: string,
    public readonly path: {
      route: string,
      built: string,
    },
    public readonly component: React.ReactElement,
  ) {
  }
}

const tabBaseStyle: CSSProperties = {
  minWidth: "7rem",
  margin: "8px 0 0 12px",
  paddingBottom: "2px",
  textAlign: "center",
  textDecoration: "none",
  fontSize: "10pt",
}

const tabStyle: CSSProperties = {
  ...tabBaseStyle,
  borderBottom: "3px solid #999",
  color: "#666",
}

const tabStyleActive: CSSProperties = {
  ...tabBaseStyle,
  color: "#000",
  borderBottom: "3px solid #009900",
}

export function TabView(props: { src: TabMenuItem[] }) {

  const first = props.src[0]

  return (
    <VBox borderTop="1px solid #f7f7f7">
      <HBox backgroundColor="#fff" boxShadow="0 1px 3px rgba(0,0,0,0.2)">
        {props.src.map((e) => (
          <NavLink
            key={e.name}
            style={({ isActive }) => (isActive ? tabStyleActive : tabStyle)}
            to={e.path.built}
          >
            {e.name}
          </NavLink>
        ))}
      </HBox>
      <VBox padding="1.5rem">
        <Routes>
          {props.src.map((e) => (
            <Route key={e.name} path={e.path.route} element={e.component} />
          ))}
          {first && (
            <Route path="*" element={<Navigate to={first.path.built} replace={true} />} />
          )}
        </Routes>
      </VBox>
    </VBox>
  )
}
