import { Source } from "@castify-inc/castify-player"
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import * as Rx from "rxjs"
import * as RxOp from "rxjs/operators"
import { CastifyApi, HttpLoadError } from "~core/castify/api"
import { AuthorizedScope, Operation, Project } from "~core/castify/api/msg/project"
import { CastifyPlatform } from "~core/castify/config"
import { Account } from "~core/castify/api/msg/account"
import { useObservable } from "rxjs-hooks"
import { PlanBind } from "~core/castify/api/msg/plan"

export const platform = (function (env) {
  switch (env) {
    case "prod": return CastifyPlatform.prod
    case "dev":  return CastifyPlatform.dev
    default:
      return CastifyPlatform.local
  }
})(import.meta.env.MODE)

Source.server.edgeApi = platform.mediaCluster.api

if (firebase.apps.length === 0) {
  firebase.initializeApp(platform.firebaseOptions)

  const firestore = import.meta.env.FIRESTORE_EMULATOR_HOST
  if (firestore !== undefined) {
    const result = `${firestore}`.match(/(.*):(\d+)/)
    const host = result?.[1]
    const port = result?.[2]
    if (host && port) {
      firebase.firestore().useEmulator(host, parseInt(port))
    }
  }
  const auth = import.meta.env.FIREBASE_AUTH_EMULATOR_HOST
  if (auth !== undefined) {
    firebase.auth().useEmulator(`http://${auth}`)
  }
}

export class Session {
  readonly liveness = new Rx.Subject<never>()

  constructor(
    public readonly scope: AuthorizedScope[],
    public readonly user?: firebase.User,
    public readonly emailNotTrusted: boolean = false
  ) {}

  hasAll(src: Operation[], context: string = app.project.id): boolean {
    if (src.length === 0 || this.privileged) {
      return true
    }
    const operations = this.scope.find(
      (e) => e.projectId === context
    )?.allowedOperations
    if (!operations) {
      return false
    }
    return !src.find((e) => !operations.includes(e))
  }

  get privileged(): boolean {
    return (
      this.user?.emailVerified === true &&
      !!this.user.email?.endsWith("@evaluni.com")
    )
  }

  async loadProjects(): Promise<Project[]> {
    const projects = await Promise.all(
      this.scope.map(async (e) => {
        try {
          return await app.api.getProject(e.projectId)
        } catch (error) {
          const notFound =
            error instanceof HttpLoadError && error.status === 404
          if (notFound) {
            return undefined // ignore
          }
          throw error
        }
      })
    )
    return projects.flatMap((e) => e ?? [])
  }

  static async fromUser(user: firebase.User | null): Promise<Session> {
    if (user === null) {
      return new Session([])
    }
    const token = await user.getIdToken(true)
    try {
      return new Session(await app.api.listAuthorizedScopes(token), user)
    } catch (error) {
      const emailNotTrusted =
        error instanceof HttpLoadError && error.status === 403
      if (emailNotTrusted) {
        return new Session([], user, emailNotTrusted)
      }
      throw error
    }
  }
}

class CastifyApp {
  readonly config = platform

  readonly api = new CastifyApi(this, this.config.baseUrl)

  readonly $session = new Rx.BehaviorSubject<Session | null>(null)

  readonly $project = new Rx.BehaviorSubject<Project | null>(null)

  readonly $plan = new Rx.BehaviorSubject<PlanBind | null>(null)

  useAccount(accountId: string): "loading" | Account | null {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useObservable(
      () => Rx.from(this.api.getAccount(accountId))
        .pipe(
          Rx.catchError(x => { if (x.status === 404) { return Rx.of(null) } throw x})
        ) as Rx.Observable<Account | null | "loading">,
      "loading", []
    );
  }

  get session(): Session {
    const out = this.$session.value
    if (out === null) {
      throw new Error()
    }
    return out
  }

  get project(): Project {
    const out = this.$project.value
    if (out === null) {
      throw new Error()
    }
    return out
  }

  logout(): Promise<void> {
    return firebase.auth().signOut()
  }
}

export const app = new CastifyApp()

export const projectChange = app.$project.pipe(
  RxOp.map((e) => e?.id),
  RxOp.distinctUntilChanged()
)

export async function executeReCAPTCHA(): Promise<string> {
  return await grecaptcha.execute(app.config.reCAPTCHA.siteKey, { action: 'submit' })
}

export function getDB(): firebase.firestore.Firestore {
  return firebase.firestore()
}
