import { CSSProperties } from "react"

export const elevation = elevate(1)

export const letterBox: CSSProperties = {
  display: "flex",
  justifyItems: "center",
  justifyContent: "center",
  alignItems: "center",
}

export const noInteraction: CSSProperties = {
  userSelect: "none",
  pointerEvents: "none",
}

export function bounds(w: number, h: number): CSSProperties {
  return {
    width: `${w}px`,
    height: `${h}px`,
  }
}

export function elevate(src: number = 1): CSSProperties {
  return {
    boxShadow: `0 ${src | 0}px ${(3 * src) | 0}px rgba(0, 0, 0, 0.2)`,
  }
}

export function marginX(value: string): CSSProperties {
  return {
    marginLeft: value,
    marginRight: value,
  }
}
