import React, {
  CSSProperties,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react"
import { Link, LinkProps } from "react-router-dom"

type DropdownItemProps = {
  disabled?: boolean
  danger?: boolean
  onClick?: () => void
  children?: ReactNode
}

export const dropdownClassName = "list-group-item list-group-item-action border-0 small"

export const dropdownStyle: CSSProperties = { whiteSpace: "nowrap" }

export function DropdownItem(props: DropdownItemProps): ReactElement {
  const className = !props.danger
    ? dropdownClassName
    : dropdownClassName + " text-danger"
  return (
    <button
      onClick={props.onClick}
      className={className}
      style={dropdownStyle}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  )
}

export function DropdownLink(props: LinkProps): ReactElement {
  return (
    <Link
      className={dropdownClassName}
      style={dropdownStyle}
      {...props} 
    />
  )
}

type DropdownProps = {
  title: ReactNode
  buttonClassName?: string
  children?: ReactNode
  disabled?: boolean
}

export function Dropdown(props: DropdownProps): ReactElement {
  return (
    <ActionButton className={props.buttonClassName ?? "btn btn-sm dropdown-toggle"} title={props.title} disabled={props.disabled}>
      <div className="list-group">{props.children}</div>
    </ActionButton>
  )
}

export function ActionButton(props: {
  title: ReactNode
  className: string
  children?: ReactNode
  disabled?: boolean
}): ReactElement {
  const root = useRef<HTMLDivElement>(null)

  const [hidden, setHidden] = useState(true)

  useEffect(() => {
    function onMouseDown(event: MouseEvent) {
      if (!hidden) {
        const target = event.target
        if (target instanceof Node && root.current?.contains(target) !== true) {
          setHidden(true)
        }
      }
    }
    window.addEventListener("mousedown", onMouseDown)
    return () => window.removeEventListener("mousedown", onMouseDown)
  }, [hidden])

  return (
    <div ref={root} className="position-relative" style={{ display: "inline-block" }}>
      <button
        onClick={() => setHidden(!hidden)}
        className={props.className}
        type="button"
        disabled={props.disabled}
      >
        {props.title}
      </button>
      <div
        className="list-group border bg-white position-absolute top-100 end-0"
        hidden={hidden}
        style={{ minWidth: "100%", zIndex: 30 }}
      >
        {props.children}
      </div>
    </div>
  )
}
