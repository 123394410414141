import React, { CSSProperties, ReactElement } from "react"
import { chooseUnitPrefix, toHoursString } from "~util/format"
import { bounds } from "~util/style"

function toTickTime(src: number): string {
  const aux = new Date(src * 1000)
  return toHoursString(aux.getMinutes() + aux.getHours() * 60)
}

export type Coord = {
  x: number
  y: number
}

type LineChartProps = {
  data: Coord[][]
  w: number
  h: number
  xTicks: number
  yTicks: number
}

const textHeight = 9

const polyLineStyle: CSSProperties = {
  fill: "transparent",
  stroke: "orangered",
  strokeWidth: 1.33,
}

const tickTextStyle: CSSProperties = {
  fontSize: textHeight + "px",
}

const xTickStyle: CSSProperties = {
  stroke: "#bbb",
  strokeWidth: 1,
}

const xLineStyle: CSSProperties = {
  fill: "transparent",
  stroke: "#bbb",
  strokeWidth: 1,
}

const yLineStyle: CSSProperties = {
  fill: "transparent",
  stroke: "#eee",
  strokeWidth: 1.33,
}

export function LineChart(props: LineChartProps): ReactElement {
  const margin_l = 0
  const margin_r = 40
  const margin_t = 0
  const margin_b = 20

  const allX = props.data.flatMap((row) => row.map((e) => e.x))
  const allY = props.data.flatMap((row) => row.map((e) => e.y))

  if (allX.length <= 1) {
    return <React.Fragment />
  }

  const minX = allX.reduce((acc, e) => Math.min(acc, e))
  const maxX = allX.reduce((acc, e) => Math.max(acc, e))
  const domX = maxX - minX

  const minY = 1.0 * allY.reduce((acc, e) => Math.min(acc, e))
  const maxY = 1.5 * allY.reduce((acc, e) => Math.max(acc, e))
  const domY = maxY - minY

  const { name, rate } = chooseUnitPrefix(maxY, 1024)

  const mapX = (x: number) =>
    (+(x - minX) / domX) * (props.w - margin_l - margin_r) + margin_l
  const mapY = (y: number) =>
    (-(y - minY) / domY) * (props.h - margin_t - margin_b) - margin_b + props.h

  const xTicks: number[] = []
  for (let i = 1; i < props.xTicks; ++i) {
    xTicks.push(minX + (domX / props.xTicks) * i)
  }

  const yTicks: number[] = []
  for (let i = 1; i < props.yTicks; ++i) {
    yTicks.push(minY + (domY / props.yTicks) * i)
  }

  const matrix = props.data.map((row) =>
    row.map(({ x, y }) => ({
      x: mapX(x),
      y: mapY(y),
    }))
  )

  return (
    <svg
      style={bounds(props.w, props.h)}
      viewBox={`0, 0, ${props.w}, ${props.h}`}
    >
      <line
        y1={props.h - margin_b}
        y2={props.h - margin_b}
        x1={margin_l}
        x2={props.w - margin_r}
        style={xLineStyle}
      />

      {xTicks.map((e) => (
        <line
          key={e}
          style={xTickStyle}
          x1={mapX(e)}
          x2={mapX(e)}
          y1={props.h - margin_b + 0}
          y2={props.h - margin_b + 5}
        />
      ))}

      {xTicks.map((e) => (
        <text
          key={e}
          x={mapX(e)}
          y={props.h - margin_b + 20}
          style={tickTextStyle}
          fill="#777"
          textAnchor="middle"
        >
          {toTickTime(e)}
        </text>
      ))}

      {yTicks.map((e) => (
        <line
          key={e}
          style={yLineStyle}
          y1={mapY(e)}
          y2={mapY(e)}
          x1={margin_l}
          x2={props.w - margin_r}
        />
      ))}

      {yTicks.map((e) => (
        <text
          key={e}
          y={mapY(e) + textHeight * 0.33}
          x={props.w - margin_r + 8}
          style={tickTextStyle}
          fill="#777"
        >
          {(e * rate).toFixed(1)}
          {name}
        </text>
      ))}

      {matrix.map((row, i) => (
        <polyline
          key={i}
          style={polyLineStyle}
          points={row.map((e) => `${e.x},${e.y}`).join(" ")}
        />
      ))}
    </svg>
  )
}
