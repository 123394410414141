import React, { MutableRefObject, useEffect, useRef, useState } from "react"
import { CancellationToken, Failure, Success, Try } from "./async"

export function useCount(): [number, () => void] {
  const [count, setCount] = useState(0)
  return [
    count,
    function () {
      setCount(count + 1)
    },
  ]
}

export function useMounted(): MutableRefObject<boolean> {
  const ref = useRef(true)
  useEffect(
    () => () => {
      ref.current = false
    },
    []
  )
  return ref
}

export interface Sender<A, E = void> {
  readonly loaded: boolean

  readonly result: Try<A> | null

  send(arg: E): Promise<A>
}

export function useSender<A, E = void>(send: (_: E) => Promise<A>): Sender<A, E> {
  const [result, setResult] = useState<Try<A> | null>(null)
  const [loaded, setLoaded] = useState(true)
  const mounted = useMounted()
  return {
    async send(arg: E) {
      if (!mounted.current) {
        throw new Error("No longer mounted.")
      }
      if (!loaded) {
        throw new Error()
      }
      setResult(null)
      setLoaded(false)
      try {
        const value = await send(arg)
        if (!mounted.current) {
          throw new Error("No longer mounted.")
        }
        setResult(new Success(value))
        setLoaded(true)
        return value
      } catch (error) {
        if (mounted.current) {
          setResult(new Failure(error))
          setLoaded(true)
        }
        throw error
      }
    },
    loaded,
    result,
  }
}

type Out<T> = {
  value: Try<T>
  deps?: React.DependencyList
}

export function useLoader<T>(get: (_: CancellationToken) => Promise<T> | undefined, deps?: React.DependencyList): [Try<T> | undefined, () => void] {
  const [out, setOut] = useState<Out<T> | undefined>()
  const [rev, setRev] = useCount()

  useEffect(() => {
    if (out !== undefined) {
      setOut(undefined)
    }
    const token = new CancellationToken()
    get(token)
      ?.then ((value) => setOut({ value: new Success(value), deps }))
      ?.catch((error) => setOut({ value: new Failure(error), deps }))
    return () => token.cancel()
  }, deps?.concat(rev) ?? [rev])

  if (out !== undefined && areSameArrays(out.deps, deps)) {
    return [out.value, setRev]
  } 
  else {
    return [undefined, setRev]
  }
}

export function useLoader2<T, A extends readonly unknown[]>(get: (... args: A) => Promise<T> | undefined, deps: [...A]): [Try<T> | undefined, () => void] {
  return useLoader<T>(() => get(...deps) , deps)
}

export function useThrottledState<T>(value: T, interval: number): T {
  const [throttledValue, setThrottledValue] = useState<T>(value)
  const [nextChange, setNextChange] = useState(0)
  useEffect(() => {
    const now = performance.now()
    if (now >= nextChange) {
      setThrottledValue(value)
      setNextChange(now + interval)
    } else {
      return clearTimeout.bind(
        null,
        setTimeout(() => {
          setThrottledValue(value)
          setNextChange(nextChange + interval)
        }, nextChange - now)
      )
    }
  }, [value, interval])
  return throttledValue
}

function areSameArrays(lhs?: ReadonlyArray<any>, rhs?: ReadonlyArray<any>) {
  if (lhs !== rhs) {
    if (lhs === undefined) return false
    if (rhs === undefined) return false
    if (lhs.length !== rhs.length) {
      return false
    }
    for (let i = 0; i < lhs.length; ++i) {
      if (lhs[i] !== rhs[i]) return false
    }
  }
  return true
}
