import { ReactElement } from "react"
import { useLocation } from "react-router"

export function Paged(props: { src: (page: number) => ReactElement }) {
  return props.src(usePage())
}

export function usePage(): number {
  const query = useQuery()
  const pageNo = query.get("page")
  if (pageNo === null) {
    return 0
  } else {
    return parseInt(pageNo) ?? 0
  }
}

export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}
