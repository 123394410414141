import React, { ReactElement } from "react"
import { app } from "~context/core"
import { modal } from "~context/modal"
import { User } from "~core/castify/api/msg/project"
import { Box, VBox } from "~parts/box"
import { ActionDialog, SenderDialog } from "~parts/dialog"
import { PageBody } from "~parts/header"
import { waitFor } from "~parts/spinner"
import { useBinder } from "~util/form"
import { useLoader, useSender } from "~util/hook"
import { CastifyRoute } from "~core/castify-route"
import { Link } from "react-router-dom"

function UserEditDialog(props: { src: User, onEnd?(): void }): ReactElement {
  const displayName = useBinder(props.src.displayName)

  const sender = useSender(async () =>
    await app.api.updateUser({ displayName: displayName.value }),
  )

  return (
    <SenderDialog
      title="プロフィールを編集"
      sender={sender}
      onEnd={props.onEnd}
    >
      <VBox minWidth="24rem">
        <Box margin="1.5rem 1.5rem 0 1.5rem">
          <input
            {...displayName}
            type="text"
            className="form-control"
            placeholder="表示名"
          />
        </Box>
      </VBox>
    </SenderDialog>
  )
}

const deleteUserDialog = (
  <ActionDialog action={deleteUser}>
    Castify のアカウントを削除します。本当によろしいですか？
  </ActionDialog>
)

function UserCard(): ReactElement {
  const [user, reloadUser] = useLoader(() => app.api.getUser())


  return (<>
    {waitFor(user, (user) => (<>
      <VBox
        width="270px"
        alignItems="center"
        marginTop="2rem"
        border="1px solid #bbb"
        backgroundColor="white"
        borderRadius="3px"
        padding="1rem"
      >
        <Box marginTop="2rem">
          <img
            src={user.photoURL}
            style={{ height: "52px", borderRadius: "50%" }}
            alt="ユーザーアイコン"
          />
        </Box>
        <Box marginTop="8px">{user.displayName}</Box>
        <Box color="#777" fontSize="10pt">
          {user.email}
        </Box>

        <div className="list-group" style={{ width: "100%", textAlign: "center", marginTop: "1rem" }}>
          <Link className="list-group-item" to={CastifyRoute.projects.path}>
            <i className="fas fa-home" />&nbsp;プロジェクト一覧
          </Link>
          <Link className="list-group-item" to={CastifyRoute.accounts.path}>
            <i className="fas fa-wallet" />&nbsp;請求先アカウント
          </Link>
          <Link className="list-group-item" to="#" onClick={modal.bind(<UserEditDialog src={user} onEnd={reloadUser} />)}>
            <i className="fas fa-pencil" />&nbsp;プロフィールを編集
          </Link>
          <Link className="list-group-item" to="#" onClick={() => app.logout()}>
            <i className="fas fa-sign-out-alt" />&nbsp;ログアウト
          </Link>
        </div>
      </VBox>

      <span className="text-danger mt-5" onClick={modal.bind(deleteUserDialog)}>
        アカウントを削除
      </span>
    </>))}
  </>)
}

export function UserPage(): ReactElement {
  return (
    <React.Fragment>
      <PageBody>
        <VBox alignItems="center">
          <UserCard />
        </VBox>
      </PageBody>
    </React.Fragment>
  )
}

async function deleteUser() {
  await app.api.deleteUser()
  try {
    await app.logout()
  } catch {
    // do nothing
  }
}
