import { ReactElement } from "react"
import { app } from "~context/core"
import { modal } from "~context/modal"
import { PlanType } from "~core/castify/api/msg/plan"
import { Box, VBox } from "~parts/box"
import { SenderDialog } from "~parts/dialog"
import { Dropdown, DropdownItem } from "~parts/dropdown"
import { PageBody, PageHeader } from "~parts/header"
import { Panel } from "~parts/panel"
import { PropertyList, PropertyListItem } from "~parts/property-list"
import { waitFor } from "~parts/spinner"
import { useBinder, useCheckbox } from "~util/form"
import { useLoader, useSender } from "~util/hook"

type PlanChangeDialogProps = {
  type: PlanType
  onChange?: () => any
}

function PlanChangeDialog(props: PlanChangeDialogProps) {

  const account = useBinder()

  const start = useBinder()

  const flags = useCheckbox()

  const sender = useSender(async () => {
    const accountId = account.value
    if (accountId === "") {
      throw new Error("請求先アカウントが未選択です。")
    }
    let startedAt = parseInt(start.value) || undefined
    if (startedAt) {
      startedAt *= 1000;
    }
    await app.api.createSub(accountId, {
      projectId: app.project.id,
      plan: props.type,
      automated: flags.value.has("automated"),
      startedAt
    })
  })

  const [accounts] = useLoader(() => app.api.listAccounts())

  return (
    <SenderDialog title="有料プランへの加入" sender={sender} onEnd={props.onChange}>
      <VBox minWidth="26rem">
        <div className="mt-4 mx-4">
          {waitFor(accounts, (src) => (
            <select {...account} className="form-control form-select">
              <option value="" disabled>
                請求先アカウントを選択
              </option>
              {src.map((e) => (
                <option key={e.accountId} value={e.accountId}>
                  {e.name}
                </option>
              ))}
            </select>
          ))}
        </div>
        <div className="mt-4 mx-4">
          <div className="text-muted small">
            開始時刻 (Optional)
          </div>
          <input className="form-control mt-3" {...start} type="text" placeholder="UNIX TIME" />
        </div>
        <div className="mt-4 mx-4 form-check">
          <input className="form-check-input" {...flags.bind("automated")} type="checkbox" />
          <label className="form-check-label">
            自動課金
          </label>
        </div>
      </VBox>
    </SenderDialog>
  )
}

const plans = {
  [PlanType.Trial]: "Trial",
  [PlanType.Basic]: "Basic",
  [PlanType.Enterprise]: "Enterprise",
}

function BroadcastCreateWithJsonDialog() {

  const name = useBinder()
  const json = useBinder()

  const sender = useSender(async () => {
    await app.api.createBroadcast({
      name: name.value,
      options: JSON.parse(json.value),
    })
  })

  return (
    <SenderDialog title="配信を作成" sender={sender}>
      <VBox minWidth="24rem">
        <Box margin="1.5rem 1.5rem 0 1.5rem">
          <input
            {...name}
            type="text"
            className="form-control"
            placeholder="タイトル"
          />
        </Box>
        <VBox margin="1rem 1.5rem 0 1.5rem">
          <textarea {...json} className="form-control" />
        </VBox>
      </VBox>
    </SenderDialog>
  )
}

// noinspection JSUnusedLocalSymbols
export function DebugPage(props: {projectId: string}): ReactElement {

  const [plan, reloadPlan] = useLoader(() => app.api.getPlanBind())

  return <>
    <PageHeader title="デバッグ">
      <Dropdown title="操作">
        <DropdownItem onClick={modal.bind(<BroadcastCreateWithJsonDialog />)}>
          JSON から配信を作成
        </DropdownItem>
      </Dropdown>
    </PageHeader>
    <PageBody>
    { waitFor(plan, src =>
      <Panel title="プロジェクトの状態">
        <PropertyList>
          <PropertyListItem name="プラン">
            <Dropdown title={plans[src.type]} disabled={src.type !== PlanType.Trial}>
              <DropdownItem
                onClick={modal.bind(<PlanChangeDialog onChange={reloadPlan} type={PlanType.Basic} />)}
              >
                Basic
              </DropdownItem>
              <DropdownItem
                onClick={modal.bind(<PlanChangeDialog onChange={reloadPlan} type={PlanType.Enterprise} />)}
              >
                Enterprise
              </DropdownItem>
            </Dropdown>
          </PropertyListItem>
        </PropertyList>
      </Panel>
    ) }
    </PageBody>
  </>
}
