import React, { ReactElement, ReactNode } from "react"
import { Box } from "~parts/box"

export function PropertyList(props: { children?: ReactNode }): ReactElement {
  return (
    <table className="table table-sm">
      <tbody>{props.children}</tbody>
    </table>
  )
}

export function PropertyListItem(props: {
  name: string
  children: ReactNode
}): ReactElement {
  return (
    <tr>
      <th style={{ verticalAlign: "middle" }}>
        <Box fontFamily="monospace">{props.name}</Box>
      </th>
      <td className="py-2">{props.children}</td>
    </tr>
  )
}
