import React, { Component, ReactNode } from "react"
import { from, Subscription } from "rxjs"
import { Box } from "~parts/box"
import { Failure, Success, Try } from "~util/async"
import { letterBox } from "~util/style"
import { Spinner } from "./spinner"

type LoaderProps<Out, Id = string> = {
  id: Id
  of: (id: Id) => Promise<Out>
  then?: (value: Out) => ReactNode
  else?: (error: any) => ReactNode
}

type LoaderState<T, Id> = {
  loader?: {
    id: Id
    scope: Subscription
  }
  loaded?: {
    id: Id
    result: Try<T>
  }
}

export class Loader<T, Id> extends Component<
  LoaderProps<T, Id>,
  LoaderState<T, Id>
> {
  state: LoaderState<T, Id> = {}

  static getDerivedStateFromProps<T, Id>(
    props: LoaderProps<T, Id>,
    state: LoaderState<T, Id>
  ): Partial<LoaderState<T, Id>> | null {
    if (props.id === state.loader?.id) {
      return null
    }
    state.loader?.scope.unsubscribe()
    state.loader = undefined
    state.loaded = undefined
    return state
  }

  componentDidMount() {
    this.componentDidUpdate()
  }

  componentDidUpdate() {
    if (this.state.loader) {
      return
    }
    const id = this.props.id
    const scope = from(this.props.of(id)).subscribe({
      error: (error) => {
        this.setState({ loaded: { id, result: new Failure(error) } })
      },
      next: (value) => {
        this.setState({ loaded: { id, result: new Success(value) } })
      },
    })

    this.setState({ loader: { id, scope } })
  }

  componentWillUnmount() {
    this.state.loader?.scope.unsubscribe()
  }

  render(): ReactNode {
    const out = this.state.loaded
    if (out === undefined) {
      return (
        <Box height="100%" {...letterBox}>
          <Spinner />
        </Box>
      )
    }
    if (out.result.failed)
      return this.props.else?.(out.result.error) ?? <Box>NG</Box>
    else return this.props.then?.(out.result.value) ?? <Box>OK</Box>
  }
}
