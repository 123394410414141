import { BehaviorSubject, Subscription } from "rxjs"
import { getDB, platform, projectChange } from "./core"

export type SignalSet = {
  exceededQuotas?: number
  scheduledJobs?: number
}

export const signalSet = new BehaviorSubject<SignalSet>({})

const db = getDB()

let last: Subscription | null = null

projectChange.subscribe(id => {
  last?.unsubscribe()
  last = new Subscription(db
    .collection(`projects/${id}/jobs`)
    .where("createdAt", ">", Date.now())
    .onSnapshot(snapshot => {
      let scheduledJobs = signalSet.value.scheduledJobs ?? 0
      for (const e of snapshot.docChanges()) {
        if (e.type === "added") {
          scheduledJobs ++
        }
      }
      signalSet.next({
        ... signalSet.value,
        scheduledJobs
      })
    }))
})

export function dropSignal(name: keyof SignalSet) {
  const { value } = signalSet
  if (value[name]) {
    signalSet.next({ ... value, [name]: 0 })
  }
}
