export enum PlanType {
  Trial = 0,
  Basic = 1,
  Test = 66
}

export type PlanBind = {
  type: PlanType
}

export const planTitles = {
  [PlanType.Trial]: "ライブ配信プラットフォーム (トライアル)",
  [PlanType.Basic]: "ライブ配信プラットフォーム",
  [PlanType.Test]: "Test"
}
