import { ReactElement, useRef } from "react"
import { CardElement, useStripe } from "@stripe/react-stripe-js"
import { StripeCardElement, StripeCardElementOptions } from "@stripe/stripe-js"
import { app } from "~context/core"
import { Box, VBox } from "~parts/box"
import { formatDateTime } from "~util/format"
import { SpinnerButton, waitForList } from "~parts/spinner"
import { useLoader, useSender } from "~util/hook"
import { ActionDialog, SenderDialog } from "~parts/dialog"
import { useBinder } from "~util/form"
import { modal } from "~context/modal"
import { Dropdown, DropdownItem } from "~parts/dropdown"
import { PageBody, PageHeader } from "~parts/header"
import { PaymentMethodInfo, StripePaymentMethod } from "~core/castify/api/msg/stripe"
import { EmptyIndicator } from "~parts/placeholder"

const cardElementOptions: StripeCardElementOptions = {
  style: {
    base: {
      fontSize: "16px",
      color: "#424770",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: { color: "#9e2146" },
  },
  hidePostalCode: true,
}

type PaymentMethodCreateDialogProps = {
  accountId: string
  close?: boolean
  onChange?(): any
  onCancel?(): void
}

export function PaymentMethodCreateDialog(props: PaymentMethodCreateDialogProps): ReactElement {

  const stripe = useStripe()

  const cardRef = useRef<StripeCardElement>()

  const name = useBinder("お支払い方法 #1")

  const sender = useSender(async () => {

    const card = cardRef.current
    if (!card || stripe === null) {
      return
    }
    const { paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: { name: name.value },
    })
    const paymentMethodId = paymentMethod?.id
    if (paymentMethodId === undefined) {
      throw new Error("登録に失敗しました。")
    }
    await app.api.createPaymentMethod(props.accountId, { paymentMethodId })
    return props.onChange?.()
  })

  return <SenderDialog title="支払い方法の追加" sender={sender} onCancel={props.onCancel}>
    <VBox className="p-4" minWidth="27rem">
      <Box>
        <input
          {...name}
          type="text"
          className="form-control form-control-sm"
          placeholder="識別名"
        />
      </Box>
      <VBox className="mt-4">
        <Box fontSize="10pt" color="#666">
          カード情報
        </Box>
        <Box marginLeft="0.5rem" marginTop="0.5rem">
          <CardElement
            options={cardElementOptions}
            onReady={e => {
              cardRef.current = e
            }}
          />
        </Box>
      </VBox>
    </VBox>
  </SenderDialog>
}

type PaymentMethodElementProps = {
  accountId: string
  src: StripePaymentMethod
  onChange?: () => void
}

function PaymentMethodDeleteDialog(props: PaymentMethodElementProps): ReactElement {

  async function start() {
    await app.api.deletePaymentMethod(
      props.accountId,
      props.src.paymentMethodId,
    )
    props.onChange?.()
  }

  return <ActionDialog action={start} title="支払い方法の削除">
    お支払い方法を削除します。よろしいですか？
  </ActionDialog>
}

const cardBrandIcons: { [_: string]: string } = {
  visa: "fab fa-cc-visa",
  master: "fab fa-cc-master",
  jcb: "fab fa-cc-jcb",
}

function PaymentMethodInfoView(props: { src: PaymentMethodInfo }) {
  const icon = cardBrandIcons[props.src.brand] ?? "far fa-credit-card"
  return (
    <span className="font-monospace">
      <i className={icon} /> *** {props.src.last4}
    </span>
  )
}

function PaymentMethodListItem(props: PaymentMethodElementProps): ReactElement {

  const sender = useSender(async () => {
    await app.api.markPaymentMethodAsDefault(props.accountId, props.src.paymentMethodId)
    props.onChange?.()
  })

  return (
    <tr>
      <td>
        <input type="checkbox" className="form-check-input" />
      </td>
      <td>{props.src.name}</td>
      <td>
        <PaymentMethodInfoView src={props.src.info} />
      </td>
      <td>
        {formatDateTime(props.src.createdAt * 1000)}
      </td>
      <td>{
        props.src.isDefault ? "既定のお支払い方法" : (
          <SpinnerButton onClick={sender.send} animated={!sender.loaded} className="btn btn-sm btn-primary">
            既定に変更
          </SpinnerButton>
        )
      }</td>
      <td>
        <button onClick={modal.bind(<PaymentMethodDeleteDialog {...props} />)} className="btn btn-sm">
          <i className="fas fa-trash text-secondary" />
        </button>
      </td>
    </tr>
  )
}

export function PaymentMethodPage(props: { accountId: string }): ReactElement {

  const { accountId } = props
  const [methods, reloadMethods] = useLoader(() =>
    app.api.listPaymentMethods(accountId),
  )

  return (
    <>
      <PageHeader title="お支払い方法">
        <Dropdown title="操作">
          <DropdownItem onClick={modal.bind(<PaymentMethodCreateDialog accountId={accountId} onChange={reloadMethods} />)}>
            お支払い方法を追加
          </DropdownItem>
        </Dropdown>
      </PageHeader>
      <PageBody>
        {
          waitForList(methods, src => {
            return (
              <table className="cas-table">
                <thead>
                <tr>
                  <th className="cas-cell-shrink">
                    <input type="checkbox" className="form-check-input" />
                  </th>
                  <th>名前</th>
                  <th>内容</th>
                  <th>作成日時</th>
                  <th style={{ width: "180px" }} />
                  <th className="cas-cell-shrink" />
                </tr>
                </thead>
                <tbody>
                {
                  src.map(e => (
                    <PaymentMethodListItem onChange={reloadMethods} key={e.paymentMethodId} accountId={accountId} src={e} />
                  ))
                }
                </tbody>
              </table>
            )
          }) ?? <EmptyIndicator />
        }
      </PageBody>
    </>
  )
}
