import React, { ReactElement } from "react"
import { Box, VBox } from "~parts/box"
import { PageHeader } from "~parts/header"
import { LogIndex } from "~parts/log"

export function AuditLogsScreen(props: {projectId: string}): ReactElement {
  return (
    <VBox>
      <PageHeader title="監査ログ" />
      <Box padding="2rem">
        <LogIndex projectId={props.projectId} query={{ type: "project" }} />
      </Box>
    </VBox>
  )
}
