import React, { CSSProperties, ReactElement, ReactNode } from "react"
import { Box, HBox, VBox } from "~parts/box"

type FigureCardProps = {
  figure: string
  legend: string
  styles: CSSProperties
}

export function FigureCard(
  props: FigureCardProps & { children: ReactNode }
): ReactElement {
  const styles: CSSProperties = {
    boxShadow: "0 2px 4px rgba(0,0,0,0.4)",
    borderRadius: "3px",
    padding: "0.66rem 1.5rem",
    width: "100%",
    ...props.styles,
  }
  return (
    <Box {...styles}>
      <HBox color="white" alignItems="center" justifyContent="space-between">
        <VBox>
          <Box fontSize="16pt">{props.figure}</Box>
          <Box fontSize="10pt">{props.legend}</Box>
        </VBox>
        <Box fontSize="23pt" margin="0 1.33rem">
          {props.children}
        </Box>
      </HBox>
    </Box>
  )
}
