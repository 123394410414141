import React, { ReactElement, ReactNode } from "react"
import { Box, VBox } from "~parts/box"
import { elevate, letterBox } from "~util/style"

export function AlertBox(props: { children?: ReactNode }): ReactElement {
  return (
    <Box
      border="1px solid #f5c2c7"
      backgroundColor="#f8d7da"
      color="#842029"
      fontSize="10pt"
      padding="0.66rem 1rem"
    >
      {props.children}
    </Box>
  )
}

export function SuccessfulBox(props: { children?: ReactNode }): ReactElement {
  return (
    <Box
      border="1px solid #c3e6cb"
      backgroundColor="#d4edda"
      color="#155724"
      fontSize="10pt"
      padding="0.66rem 1rem"
    >
      {props.children}
    </Box>
  )
}

export function AuthBox(props: { children?: ReactNode }): ReactElement {
  return (
    <Box
      className="row g-0 justify-content-md-center align-items-md-center"
      minHeight="100%"
      backgroundColor="#f1f1f1"
    >
      <Box className="col-md-4 col-sm-12">
        <VBox {...elevate(2)} backgroundColor="white">
          <VBox
            {...letterBox}
            width="100%"
            padding="20px 0"
            backgroundColor="#050727"
            color="white"
          >
            <img
              src="https://storage.googleapis.com/public.castify.jp/img/logo/text-v-white.svg"
              height="200px"
              alt="Castify ロゴ"
            />
          </VBox>
          <Box padding="1.5rem">
            <div>
              本サービスを利用することをもって、
              <a href="https://www.castify.jp/legal#service-agreement">
                サービス規約
              </a>
              および
              <a href="https://www.castify.jp/legal#privacy-policy">
                プライバシーポリシー
              </a>
              に同意したものとみなされます。
            </div>
            <div>
              {props.children}
            </div>
          </Box>
        </VBox>
      </Box>
    </Box>
  )
}
