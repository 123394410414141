import { Session } from "~context/core"
import { sleep } from "~util/async"
import {
  BroadcastCreate,
  BroadcastCreateResult,
  BroadcastGetResult,
  BroadcastListRow,
  BroadcastRTCOfferCreate,
  MediaQueueStatus,
  OTT,
  PlayableRangesListResult, 
  MediaImportRequest,
  TrackExtentList,
  TranscodeStatus,
  PlaybackCount,
} from "./msg/broadcast"
import { AuthorizedScope, Invitation, InvitationCreate, Member, Project, ProjectCreate, ProjectUpdate, User, UserUpdate } from "./msg/project"
import { RtcOffer } from "./msg/rtc"
import { APIToken, APITokenCreate } from "./msg/tokens"
import { BroadcastUsageCreate, TimeUsageData, UsageMetric, UsageSummary } from "./msg/usage"
import { Meter, Quota } from "./msg/quota"
import { WebhookCreate, WebhookGetResult, WebhookUpdate } from "./msg/webhook"
import { PlanBind, PlanType } from "./msg/plan"
import { PlaybackStats } from "./msg/stats"
import { Bill, Charge, ChargeStatus, Sub, SubCreate } from "./msg/payment"
import { Account, AccountCreate, AccountMember } from "./msg/account"

import { StripePayment, StripePaymentMethod, StripePaymentMethodCreate } from "./msg/stripe"
import { DiskEntry } from "./msg/disk"
import { localizedMessages } from "~core/localization"

export * from "./msg/broadcast"
export * from "./msg/dimension"
export * from "./msg/preview"
export * from "./msg/stats"

export type Page<T> = { total: number; values: T[] }

export class HttpLoadError {
  constructor(public readonly status: number) {}
}

export class CastifyApiError extends Error {
  constructor(
    public readonly code: string,
    public readonly note: string)
  {
    super(localizedMessages.get(note) ?? note)
  }
}

interface CastifyApiConfig {
  readonly project: Project
  readonly session: Session
}

const delay = 0

const webTokenHeaderField = "X-Castify-Web-Token"
const apiTokenHeaderField = "X-Castify-API-Token"

export class CastifyApi {
  constructor(
    public readonly config: CastifyApiConfig,
    public readonly baseUrl: string
  ) {}

  async listAuthorizedScopes(fbToken: string): Promise<AuthorizedScope[]> {
    const res = await fetch(`${this.baseUrl}/api/v1/authorized_scopes`, {
      headers: { [webTokenHeaderField]: fbToken },
    })
    if (res.status !== 200) {
      throw new HttpLoadError(res.status)
    }
    return await res.json()
  }

  //---
  // User
  //---

  async getUser(): Promise<User> {
    return this.fetch(`/console_api/v1/users/me`)
  }

  async updateUser(request: UserUpdate): Promise<void> {
    await this.update(`/console_api/v1/users/me`, "put", request)
  }

  async deleteUser(): Promise<void> {
    await this.update(`/console_api/v1/users/me`, "delete")
  }

  listMyInvitations(): Promise<Invitation[]> {
    return this.fetch(`/console_api/v1/users/my/invitations`)
  }

  async takeMyInvitation(invitationId: string, accept: boolean): Promise<void> {
    await this.update(
      `/console_api/v1/users/my/invitations/${invitationId}`,
      accept ? "put" : "delete"
    )
  }

  //---
  // Project
  //---

  getProject(projectId: string): Promise<Project> {
    return this.fetch(`/console_api/v1/projects/${projectId}`)
  }

  async createProject(request: ProjectCreate): Promise<void> {
    await this.update(`/console_api/v1/projects`, "post", request)
  }

  async updateProject(request: ProjectUpdate): Promise<void> {
    await this.update(
      `/console_api/v1/projects/${this.config.project.id}`,
      "put",
      request
    )
  }

  async deleteProject(): Promise<void> {
    await this.update(
      `/console_api/v1/projects/${this.config.project.id}`,
      "delete"
    )
  }

  //---
  // Account
  //---

  listAccounts(): Promise<Account[]> {
    return this.fetch(`/console_api/v1/accounts`)
  }

  getAccount(accountId: string): Promise<Account> {
    return this.fetch(`/console_api/v1/accounts/${accountId}`)
  }

  async createAccount(request: AccountCreate): Promise<void> {
    await this.update(`/console_api/v1/accounts/@new`, "post", request)
  }

  async deleteAccount(accountId: string): Promise<void> {
    await this.update(`/console_api/v1/accounts/${accountId}`, "delete")
  }
  async updateAccount(accountId: string, request: AccountCreate): Promise<void> {
    await this.update(`/console_api/v1/accounts/${accountId}`, "put", request)
  }

  //---
  // AccountMember
  //---

  listAccountMembers(accountId: string): Promise<AccountMember[]> {
    return this.fetch(`/console_api/v1/accounts/${accountId}/members`)
  }

  async createAccountMember(accountId: string, email: string): Promise<void> {
    await this.update(
      `/console_api/v1/accounts/${accountId}/members/@new`,
      "post",
      { email }
    )
  }

  async deleteAccountMember(accountMemberId: number): Promise<void> {
    await this.update(
      `/console_api/v1/accounts/@any/members/${accountMemberId}`,
      "delete"
    )
  }

  //---
  // Broadcast
  //---

  listBroadcasts(
    offset: number = 0,
    status: "ANY" | "IN_PROGRESS" = "ANY"
  ): Promise<Page<BroadcastListRow>> {
    return this.fetch(
      `/console_api/v1/projects/${this.config.project.id}/broadcasts?offset=${offset}&status=${status}`
    )
  }

  getBroadcast(broadcastId: string): Promise<BroadcastGetResult> {
    return this.fetch(`/api/v1/broadcasts/${broadcastId}`)
  }

  createBroadcastPath(): string {
    return `/api/v1/broadcasts/@new`
  }

  async createBroadcast(request: BroadcastCreate): Promise<BroadcastCreateResult> {
    const res = await this.update(this.createBroadcastPath() + `?project=${this.config.project.id}`, "post", request)
    if (res.status !== 200) {
      throw new HttpLoadError(res.status)
    }
    return await res.json()
  }

  async deleteBroadcast(broadcastId: string): Promise<void> {
    await this.update(`/api/v1/broadcasts/${broadcastId}`, "delete")
  }

  getBroadcastUsage(broadcastId: string, since: number, until: number): Promise<UsageMetric[]> {
    return this.fetch(`/console_api/v1/broadcasts/${broadcastId}/usage?since=${since}&until=${until}`)
  }

  //---
  // Recorder
  //---

  async stopRecording(broadcastId: string): Promise<void> {
    await this.update(`/api/v1/broadcasts/${broadcastId}/recorder`, "delete")
  }

  //---
  // Streamer
  //---

  async createOTT(broadcastId: string): Promise<OTT> {
    const res = await this.update(
      `/api/v1/broadcasts/${broadcastId}/ott/@new`,
      "post",
      {}
    )
    if (res.status !== 200) {
      throw new HttpLoadError(res.status)
    }
    return res.json()
  }

  listPlayableRanges(broadcastId: string): Promise<PlayableRangesListResult> {
    return this.fetch(`/api/v1/broadcasts/${broadcastId}/playable_ranges`)
  }

  listVariantNames(broadcastId: string): Promise<string[]> {
    return this.fetch(`/console_api/v1/broadcasts/${broadcastId}/variants.name`)
  }

  getVariantInfo(broadcastId: string, name: string): Promise<{ duration: number, size: number }> {
    return this.fetch(`/console_api/v1/broadcasts/${broadcastId}/variants.info/${name}`)
  }

  getArchiveUrl(broadcastId: string, name: string): Promise<{ url: string }> {
    return this.fetch(
      `/api/v1/broadcasts/${broadcastId}/archives/${name}?redirect=0`
    )
  }

  //---
  // Webhook
  //---

  listWebhooks(offset: number = 0): Promise<WebhookGetResult[]> {
    return this.fetch(
      `/api/v1/projects/${this.config.project.id}/webhooks?offset=${offset}`
    )
  }

  getWebhook(webhookId: string): Promise<WebhookGetResult> {
    return this.fetch(
      `/api/v1/projects/${this.config.project.id}/webhooks/${webhookId}`
    )
  }

  async createWebhook(src: WebhookCreate): Promise<void> {
    await this.update(
      `/api/v1/projects/${this.config.project.id}/webhooks`,
      "post",
      src
    )
  }

  async updateWebhook(webhookId: string, src: WebhookUpdate): Promise<void> {
    await this.update(
      `/api/v1/projects/${this.config.project.id}/webhooks/${webhookId}`,
      "put",
      src
    )
  }

  async deleteWebhook(webhookId: string): Promise<void> {
    await this.update(
      `/api/v1/projects/${this.config.project.id}/webhooks/${webhookId}`,
      "delete"
    )
  }

  //---
  // Invitation
  //---

  listInvitation(): Promise<Invitation[]> {
    return this.fetch(
      `/console_api/v1/projects/${this.config.project.id}/invitations`
    )
  }

  async createInvitation(src: InvitationCreate): Promise<void> {
    await this.update(
      `/console_api/v1/projects/${this.config.project.id}/invitations`,
      "post",
      src
    )
  }

  async deleteInvitation(invitationId: string): Promise<void> {
    await this.update(
      `/console_api/v1/projects/${this.config.project.id}/invitations/${invitationId}`,
      "delete"
    )
  }

  //---
  // APIToken
  //---

  listAPITokens(): Promise<APIToken[]> {
    return this.fetch(`/api/v1/projects/${this.config.project.id}/api_tokens`)
  }

  async createAPIToken(src: APITokenCreate): Promise<void> {
    await this.update(
      `/api/v1/projects/${this.config.project.id}/api_tokens`,
      "post",
      src
    )
  }

  async deleteAPIToken(apiTokenId: string): Promise<void> {
    await this.update(
      `/api/v1/projects/${this.config.project.id}/api_tokens/${apiTokenId}`,
      "delete"
    )
  }

  //---
  // Member
  //---

  listMembers(): Promise<Member[]> {
    return this.fetch(`/api/v1/projects/${this.config.project.id}/members`)
  }

  async deleteMember(memberId: string): Promise<void> {
    await this.update(
      `/api/v1/members/${memberId}`,
      "delete"
    )
  }

  //---
  // Quota
  //---

  getQuotas(): Promise<Quota[]> {
    return this.fetch(
      `/console_api/v1/projects/${this.config.project.id}/quotas`
    )
  }

  getMeters(): Promise<Meter[]> {
    return this.fetch(
      `/console_api/v1/projects/${this.config.project.id}/meters/weekly/-1`
    )
  }

  async createTrialQuotas(): Promise<void> {
    await this.update(
      `/console_api/v1/projects/${this.config.project.id}/quotas.trial`,
      "post"
    )
  }

  async deleteTrialQuotas(): Promise<void> {
    await this.update(
      `/console_api/v1/projects/${this.config.project.id}/quotas.trial`,
      "delete"
    )
  }

  //---
  // Usage
  //---

  getUsageData(type: number): Promise<TimeUsageData> {
    return this.fetch(
      `/console_api/v1/projects/${this.config.project.id}/usage/charts/${type}`
    )
  }

  getUsageSummary(): Promise<UsageSummary> {
    return this.fetch(
      `/console_api/v1/projects/${this.config.project.id}/usage/summary`
    )
  }

  //---
  // PlanBind
  //---

  getPlanBind(projectId = this.config.project.id): Promise<PlanBind> {
    return this.fetch(`/console_api/v1/projects/${projectId}/plan`)
  }

  //---
  // Stripe: PaymentMethod
  //---

  async createPaymentMethod(accountId: string, request: StripePaymentMethodCreate): Promise<void> {
    await this.update(
      `/console_api/v1/accounts/${accountId}/stripe_payment_methods/@new`,
      "post",
      request
    )
  }

  async deletePaymentMethod(accountId: string, paymentMethodId: string): Promise<void> {
    await this.update(
      `/console_api/v1/accounts/${accountId}/stripe_payment_methods/${paymentMethodId}`,
      "delete",
      {}
    )
  }

  async markPaymentMethodAsDefault(accountId: string, paymentMethodId: string): Promise<void> {
    await this.update(
      `/console_api/v1/accounts/${accountId}/stripe_payment_methods/${paymentMethodId}/default`,
      "put",
      {}
    )
  }

  listPaymentMethods(accountId: string): Promise<StripePaymentMethod[]> {
    return this.fetch(
      `/console_api/v1/accounts/${accountId}/stripe_payment_methods`
    )
  }

  //---
  // Charge
  //---

  getCharge(accountId: string, chargeId: string): Promise<Charge> {
    return this.fetch(
      `/console_api/v1/accounts/${accountId}/charges/${chargeId}`
    )
  }

  listCharges(accountId: string): Promise<Charge[]> {
    return this.fetch(
      `/console_api/v1/accounts/${accountId}/charges`
    )
  }

  async getPayment(accountId: string, chargeId: number): Promise<StripePayment> {
    return this.fetch(
      `/console_api/v1/accounts/${accountId}/charges/${chargeId}/stripe_payment`
    )
  }

  async fixCharge(accountId: string, chargeId: number): Promise<void> {
    await this.update(
      `/console_api/v1/accounts/${accountId}/charges/${chargeId}/status`,
      "delete"
    )
  }

  async setChargeStatus(accountId: string, chargeId: number, status: ChargeStatus): Promise<void> {
    await this.update(
      `/console_api/v1/accounts/${accountId}/charges/${chargeId}/status`,
      "put",
      { status }
    )
  }

  //---
  // Subscription
  //---

  async createSub(accountId: string, request: SubCreate): Promise<void> {
    await this.update(
      `/console_api/v1/accounts/${accountId}/subscriptions/@new`,
      "post",
      request
    )
  }

  async cancelSub(accountId: string, subId: string): Promise<void> {
    await this.update(
      `/console_api/v1/accounts/${accountId}/subscriptions/${subId}/cancellation`,
      "post"
    )
  }

  async transferSub(accountId: string, subId: string, targetAccountId: string): Promise<void> {
    await this.update(
      `/console_api/v1/accounts/${accountId}/subscriptions/${subId}/transfer`,
      "post",
      { targetAccountId }
    )
  }

  listSubscriptions(accountId: string): Promise<Sub[]> {
    return this.fetch(
      `/console_api/v1/accounts/${accountId}/subscriptions`
    )
  }

  getSubscription(accountId: string, subId: string): Promise<Sub> {
    return this.fetch(
      `/console_api/v1/accounts/${accountId}/subscriptions/${subId}`
    )
  }

  getEstimateNextCharge(accountId: string, subsId: string, plan?: PlanType): Promise<Bill> {
    let url = `/console_api/v1/accounts/${accountId}/subscriptions/${subsId}/estimate`
    if (plan !== undefined) {
      url += `?plan=${plan}`
    }
    return this.fetch(url)
  }

  //---
  // Debug
  //---

  getTranscodeStatus(broadcastId: string): Promise<TranscodeStatus> {
    return this.fetch(`/console_api/v1/projects/${this.config.project.id}/broadcasts/${broadcastId}/transcode_status`)
  }

  getExtentMatrix(broadcastId: string): Promise<TrackExtentList[]> {
    return this.fetch(`/console_api/v1/broadcasts/${broadcastId}/extents`)
  }

  listMediaQueues(broadcastId: string): Promise<MediaQueueStatus[]> {
    return this.fetch(`/console_api/v1/broadcasts/${broadcastId}/recorder/media_queues`)
  }

  async createBroadcastUsage(broadcastId: string, request: BroadcastUsageCreate): Promise<void> {
    await this.update(
      `/console_api/v1/broadcasts/${broadcastId}/usages/@new`,
      "post",
      request
    )
  }

  async sendCommandToActiveSession(broadcastId: string, name: string): Promise<void> {
    await this.update(
      `/console_api/v1/broadcasts/${broadcastId}/recorder/sessions/@active/commands/${name}`,
      "post"
    )
  }

  //---
  // Disk
  //---

  private toDiskEntryPath(name: string, path?: string): string {
    return path === undefined 
      ? `/console_api/v1/projects/${this.config.project.id}/disks/${name}`
      : `/console_api/v1/projects/${this.config.project.id}/disks/${name}/${encodeURIComponent(path)}`
  }

  async listDisks(): Promise<string[]> {
    return this.fetch(
      `/console_api/v1/projects/${this.config.project.id}/disks`
    )
  }

  // TODO paging
  async listDiskEntries(disk: string): Promise<DiskEntry[]> {
    const entries: any[] = await this.fetch(this.toDiskEntryPath(disk))
    return entries.map(e => ({ ...e, disk }))
  }

  async getDiskEntry(disk: string, path: string): Promise<DiskEntry> {
    return { ...await this.fetch(this.toDiskEntryPath(disk, path)), disk }
  }

  createDLLink(name: string, path: string): Promise<{ url: string }> {
    return this.fetch(this.toDiskEntryPath(name, path) + `/download_link`)
  }

  createULLink(name: string, path: string): Promise<{ url: string }> {
    return this.fetch(this.toDiskEntryPath(name, path) + `/upload_link`)
  }

  async deleteDiskEntry(name: string, path: string): Promise<void> {
    await this.update(this.toDiskEntryPath(name, path), "delete")
  }

  async requestExport(broadcastId: string, variantName: string, format?: string): Promise<void> {
    await this.update(`/console_api/v1/projects/${this.config.project.id}/jobs/media_exports/@new`, "post", {
      broadcastId,
      variantName,
      format
    })
  }

  async requestImport(value: MediaImportRequest): Promise<void> {
    await this.update(`/console_api/v1/projects/${this.config.project.id}/jobs/media_imports/@new`, "post", value)
  }

  //---
  // Job
  //---

  async deleteJob(jobId: string): Promise<void> {
    await this.update(`/console_api/v1/projects/${this.config.project.id}/jobs/${jobId}`, "delete")
  }

  //---
  // Inquiry
  //---

  async sendInquiry(subject: string, content: string, contact?: string): Promise<void> {
    await this.update(`/console_api/v1/inquiries/@new`, "post", {subject, content, contact})
  }

  //---
  // Preview
  //---

  async getPreviewUrl(
    broadcastId: string,
    name: string,
    time: number | "latest" | "oldest" = "latest"
  ): Promise<string> {
    const res = await this.call(
      `/api/v1/broadcasts/${broadcastId}/previews/${name}?redirect=0&time=${time}`
    )
    if (res.status !== 200) {
      throw new HttpLoadError(res.status)
    }
    const obj = await res.json()
    if (obj?.image === undefined) {
      throw new Error()
    }
    return obj.image
  }

  async getPreview(
    broadcastId: string,
    name: string = "console",
    time: number | "latest" | "oldest" = "latest"
  ): Promise<Blob> {
    const url = await this.getPreviewUrl(broadcastId, name, time)
    const res = await fetch(url, {})
    if (res.status !== 200) {
      throw new HttpLoadError(res.status)
    }
    return await res.blob()
  }

  //---
  // WebRTC
  //---

  async createRTCOffer(src: BroadcastRTCOfferCreate): Promise<RtcOffer> {
    const res = await this.update(
      `/api/experimental/rtc/offers/@new`,
      "post",
      src
    )
    if (res.status !== 200) {
      throw new HttpLoadError(res.status)
    }
    return res.json()
  }

  //---
  // Playback
  //---

  async getPlaybackStats(broadcastId: string, duration: "live" | number = "live"): Promise<PlaybackStats> {
    return await this.fetch(
      `/console_api/v1/broadcasts/${broadcastId}/playback_stats?duration=${duration}`
    );
  }

  async getPlaybackCount(broadcastId: string): Promise<PlaybackCount> {
    return await this.fetch(
      `/api/v1/broadcasts/${broadcastId}/playback_count`
    );
  }

  //-----

  private async call(path: string, extra: RequestInit & { headers?: Record<string, string> } = {}): Promise<Response> {
    if (delay > 0) {
      await sleep(delay)
    }
    const headers: Record<string, string> = {
      ...extra.headers,
    }
    const noToken = !(
      headers[webTokenHeaderField] !== undefined ||
      headers[apiTokenHeaderField] !== undefined
    )
    if (noToken) {
      const token = await this.config.session.user?.getIdToken(false)
      if (token === undefined) {
        throw new Error("No ID token available.")
      }
      headers[webTokenHeaderField] = token
    }
    return await fetch(this.baseUrl + path, { ...extra, headers })
  }

  private async fetch<T>(path: string, method = "get"): Promise<T> {
    const res = await this.call(path, { method })
    if (res.status !== 200) {
      throw new HttpLoadError(res.status)
    }
    return await res.json()
  }

  private async update(path: string, method = "post", json: any = {}): Promise<Response> {
    const res = await this.call(path, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(json),
    })
    if (!res.status.toString().startsWith("2")) {
      let error = undefined as any
      try {
        error = await res.json()
      }
      catch {
        /* empty */
      }
      if ("code" in error) {
        throw new CastifyApiError(
          error.code,
          error.message ?? "Something went wrong."
        )
      }
      throw new HttpLoadError(res.status)
    }
    return res
  }
}
