export async function sleep(time: number): Promise<void> {
  return new Promise((fn) => setTimeout(fn, time))
}

export function stay<T>(): Promise<T> {
  return new Promise<T>(() => {})
}

export type Try<Out, Err = any> = Success<Out> | Failure<Err>

export class Success<Out> {
  readonly error?: undefined

  constructor(public readonly value: Out) {}

  get failed(): false {
    return false
  }
}

export class Failure<Err = any> {
  readonly value?: undefined

  constructor(public readonly error: Err) {}

  get failed(): true {
    return true
  }
}

export class CancellationToken {

  constructor(private _cancelled = false) {}

  cancel() {
    this._cancelled = true
  }

  get cancelled(): boolean {
    return this._cancelled
  }
}

interface EventHost<Map> {
  addEventListener<K extends keyof Map>(
    name: K,
    listener: (event: Map[K]) => any,
    options?: boolean | AddEventListenerOptions
  ): void
}

export function waitForEvent<EventMap>(host: EventHost<EventMap>) {
  return function curried<K extends keyof EventMap>(
    name: K
  ): Promise<EventMap[K]> {
    return new Promise((resolve) =>
      host.addEventListener(name, resolve, { once: true })
    )
  }
}

export class Cache<K, V> {

  private bin = new Map<K, Promise<V>>()

  constructor(private load: (key: K) => Promise<V>) {
  }

  async get(key: K): Promise<V> {
    const entry = this.bin.get(key)
    if (entry !== undefined) {
      return entry;
    }
    const out = this.load(key)
    this.bin.set(key, out)
    try {
      return await out
    }
    catch (error) {
      if (out === this.bin.get(key)) {
        this.bin.delete(key)
      }
      throw error
    }
  }
}

export function downloadFile(url: string, filename: string) {
  const anchor = document.createElement("a")
  anchor.href = url
  anchor.setAttribute("download", filename)
  anchor.click()
}
