import React, { ReactElement, ReactNode, useState } from "react"
import { Box } from "~parts/box"

export function Mask(props: { children?: ReactNode }): ReactElement {
  const [hidden, setHidden] = useState<boolean>(true)
  if (hidden) {
    return (
      <Box>
        <button
          onClick={() => setHidden(false)}
          className="btn btn-sm btn-dark"
          type="button"
        >
          クリックして表示
        </button>
      </Box>
    )
  } else {
    return <Box>{props.children}</Box>
  }
}
