import React from "react"
import { app } from "~context/core"
import { CastifyRoute } from "~core/castify-route"
import { Link, useNavigate } from "react-router-dom"
import { AudioTranscoderPreset, BroadcastCreate, VideoTranscoderPreset } from "~core/castify/api"
import { FormProvider, useForm } from "react-hook-form"
import { PageBody, PageHeader } from "~parts/header"
import { Box, VBox } from "~parts/box"
import { useLoader } from "~util/hook"
import { PlanBind, PlanType } from "~core/castify/api/msg/plan"
import { letterBox } from "~util/style"
import { Spinner } from "~parts/spinner"
import { BroadcastOptionForm } from "~parts/broadcast-option-form"

export function BroadcastNewView(props: { projectId: string, plan?: PlanBind }): JSX.Element {

  const navigate = useNavigate()
  const id = React.useId()
  const [trialBroadcasts] = useLoader(async () => {
    if (props.plan?.type !== PlanType.Trial) {
      return { total: 0, values: [] }
    }
    return app.api.listBroadcasts()
  })

  const [error, setError] = React.useState<JSX.Element>()
  const form = useForm<BroadcastCreate>({
    mode: "onBlur",
    defaultValues: {
      name: new Date().toISOString(),
      link: undefined,
      deferred: true,
      options: {
        type: "av",
        live: true,
        previews: undefined,
        variants: [
          {
            name: "default",
            audio: {
              type: "preset",
              preset: AudioTranscoderPreset.PassThrough,
            },
            video: {
              type: "preset",
              preset: VideoTranscoderPreset.PassThrough,
            },
          },
        ],
      },
    },
  })

  const onSubmit = (value: BroadcastCreate) => {
    if (value.name === "") {
      delete value.name
    }
    if (value.link === "") {
      delete value.link
    }
    if (value.customData === "") {
      delete value.customData
    }
    if (value.options !== undefined) {
      value.options.variants = value.options.variants?.filter(x => !!x)
      value.options.previews = value.options.previews?.filter(x => !!x)
    }
    return app.api.createBroadcast(value).then(broadcast => {
      navigate(CastifyRoute.projects.project.broadcasts.broadcast.getPath(props.projectId, broadcast.broadcastId))
    }).catch(() => {
      setError(<>
        不明なエラーが発生しました。
        このエラーが継続する場合は、お手数ですが、
        <Link to={CastifyRoute.projects.project.supports.getPath(props.projectId)} className="page-link">
          お問い合わせフォーム
        </Link>からお問い合わせください。</>)
    })
  }

  const values = form.watch()
  if (values.name === "") {
    delete values.name
  }
  if (values.link === "") {
    delete values.link
  }
  if (values.customData === "") {
    delete values.customData
  }
  if (values.options !== undefined) {
    values.options.variants = values.options.variants?.filter(x => !!x)
    values.options.previews = values.options.previews?.filter(x => !!x)
  }

  if (trialBroadcasts?.value?.values === undefined) {
    return <Box height="100%" {...letterBox}>
      <Spinner />
    </Box>
  }

  const isTrialLimitBroadcasts = trialBroadcasts.value.values.length === 5
  const isTrialLimitLive = trialBroadcasts.value.values.findIndex(x => !x.duration.fixed) !== -1
  const isAnyTrialLimit = isTrialLimitBroadcasts || isTrialLimitLive

  return <>
    <VBox>
      <PageHeader title="配信を作成" />
      <PageBody>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-1">
              {isAnyTrialLimit && (
                <div className="alert alert-warning text-center d-flex flex-column gap-3" role="alert">
                  {isTrialLimitBroadcasts && (
                    <div className="text-danger">
                      トライアルモードのアーカイブ保持数の制限に達しました。<br />
                      <Link to={CastifyRoute.projects.project.broadcasts.getPath(props.projectId)}>配信リスト</Link>から、過去の Broadcast を削除してください。
                    </div>
                  )}
                  {isTrialLimitLive && (
                    <div className="text-danger">
                      トライアルモードの同時配信数の制限に達しました。<br />
                      <Link to={CastifyRoute.projects.project.broadcasts.getPath(props.projectId)}>配信リスト</Link>から、配信中の Broadcast を停止もしくは削除してください。
                    </div>
                  )}
                </div>
              )}

              <label className="form-label">
                <h5>基本情報</h5>
              </label>

              <div>
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    disabled={form.formState.isSubmitting}
                    id={id + "name"}
                    {...form.register("name")}
                  />
                  <label htmlFor={id + "name"}>名前</label>
                </div>

                {form.formState.errors.name &&
                  <p className="text-danger">{form.formState.errors.name?.message}</p>
                }
              </div>

              <div>
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    disabled={form.formState.isSubmitting}
                    id={id + "link"}
                    {...form.register("link")}
                  />
                  <label htmlFor={id + "link"}>リンク先</label>
                </div>

                {form.formState.errors.link &&
                  <p className="text-danger">{form.formState.errors.link?.message}</p>
                }
              </div>
            </div>

            <div className="d-flex gap-2">
              <label className="form-check-label" htmlFor={id + "deferred"}>
                <h5>
                  遅延での開始
                  <a href="https://blog.castify.jp/2023/02/21/deferred/" target="_blank" rel="noopener noreferrer">
                    <i className="fas fa-question-circle fa-fw" />
                  </a>
                </h5>
              </label>

              <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id={id + "deferred"} {...form.register("deferred")} />
              </div>
            </div>

            <BroadcastOptionForm plan={props.plan} />

            <div>
              <label htmlFor={id + "customData"} className="form-label">
                <h5>カスタムデータ</h5>
              </label>

              <textarea
                className="form-control"
                id={id + "customData"}
                disabled={form.formState.isSubmitting}
                rows={3}
                {...form.register("customData")}
              />

              {form.formState.errors.customData &&
                <p className="text-danger">{form.formState.errors.customData?.message}</p>
              }
            </div>

            <div>
              <button type="submit" className="btn btn-primary" disabled={form.formState.isSubmitting || isAnyTrialLimit}>作成</button>
            </div>

            {error && (
              <span className="text-danger">{error}</span>
            )}

            <div>
              <label className="form-label">
                <h6>この Broadcast を作成するコマンド</h6>
              </label>

              <pre>
                <code>
                  <span>curl -X POST \</span><br />
                  <span>  -H &apos;Authorization: Bearer $API_TOKEN&apos; \</span><br />
                  <span>  -H &apos;Content-Type: application/json&apos; \</span><br />
                  <span>  -d &apos;{JSON.stringify(values)}&apos; \</span><br />
                  <span>  &quot;{app.api.baseUrl + app.api.createBroadcastPath()}&quot;</span><br />
                </code>
              </pre>
            </div>
          </form>
        </FormProvider>
      </PageBody>
    </VBox>
  </>
}
