import { Link } from "react-router-dom"
import { app } from "~context/core"
import { Project } from "~core/castify/api/msg/project"
import { Cache } from "~util/async"
import { useLoader2 } from "~util/hook"
import { CastifyRoute } from "~core/castify-route"


export function ProjectLink(props: { id?: string }) {

  const [project] = useLoader2(async (id) => id && projects.get(id), [props.id])

  if (!project?.value) {
    return <small className="text-muted">-</small>
  }
  else {
    return <Link to={CastifyRoute.projects.project.getPath(project.value.id)}>
      { project?.value?.displayName ?? props.id }
    </Link>
  }
}

const projects = new Cache<string, Project>(id => app.api.getProject(id))
