import React, { ReactElement } from "react"
import { Box, VBox } from "~parts/box"

export function EmptyIndicator(): ReactElement {
  return (
    <VBox className="text-secondary" alignItems="center" padding="1rem">
      <Box>
        <h1>
          <i className="fas fa-list"></i>
        </h1>
      </Box>
      <Box className="h4" marginTop="0.5rem">
        No items.
      </Box>
      <Box marginTop="0.5rem">
        右上の <i className="fas fa-plus-square"></i>{" "}
        から新しい項目を作成できます。
      </Box>
    </VBox>
  )
}

export function Empty(): ReactElement {
  return (
    <VBox className="text-secondary" alignItems="center" padding="1rem">
      <Box>
        <h1>
          <i className="fas fa-list"></i>
        </h1>
      </Box>
      <Box className="h4" marginTop="0.5rem">
        No items.
      </Box>
    </VBox>
  )
}
