import React, { ReactElement } from "react"
import { BehaviorSubject } from "rxjs"
import { ModalBackdrop } from "~parts/dialog"
import { useBehavior } from "~util/form"

export type ModalProps<T = void> = {
  /**
   * @private
   */
  _onModalResult?: (_: T) => void
}

class ModalDisplay {
  readonly $dialog = new BehaviorSubject<ReactElement | null>(null)

  show(dialog: ReactElement) {
    this.$dialog.next(dialog)
  }

  readonly dismiss = () => {
    this.$dialog.next(null)
  }

  bind(dialog: ReactElement): () => void {
    return () => this.show(dialog)
  }
}

export const modal = new ModalDisplay()

export function DialogHost(): ReactElement {
  const dialog = useBehavior(modal.$dialog)

  return (
    <React.Fragment>
      {dialog && <ModalBackdrop>{dialog}</ModalBackdrop>}
    </React.Fragment>
  )
}
