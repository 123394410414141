import React, { ReactElement, ReactNode, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { app, getDB } from "~context/core"
import { modal } from "~context/modal"
import { castifyBackendApps, castifyEvents } from "~core/castify/config"
import { Box } from "~parts/box"
import { useBinder } from "~util/form"
import { formatDate } from "~util/format"
import { letterBox } from "~util/style"
import { JSONViewer } from "./code"
import { Dialog } from "./dialog"
import { useQuery } from "./paged"
import { Spinner } from "./spinner"
import { CastifyRoute } from "~core/castify-route"
import { localizedMessages } from "../core/localization"

type LogData = {
  timestamp: number
  code: number
  note: string | null
  args?: any
  info?: any
  severity: number
  operator: any
  system?: string
}

type Log = {
  id: string
  data: LogData
}

const logCount = 5000

const severityList = [
  { level: 100, name: "debug" },
  { level: 200, name: "info" },
  { level: 400, name: "warn" }
]

function toLevelName(level: number): string {
  return severityList.find((e) => e.level >= level)?.name ?? "error"
}

function toServiceName(src?: string): ReactNode {
  if (src === undefined) {
    return "N/A"
  }
  const app = castifyBackendApps[src]
  if (app === undefined) {
    return `Unknown: ${src}`
  } else {
    return app.title
  }
}

const db = getDB()

function parseDateTime(dateValue: string, timeValue: string): Date | undefined {
  if (!dateValue.match(/^(\d+(-\d+)*)?$/)) return
  if (!timeValue.match(/^(\d+(:\d+)*)?$/)) return
  const dateTime = new Date()
  if (dateValue !== "") {
    const parts = dateValue.split("-").map((e) => parseInt(e))
    let i = 0
    if (parts.length >= 3) {
      dateTime.setFullYear(parts[i++] ?? 0)
    }
    if (parts.length >= 2) {
      dateTime.setMonth((parts[i++] ?? 0) - 1)
    }
    dateTime.setDate(parts[i++] ?? 0)
  }
  if (timeValue !== "") {
    const parts = timeValue.split(":").map((e) => parseInt(e))
    dateTime.setSeconds(parts[2] ?? 0)
    dateTime.setMinutes(parts[1] ?? 0)
    dateTime.setHours(parts[0] ?? 0)
  }
  return dateTime
}

function LogDateTimeForm(props: { projectId: string, time?: Date }): ReactElement {
  let initialDate = ""
  let initialTime = ""

  if (props.time) {
    initialDate = [
      props.time.getFullYear(),
      props.time.getMonth() + 1,
      props.time.getDate()
    ].join("-")

    initialTime = [
      props.time.getHours(),
      props.time.getMinutes() || [],
      props.time.getSeconds() || []
    ]
      .flatMap((e) => e)
      .join(":")
  }

  const navigate = useNavigate()
  const date = useBinder(initialDate)
  const time = useBinder(initialTime)
  const [goal, setGoal] = useState<Date | undefined>()

  function submit() {
    const dateTime = parseDateTime(date.value.trim(), time.value.trim())
    if (dateTime !== undefined) {
      setGoal(dateTime)
    }
  }

  if (goal !== undefined) {
    navigate(CastifyRoute.projects.project.auditLogs.getPath(props.projectId) + "?time=" + goal.getTime())
    return <></>
  }

  return (
    <form
      className="input-group input-group-sm"
      onSubmit={(e) => e.preventDefault()}
    >
      <span className="input-group-text">
        <i className="fas fa-history" />
      </span>
      <input
        {...date}
        type="text"
        className="form-control"
        placeholder="yyyy-MM-dd"
      />
      <input
        {...time}
        type="text"
        className="form-control"
        placeholder="HH:mm:ss"
      />
      <button className="btn btn-outline-secondary" onClick={submit}>
        Go
      </button>
    </form>
  )
}

function LogDetailDialog(props: { src: LogData }): ReactElement {
  return (
    <Dialog title="Raw Data">
      <Box
        margin="1rem"
        width="40rem"
        maxWidth="70vw"
        height="20rem"
        maxHeight="70vh"
      >
        <JSONViewer src={props.src} />
      </Box>
    </Dialog>
  )
}

function Note(props: { src: LogData }): ReactElement {
  if (props.src.code !== 0) {
    return <span className="badge rounded-pill bg-info text-dark">{castifyEvents[props.src.code]}</span>
  }
  const { note } = props.src
  if (note) {
    return <>{
      (localizedMessages.get(note) ?? note)
      .replace(/%<(\w+)>/g, (_m, sym) => 
        props.src.args?.[sym] ?? 
        props.src.info?.[sym]
      )
    }</>
  }
  return <></>
}

function LogList(props: { projectId: string, cols?: string[][], src: Log[] }): ReactElement {

  const rows: ReactNode[] = []
  let date = ""
  for (const e of props.src) {
    const aux = new Date(e.data.timestamp)
    const key = formatDate(e.data.timestamp)
    if (key !== date) {
      date = key
      rows.push(
        <tr key={key}>
          <th colSpan={5}>
            <i className="far fa-calendar-alt" /> {key}
          </th>
        </tr>
      )
    }

    const specialCols: ReactNode[] = []
    if (props.cols?.length) {
      outer:
        for (const path of props.cols) {
          let node: any = e.data
          for (const item of path) {
            if (typeof node !== "object" || node === null) {
              continue outer
            }
            node = node[item]
          }
          if (node !== undefined) {
            specialCols.push(
              <span key={specialCols.length} className="badge rounded-pill bg-secondary">
              {typeof node === "object" ? JSON.stringify(node) : `${node}`}
            </span>
            )
          }
        }
    }

    rows.push(
      <tr key={e.id}>
        <td className="fit-sized" data-level={toLevelName(e.data.severity)} />
        <td className="fit-sized text-monospace">
          {aux.getHours().toString().padStart(2, "0")}:
          {aux.getMinutes().toString().padStart(2, "0")}:
          {aux.getSeconds().toString().padStart(2, "0")}
        </td>
        <td>
          {specialCols} <Note src={e.data} />
        </td>
        <td className="fit-sized">{toServiceName(e.data.system)}</td>
        <td className="fit-sized">
          <button
            onClick={() => modal.show(<LogDetailDialog src={e.data} />)}
            className="btn btn-sm"
            type="button"
          >
            <i className="fas fa-code" />
          </button>
          <Link
            className="btn btn-sm btn-link"
            to={CastifyRoute.projects.project.auditLogs.auditLog.getPath(props.projectId, e.id)}
          >
            Link
          </Link>
        </td>
      </tr>
    )
  }

  return (
    <Box
      backgroundColor="#fff"
      className="cas-log-viewer"
      maxHeight="66vh"
      overflow="scroll"
      display="flex"
      flexDirection="column-reverse"
    >
      <table>
        <thead>
          <tr>
            <th>Level</th>
            <th>
              Time <i className="fas fa-sort-amount-down-alt" />
            </th>
            <th>Message</th>
            <th>Service</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </Box>
  )
}

type LogQuery = { type: string; id: string } | { type: "project" }

function toQuery(props: { query: LogQuery, time?: Date }) {
  const events = db.collection(`projects/${app.project.id}/events`)
  let query = events.where("type", "==", props.query.type)
  if ("id" in props.query) {
    query = query.where("entityId", "==", props.query.id)
  }
  if (props.time !== undefined) {
    query = query.where("timestamp", "<=", props.time.getTime())
  }
  return query.orderBy("timestamp", "asc").limitToLast(logCount)
}

export function LogIndex(props: {
  projectId: string
  query: LogQuery
  cols?: string[][]
}): ReactElement {

  let time = undefined
  const timeValue = useQuery().get("time")
  if (timeValue !== null) {
    time = new Date(parseInt(timeValue))
  }

  const [logs, setLogs] = useState<Log[] | undefined>()

  const [debug, setDebug] = useState(false)

  useEffect(() =>
    toQuery(props).onSnapshot(snapshot => {
      const logs: Log[] = []
      snapshot.forEach(doc => {
        const data = doc.data() as LogData
        if (debug || data.severity > 100) {
          logs.push({ id: doc.id, data })
        }
      })
      setLogs(logs)
    }),
    [props.query, debug]
  )

  if (logs === undefined) {
    return <Box height="100%" {...letterBox}><Spinner /></Box>
  }
  else {
    return (
      <div>
        <LogList projectId={props.projectId} cols={props.cols} src={logs} />
        <div className="mt-3 row justify-content-start align-items-center">
          <div className="col" style={{ maxWidth: "22rem" }}>
            <LogDateTimeForm projectId={props.projectId} time={time} />
          </div>
          <div className="col nowrap">
            <div className="form-check">
              <label className="form-check-label">
                <input className="form-check-input" type="checkbox" checked={debug} onChange={e => setDebug(e.target.checked)} />
                Debug
              </label>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export function LogEntry(props: { projectId: string, logId: string }) {
  const { projectId, logId } = props
  const [data, setData] = useState<LogData | undefined>()

  useEffect(
    () =>
      db
        .doc(`projects/${projectId}/events/${logId}`)
        .onSnapshot((snapshot) => {
          setData(snapshot.data() as LogData)
        }),
    [logId]
  )

  if (data === undefined) {
    return (
      <Box height="100%" {...letterBox}>
        <Spinner />
      </Box>
    )
  } else {
    return <LogList projectId={projectId} src={[{ id: logId, data }]} />
  }
}
