import React, { ReactElement } from "react"
import { app } from "~context/core"
import { SenderDialog } from "~parts/dialog"
import { useSender } from "~util/hook"
import { CastifyRoute } from "~core/castify-route"
import { useNavigate } from "react-router-dom"

export function WebhookDeleteDialog(props: {
  id: string
  onEnd?: () => void
}): ReactElement {

  const navigate = useNavigate()
  const deleter = useSender(async () => {
    await app.api.deleteWebhook(props.id)
  })

  const onEnd = props.onEnd ?? (() => {
    navigate(CastifyRoute.projects.project.webhooks.getPath(app.project.id))
    return <></>
  })

  return (
    <SenderDialog sender={deleter} onEnd={onEnd}>
      <div className="px-4 pt-4">
        この Webhook を削除します。本当によろしいですか？
      </div>
    </SenderDialog>
  )
}
