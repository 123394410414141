import React, { ReactElement, useId } from "react"
import { useNavigate } from "react-router-dom"
import { useLoader, useLoader2 } from "~util/hook"
import { app } from "~context/core"
import { waitFor } from "~parts/spinner"
import { PageBody, PageHeader } from "~parts/header"
import { PropertyList, PropertyListItem } from "~parts/property-list"
import { Panel } from "~parts/panel"
import { formatDateTime, formatDecimal, formatPeriod } from "~util/format"
import { PlanType, planTitles } from "~core/castify/api/msg/plan"
import { BillItem } from "~core/castify/api/msg/payment"
import { ProjectLink } from "~parts/link"
import { Dropdown, DropdownItem } from "~parts/dropdown"
import { modal } from "~context/modal"
import { SubscriptionCancelDialog, SubscriptionTransferDialog } from "./dialog"
import { CastifyRoute } from "~core/castify-route"
import { useBoolean } from "~util/form"
import { useQuery } from "~parts/paged"

function EstimateItemView(props: { src: BillItem }): ReactElement {
  return (
    <tr>
      <td>
        {props.src.product.description}
      </td>
      <td style={{ fontFamily: "monospace" }}>
        { formatPeriod(props.src.targetPeriod) }
      </td>
      <td className="cas-numeric">
        { formatDecimal(props.src.count) }
      </td>
      <td className="px-2">
        { props.src.product.unit ?? "" }
      </td>
      <td className="cas-numeric">
        { formatDecimal(props.src.price, 1) }
      </td>
    </tr>
  )
}

function EstimateListView(props: { subscriptionId: string, accountId: string }): ReactElement {

  const query = useQuery()

  const [estimate] = useLoader(async () => {
    if (query.get("debug") === "1") {
      return app.api.getEstimateNextCharge(props.accountId, props.subscriptionId, PlanType.Test)
    }
    else {
      return app.api.getEstimateNextCharge(props.accountId, props.subscriptionId)
    }
  })

  const subtotal = estimate?.value?.items.reduce((acc, e) => acc + e.price, 0)

  const checkbox = useId()

  const showAll = useBoolean()

  return <>
    <div className="form-check small my-3">
      <input className="form-check-input" type="checkbox" id={checkbox} {...showAll} />
      <label className="form-check-label" htmlFor={checkbox}>
        0円の項目を表示
      </label>
    </div>
    { waitFor(estimate, src => (
      <table className="table table-sm">
        <thead>
          <tr>
            <th>内容</th>
            <th>対象期間</th>
            <th style={{textAlign: "right"}}>利用量</th>
            <th />
            <th style={{textAlign: "right"}}>料金 (税抜)</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {src.items.filter(e => showAll.checked || e.price > 0).map((e, i) => (
            <EstimateItemView key={i} src={e} />
          ))}
          <tr style={{ borderColor: "transparent" }}>
            <th colSpan={4}>
              小計
            </th>
            <td className="cas-numeric">
              {formatDecimal(subtotal ?? 0, 1)}
            </td>
          </tr>
        </tbody>
      </table>
    ))}
  </>
}

export function SubscriptionInfoPage(props: { accountId: string }): ReactElement {

  const { accountId } = props
  const { subscriptionId } = CastifyRoute.accounts.account.subscriptions.subscription.usePath()
  const [sub] = useLoader2(id => app.api.getSubscription(accountId, id), [subscriptionId])

  const navigate = useNavigate()

  function goBack() {
    navigate(CastifyRoute.accounts.account.subscriptions.getPath(accountId))
  }

  return <>{ waitFor(sub, src =>
    <>
      <PageHeader title="課金ステータス">
        <Dropdown title="操作">
          { !src.cancelled && (
            <>
              <DropdownItem
                onClick={modal.bind(<SubscriptionTransferDialog
                  accountId={accountId} src={src} onChange={goBack}
                />)}
              >
                請求先アカウントの変更
              </DropdownItem>
              <DropdownItem
                onClick={modal.bind(<SubscriptionCancelDialog
                  accountId={accountId} src={src} onChange={goBack}
                />)}
                danger={true}
              >
                解約
              </DropdownItem>
            </>
          )}
        </Dropdown>
      </PageHeader>
      <PageBody>
        <Panel>
          <PropertyList>
            <PropertyListItem name="契約プラン名">
              { planTitles[src.plan] }
            </PropertyListItem>
            <PropertyListItem name="対象プロジェクト">
              <ProjectLink id={src.projectId} />
              &nbsp;
              <span>({src.projectId})</span>
            </PropertyListItem>
            <PropertyListItem name="開始日時">
              { formatDateTime(src.createdAt) }
            </PropertyListItem>
          </PropertyList>
        </Panel>
        <Panel title="お見積り金額" className="mt-3">
          <p className="text-muted">
            現在の利用状況に応じた次回のお見積り金額です。
          </p>
          <EstimateListView subscriptionId={subscriptionId} accountId={accountId} />
        </Panel>
      </PageBody>
    </>
  )}</>
}
