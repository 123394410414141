import React, { ReactElement } from "react"
import { Link, useNavigate } from "react-router-dom"
import { app, executeReCAPTCHA } from "~context/core"
import { modal } from "~context/modal"
import { Invitation, Project } from "~core/castify/api/msg/project"
import { Box, VBox } from "~parts/box"
import { ActionDialog, SenderDialog } from "~parts/dialog"
import { PageBody, PageHeader } from "~parts/header"
import { EmptyIndicator } from "~parts/placeholder"
import { waitFor, waitForList } from "~parts/spinner"
import { useBehavior, useBinder } from "~util/form"
import { formatDateTime } from "~util/format"
import { useLoader, useSender } from "~util/hook"
import { CastifyRoute } from "~core/castify-route"

function ProjectCreateDialog(props: { onEnd?(): void }): ReactElement {
  const displayName = useBinder()

  const sender = useSender(async () => {
    const token = await executeReCAPTCHA()
    await app.api.createProject({ displayName: displayName.value, token })
  })

  return (
    <SenderDialog title="プロジェクトの新規作成" sender={sender} onEnd={props.onEnd}>
      <div className="py-3 px-2" style={{ minWidth: "24rem" }}>
        <input
          {...displayName}
          type="text"
          className="form-control"
          placeholder="プロジェクト名"
        />
      </div>
    </SenderDialog>
  )
}

function ProjectItem(props: { src: Project }): ReactElement {
  return (
    <div key={props.src.id} className="col">
      <VBox className="card">
        <Box className="card-body">
          <Box
            className="card-title h6 text-nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {props.src.displayName}
          </Box>
          <div className="card-text">
            {props.src.description ?? <Box color="#777">No description.</Box>}
          </div>
          <Link
            className="btn btn-primary btn-sm mt-3"
            to={CastifyRoute.projects.project.getPath(props.src.id)}
          >
            このプロジェクトを開く
          </Link>
        </Box>
        <div className="card-footer">
          <small className="text-muted">
            {formatDateTime(props.src.updatedAt)}
          </small>
        </div>
      </VBox>
    </div>
  )
}

function InvitationItem(props: { src: Invitation, onDecline: () => void }): ReactElement {
  const navigate = useNavigate()
  const sender = useSender(async () => {
    await app.api.takeMyInvitation(props.src.id, true)

    // Invalidates the old session w/o the access to the project.
    const session = app.$session.value
    if (session && !session.privileged) {
      session.liveness.complete()
    }
  })
  if (sender.result?.failed === false) {
    navigate(CastifyRoute.projects.project.getPath(props.src.project.id))
    return <></>
  }

  return (
    <div className="col-xl-4 col-md-6 col-sm-12">
      <VBox className="card" margin="1rem 0.5rem">
        <Box className="card-body">
          <Box
            className="card-title h6 text-nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {props.src.project.displayName}
          </Box>
          <div className="card-text">
            {props.src.project.description ?? <Box color="#777">No description.</Box>}
          </div>
          <Box className="mt-2">
            <button
              className="btn btn-primary btn-sm mt-1 me-1"
              onClick={() => sender.send()}
            >
              招待を承認する
            </button>
            <button
              className="btn btn-primary btn-sm btn-danger mt-1"
              onClick={() => props.onDecline()}
            >
              <i className="fas fa-trash" />
              拒否
            </button>
          </Box>
        </Box>
        <div className="card-footer">
          <small className="text-muted">
            <div>
              <a href={`mailto:${props.src.user.email}`}>{props.src.user.displayName}</a> さんがあなたを招待しました
            </div>
          </small>
        </div>
      </VBox>
    </div>
  )
}

export function ProjectListScreen(): ReactElement {
  const session = useBehavior(app.$session)

  const [projects] = useLoader(
    async () => session?.loadProjects() ?? [],
    [session],
  )

  const [invitations, reloadInvitations] = useLoader(() =>
    app.api.listMyInvitations(),
  )

  function declineInvitation(invitation: Invitation) {
    modal.show(
      <ActionDialog
        action={() => app.api.takeMyInvitation(invitation.id, false)}
        onEnd={() => reloadInvitations()}
      >
        {invitation.user.displayName} からの招待を断ってもよろしいですか？
      </ActionDialog>,
    )
  }

  return (
    <>
      <PageHeader title="プロジェクト一覧">
        <button
          onClick={modal.bind(
            <ProjectCreateDialog onEnd={() => session?.liveness.complete()} />,
          )}
          type="button"
          className="btn btn-primary btn-sm"
        >
          <i className="fas fa-plus" />
        </button>
      </PageHeader>
      <PageBody>
        {waitFor(invitations, (src) => {
          // waitForList を使うと invitation がないときに EmptyIndicator が表示されてしまう
          if (src.length > 0) {
            return <>
              <h3 className="h3">招待されているプロジェクト</h3>
              <Box className="row g-0 mb-5">
                {src.map((e) => (
                  <InvitationItem key={e.id} src={e} onDecline={() => declineInvitation(e)} />
                ))}
              </Box>
            </>
          } else {
            return <></>
          }
        })}
        <h3 className="h3">参加中のプロジェクト</h3>
        <Box className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mt-3">
          {
            waitForList(projects, src => src.map(e => <ProjectItem key={e.id} src={e} />)) ?? <EmptyIndicator />
          }
        </Box>
      </PageBody>
    </>
  )
}
