import { CSSProperties, ReactElement, ReactNode } from "react"
import { app } from "~context/core"
import { modal } from "~context/modal"
import { Box, VBox } from "~parts/box"
import { AlertText, SenderDialog } from "~parts/dialog"
import { Dropdown, DropdownItem } from "~parts/dropdown"
import { PageBody, PageHeader } from "~parts/header"
import { Loader } from "~parts/loader"
import { useBinder } from "~util/form"
import { formatBytes } from "~util/format"
import { useLoader, useSender } from "~util/hook"
import { FeedList } from "./cards/feed"
import { FigureCard } from "./cards/figure"
import { UsageChart } from "./cards/usage"
import { CastifyRoute } from "~core/castify-route"
import { PlanType } from "~core/castify/api/msg/plan"
import { Link, useNavigate } from "react-router-dom"

// TODO error
function ProjectSettingDialog(): ReactElement {
  const displayName = useBinder(app.project.displayName)

  const sender = useSender(async () => {
    const changes = {
      displayName: displayName.value,
    }
    await app.api.updateProject(changes)
    const project = app.$project.value
    if (project) {
      app.$project.next({
        ...project,
        ...changes,
      })
    }
  })

  return (
    <SenderDialog title="Rename Project" sender={sender}>
      <VBox minWidth="24rem">
        <Box margin="1.5rem 1.5rem 0 1.5rem">
          <input
            {...displayName}
            type="text"
            className="form-control"
            placeholder="Name"
          />
        </Box>
      </VBox>
    </SenderDialog>
  )
}

const checkRemainingLives = true

function ProjectDeleteDialog(): ReactElement {
  const navigate = useNavigate()
  const deleter = useSender(async () => {
    if (checkRemainingLives) {
      const { total } = await app.api.listBroadcasts(0, "IN_PROGRESS")
      if (total > 0) {
        throw new Error("配信中の Broadcast が残っています。")
      }
    }
    await app.api.deleteProject()
  })
  if (deleter.result?.failed === false) {
    navigate(CastifyRoute.projects.path)
  }
  return <SenderDialog sender={deleter}>
    <div className="px-4 pt-4">
      プロジェクトを削除します。本当によろしいですか？
    </div>
    <div className="alert alert-warning mt-3 mx-3 p-2 small">
      <i className="fas fa-exclamation-circle me-2" />
      既存の配信／視聴は 5~10 分後にすべて停止します。
    </div>
  </SenderDialog>
}

// noinspection JSUnusedLocalSymbols
export function OverviewScreen(_props: { projectId: string }): ReactElement {
  const [bind] = useLoader(() => app.api.getPlanBind(), [])
  return (
    <VBox position="relative">
      <PageHeader title="プロジェクトの情報">
        <Dropdown title="操作">
          <DropdownItem onClick={modal.bind(<ProjectSettingDialog />)}>
            プロジェクト情報の編集
          </DropdownItem>
          <DropdownItem danger onClick={modal.bind(<ProjectDeleteDialog />)}>
            プロジェクトを削除
          </DropdownItem>
        </Dropdown>
      </PageHeader>
      <PageBody>
        <VBox alignItems="center">
          { bind?.value?.type === PlanType.Trial && <>
            <div className="mt-2 alert alert-info py-3">
              このプロジェクトは現在トライアル中です。<a className="alert-link" href="https://www.castify.jp/limitations">制限事項</a>がありますのでご注意下さい。本番でのサービス利用を開始される前に<a>アップグレード（トライアルの終了）</a>をお願い致します。また、5週間を超えて配信が作成されなかった場合、<strong>プロジェクトが削除</strong>されますのでご注意下さい。
            </div>
          </> }
          <FigureCollection />
          <Box className="row mt-4 w-100">
            <Box className="col-lg-6 col-sm-12">
              <Card title="配信メディアの転送量">
                <UsageChart type={1} />
              </Card>
            </Box>
            <Box className="col-lg-6 col-sm-12 mt-4 mt-md-0">
              <Card title="入力メディアの転送量">
                <UsageChart type={2} />
              </Card>
            </Box>
          </Box>
          <Box className="mt-4 w-100">
            <Card title="News">
              <FeedList />
            </Card>
          </Box>
        </VBox>
      </PageBody>
    </VBox>
  )
}

namespace backgrounds {
  export const a: CSSProperties = {
    background:
      "linear-gradient(45deg, rgba(9,175,173,1) 35%, rgba(5,125,170,1) 66%)",
  }

  export const b: CSSProperties = {
    background:
      "linear-gradient(45deg, rgba(251,153,239,1) 35%, rgba(255,37,122,1) 66%)",
  }

  export const c: CSSProperties = {
    background:
      "linear-gradient(45deg, rgba(92,228,140,1) 28%, rgba(20,212,171,1) 69%)",
  }
}

function Card(props: { title: string; children?: ReactNode }): ReactElement {
  return (
    <Box className="card small">
      <div className="card-header">{props.title}</div>
      <div className="card-body">{props.children}</div>
    </Box>
  )
}

function FigureCollection(): ReactElement {
  return (
    <Loader
      id="figure-collection"
      of={() => app.api.getUsageSummary()}
      then={(e) => (
        <Box className="row justify-content-center w-100 px-4 px-md-0">
          <Box className="col-12 col-md-8 col-lg-3 mt-4">
            <FigureCard
              styles={backgrounds.a}
              legend="Current Viewers"
              figure={e.viewers.toLocaleString()}
            >
              <i className="fas fa-eye" />
            </FigureCard>
          </Box>
          <Box className="col-12 col-md-8 col-lg-3 mt-4">
            <FigureCard
              styles={backgrounds.b}
              legend="Disk Usage"
              figure={formatBytes(e.storage)}
            >
              <i className="fas fa-database" />
            </FigureCard>
          </Box>
          <Box className="col-12 col-md-8 col-lg-3 mt-4">
            <FigureCard
              styles={backgrounds.c}
              legend="Recent Incidents"
              figure="0"
            >
              <i className="fas fa-bug" />
            </FigureCard>
          </Box>
        </Box>
      )}
    />
  )
}
