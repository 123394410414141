import React, { CSSProperties, ReactNode } from "react"

type BoxProps = CSSProperties & {
  children?: ReactNode
  className?: string
}

export function Box(props: BoxProps) {
  const { children, className, ...style } = props
  return (
    <div className={className} style={style}>
      {children}
    </div>
  )
}

const HBoxStyles: CSSProperties = {
  display: "flex",
  flexDirection: "row",
}

export function HBox(props: BoxProps) {
  const { children, className, ...style } = props
  return (
    <div className={className} style={{ ...HBoxStyles, ...style }}>
      {children}
    </div>
  )
}

const VBoxStyles: CSSProperties = {
  display: "flex",
  flexDirection: "column",
}

export function VBox(props: BoxProps) {
  const { children, className, ...style } = props
  return (
    <div className={className} style={{ ...VBoxStyles, ...style }}>
      {children}
    </div>
  )
}
