import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { app } from "~context/core"
import { PlanType } from "~core/castify/api/msg/plan"
import { AlertText, SenderDialog } from "~parts/dialog"
import { waitFor } from "~parts/spinner"
import { useBinder } from "~util/form"
import { useLoader, useLoader2, useSender } from "~util/hook"
import { AccountCreateDialog } from "../account/list"
import { PaymentMethodCreateDialog } from "../account/pages/payment_method"
import { CastifyRoute } from "~core/castify-route"

type PlanChangeDialogProps = {
  type: PlanType
  onChange?: () => any
}

export function PlanChangeDialog(props: PlanChangeDialogProps) {

  const navigate = useNavigate()

  const [mode, setMode] = useState<"account" | "pm">()

  const account = useBinder()

  const sender = useSender(async () => {
    const projectId = app.project.id
    const accountId = account.value
    if (accountId === "") {
      throw new Error("請求先アカウントが未選択です。")
    }
    await app.api.createSub(accountId, { projectId, plan: props.type })
    navigate(CastifyRoute.accounts.account.subscriptions.getPath(accountId))
  })

  const [pms, reloadPMs] = useLoader2(async (id) => {
    if (id) {
      return app.api.listPaymentMethods(id)
    }
  }, [account.value])

  const [accounts, reloadAccounts] = useLoader(() => app.api.listAccounts())

  switch (mode) {
  case "account":
    return <AccountCreateDialog
      onCancel={() => setMode(undefined)}
      onChange={() => {
        reloadAccounts()
        setMode(undefined)
        return true
      }}
    />
  case "pm":
    return <PaymentMethodCreateDialog
      accountId={account.value}
      onCancel={() => setMode(undefined)}
      onChange={() => {
        reloadPMs()
        setMode(undefined)
        return true
      }}
    />
  default:
    return (
      <SenderDialog title="Basic プランに加入" sender={sender}>
        <div className="" style={{ minWidth: "300px" }}>
          <div className="mt-4 mx-4 d-flex flex-column">
            <label>
              請求先アカウントを選択
            </label>
            <select {...account} className="mt-3 form-control form-select">
              <option value="" disabled hidden>
                未選択
              </option>
              {waitFor(accounts, src => src.map(e => <option key={e.accountId} value={e.accountId}>{e.name}</option>))}
            </select>
            <div className="mt-1 align-self-end">
              <button onClick={() => setMode("account")} type="button" className="btn btn-link btn-sm">請求先アカウントを作成</button>
            </div>
          </div>
          {pms?.value?.length === 0 && (
            <AlertText>
              請求先アカウントにお支払い方法が未登録です。
              <div>
                <button onClick={() => setMode("pm")} type="button" className="btn btn-link btn-sm">
                  お支払い方法を登録
                </button>
              </div>
            </AlertText>
          )}
        </div>
      </SenderDialog>
    )
  }
}
