import { ReactElement } from "react"
import { app } from "~context/core"
import { waitFor } from "~parts/spinner"
import { useLoader, useSender } from "~util/hook"
import { SenderDialog } from "~parts/dialog"
import { useBinder } from "~util/form"
import { VBox } from "~parts/box"
import { Charge, ChargeStatus } from "~core/castify/api/msg/payment"
import { useStripe } from "@stripe/react-stripe-js"


type ChargeProps = {
  accountId: string,
  src: Charge
  onChange?(): void
}

export function PaymentUpdateDialog(props: ChargeProps): ReactElement {

  const paymentMethod = useBinder()

  const stripe = useStripe()

  const [paymentMethods] = useLoader(() => app.api.listPaymentMethods(props.accountId))

  const sender = useSender(async () => {
    if (stripe === null) {
      throw Error("Stripe SKD is not available.")
    }
    const payment_method = paymentMethod.value
    if (payment_method === "") {
      throw Error("お支払い方法をご選択下さい。")
    }
    const { secret } = await app.api.getPayment(
      props.accountId,
      props.src.chargeId
    )
    const { error } = await stripe.confirmCardPayment(secret, { payment_method })
    // noinspection LoopStatementThatDoesntLoopJS
    while (error) {
      if (error.code === "payment_intent_unexpected_state") {
        const { paymentIntent } = await stripe.retrievePaymentIntent(secret)
        if (paymentIntent?.status === "succeeded") {
          break;
        }
      }
      throw new Error(`ERROR ${error.code}`)
    }
    await app.api.fixCharge(
      props.accountId,
      props.src.chargeId
    )
    props.onChange?.()
  })

  return (
    <SenderDialog sender={sender} title="お支払い">
      <VBox margin="1rem 1.5rem 0 1.5rem">
        { waitFor(paymentMethods, src => (
          <select {...paymentMethod} className="form-control form-select">
            <option value="" disabled>
              お支払い方法を選択
            </option>
            { src.map(e => (
              <option key={e.paymentMethodId} value={e.paymentMethodId}>
                {e.name}
              </option>
            ))}
          </select>
        ))}
      </VBox>
    </SenderDialog>
  )
}

export function ChargeFixDialog(props: ChargeProps): ReactElement {

  const status = useBinder(`${ChargeStatus.CLOSED}`)

  const sender = useSender(async () => {
    await app.api.setChargeStatus(
      props.accountId,
      props.src.chargeId,
      parseInt(status.value) as ChargeStatus
    )
  })

  return (
    <SenderDialog sender={sender} onEnd={props.onChange} title="支払いステータスの変更">
      <VBox margin="1rem 1.5rem 0 1.5rem">
        <select {...status} className="form-control form-select">
          <option value={ChargeStatus.CLOSED}>支払い済</option>
          <option value={ChargeStatus.INITIAL}>初期化</option>
        </select>
      </VBox>
    </SenderDialog>
  )
}
