import React, { ReactElement } from "react"
import { app } from "~context/core"
import { Box, VBox } from "~parts/box"
import { SpinnerButton } from "~parts/spinner"
import { useSender } from "~util/hook"
import { elevate } from "~util/style"

export function EMailNotTrustedPage(): ReactElement {
  const sender = useSender(() => app.logout())

  return (
    <Box
      className="row g-0 justify-content-md-center align-items-md-center"
      minHeight="100%"
      backgroundColor="#f1f1f1"
    >
      <Box className="col-md-4 col-sm-12">
        <VBox {...elevate(2)} backgroundColor="white" className="py-5 px-4">
          <Box className="h5" alignSelf="center">
            Castify の利用制限
          </Box>
          <p className="text-muted small mt-3">
            現在 Castify
            のご利用は、直接お問い合わせ頂いたお客様のみに制限しております。お問い合わせは
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSeO264P8KKXVyFCb7Vdps0DCf423t_QJrtAe0Fc58FuDxp7nw/viewform">
              こちら
            </a>
            からお願いします。
          </p>
          <Box className="mt-3" alignSelf="center">
            <SpinnerButton
              className="btn btn-primary"
              onClick={sender.send}
              animated={!sender.loaded}
            >
              ログアウト
            </SpinnerButton>
          </Box>
        </VBox>
      </Box>
    </Box>
  )
}
