import React, { ReactElement } from "react"
import { app } from "~context/core"
import { WebhookGetResult, WebhookUpdate } from "~core/castify/api/msg/webhook"
import { castifyWebhooks } from "~core/castify/config"
import { Box, VBox } from "~parts/box"
import { SenderDialog } from "~parts/dialog"
import { useSender } from "~util/hook"
import { useForm } from "react-hook-form"

export function WebhookUpdateDialog(props: {
  src: WebhookGetResult
  onEnd?: () => void
}): ReactElement {
  const { register, getValues, formState: { isValid, errors } } = useForm<WebhookUpdate>({
    mode: "onBlur",
    defaultValues: {
      uri: props.src.uri,
      kinds: props.src.kinds,
    }
  })

  const sender = useSender(async () => {
    if (!isValid) {
      console.error(errors)
      return
    }
    await app.api.updateWebhook(props.src.id, getValues())
  })

  const checks = castifyWebhooks.map((name) => (
    <Box
      key={name}
      className="form-check"
      fontSize="10pt"
      fontFamily="monospace"
    >
      <label className="form-check-label">
        <input
          {...register("kinds", {
            required: {
              value: true,
              message: "必須要素です。",
            },
          })}
          className="form-check-input"
          type="checkbox"
          value={name}
        />
        {name}
      </label>
    </Box>
  ))
  return (
    <SenderDialog title="Webhook の編集" sender={sender} onEnd={props.onEnd} disabled={!isValid}>
      <VBox minWidth="24rem">
        <Box margin="1rem 1.5rem 0 1.5rem">
          <input
            {...register("uri", {
              required: {
                value: true,
                message: "必須要素です。",
              },
              validate: {
                isValidURL: (value) => {
                  try {
                    console.log(new URL(value))
                    new URL(value)
                    return true
                  } catch (e) {
                    return "URL として解釈できない文字列です。"
                  }
                },
              },
            })}
            type="text"
            className="form-control"
            placeholder="URL"
          />
          {errors.uri ? <p className="text-danger">{errors.uri?.message}</p> : <></>}
        </Box>
        <VBox margin="1rem 1.5rem 0 1.5rem">
          <Box fontSize="10pt" color="#666">
            Webhook の種別
          </Box>
          <Box marginLeft="0.5rem" marginTop="0.5rem">
            {checks}
            {errors.kinds ? <p className="text-danger">{errors.kinds?.message}</p> : <></>}
          </Box>
        </VBox>
      </VBox>
    </SenderDialog>
  )
}
