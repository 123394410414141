import React, { ReactElement } from "react"
import { app } from "~context/core"
import { InlinePlayer } from "~parts/player/player"
import { useLoader } from "~util/hook"
import { CastifyRoute } from "~core/castify-route"

export function PlayerView(): ReactElement {
  const { broadcastId } = CastifyRoute.player.usePath()

  const [ott] = useLoader(() => {
    if (broadcastId !== null) {
      return app.api.createOTT(broadcastId)
    }
  }, [broadcastId])

  if (broadcastId === null || !ott?.value) {
    return <></>
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <InlinePlayer
        broadcastId={broadcastId}
        ott={ott.value}
        width="100%"
        height="100%"
      />
    </div>
  )
}
