import React, { ReactElement, ReactNode } from "react"
import { app, platform } from "~context/core"
import { Box, VBox } from "~parts/box"
import { Spinner } from "~parts/spinner"
import { useLoader } from "~util/hook"
import { letterBox } from "~util/style"
import { CastifyRoute } from "~core/castify-route"
import { Link, NavLink, Outlet, Route } from "react-router-dom"
import { FloatingPlayer } from "~parts/player"
import { navigation } from "~context/nav"
import { useBehavior } from "~util/form"
import { items, MenuItem } from "~pages/dashboard/menu"
import { signalSet } from "~context/signal"
import { PlanBind, PlanType } from "~core/castify/api/msg/plan"
import { version } from "../../../package.json"
import { BroadcastNewView } from "~pages/broadcast/new"
import { BroadcastDetailView } from "~pages/broadcast/main"
import { WebhookDetailView } from "~pages/webhook/main"
import { DiskEntryView } from "~pages/disk/entry"
import { AuditLogScreen } from "~pages/audit_log/detail"
import { Navigate, Routes } from "react-router"
import { BroadcastImportView } from "~pages/broadcast/import"
import { DiskEntryList } from "~pages/disk"

function MenuItemView(props: {
  projectId: string,
  src: MenuItem,
  badge?: number,
}): ReactElement | null {
  const session = useBehavior(app.$session)
  const notAllowed = session?.hasAll(props.src.requiredScopes) !== true

  if (notAllowed) {
    return (
      <a className="cas-sidebar-item disabled">
        <VBox color="#aaa" width="3rem" alignItems="center">
          <i className={"fas " + props.src.icon} />
        </VBox>
        <s style={{ color: "#999" }}>{props.src.name}</s>
        &nbsp;
        <i className="fas fa-lock" />
      </a>
    )
  } else {
    return (
      <NavLink
        className={({ isActive }) => "cas-sidebar-item d-flex align-items-center ps-4 pe-3" + (isActive ? " cas-sidebar-item-active" : "")}
        to={props.src.path.getPath(props.projectId)}
      >
        <div style={{ color: "#aaa", width: "2rem" }}>
          <i className={"fas " + props.src.icon} />
        </div>
        <div className="flex-grow-1">
          {props.src.name}
        </div>
        <div hidden={!props.badge}>
          <span className="badge badge-small rounded-pill bg-danger">
            {props.badge}
          </span>
        </div>
      </NavLink>
    )
  }
}

type MenuGroupProps = {
  title: string
  children?: ReactNode
}

function MenuGroup(props: MenuGroupProps): ReactElement {
  return (
    <Box marginBottom="0.66rem">
      <Box color="#bbb" padding="0.5rem 1rem">
        {props.title}
      </Box>
      {props.children}
    </Box>
  )
}

function MenuList(props: { projectId: string, plan?: PlanBind }): ReactElement {

  const project = useBehavior(app.$project)
  const session = useBehavior(app.$session)
  const signals = useBehavior(signalSet)

  return (
    <VBox height="calc(100% - 60px)" position="fixed" width="240px" overflow="auto">
      <Box color="white" margin="1.25rem 1rem">
        <div>
          <div className="d-flex align-items-center">
            {project?.displayName}
            <Link to={CastifyRoute.projects.path} className="btn btn-sm text-light">
              <i className="fas fa-sign-out-alt" />
            </Link>
          </div>
          {props.plan?.type === PlanType.Trial &&
            <div className="mt-2">
              <span className="badge rounded-pill bg-light text-dark">
                TRIAL
              </span>
              <small className="mx-2">
                <Link to={CastifyRoute.projects.project.catalog.getPath(props.projectId)} className="text-info">アップグレード</Link>
              </small>
            </div>
          }
        </div>
      </Box>

      <MenuGroup title="Home">
        <MenuItemView projectId={props.projectId} src={items.overview} />
        <MenuItemView projectId={props.projectId} src={items.broadcasts} />
        <MenuItemView projectId={props.projectId} src={items.broadcastNew} />
      </MenuGroup>

      <MenuGroup title="Development">
        <MenuItemView projectId={props.projectId} src={items.tokens} />
        <MenuItemView projectId={props.projectId} src={items.webhooks} />
        <a
          className="cas-sidebar-item"
          href={app.config.documentUrl}
          target="_blank"
          rel="noreferrer"
        >
          <VBox color="#aaa" width="3rem" alignItems="center">
            <i className="fas fa-book" />
          </VBox>
          ドキュメント&nbsp;
          <i className="fas fa-external-link-alt" />
        </a>
      </MenuGroup>

      <MenuGroup title="Admin">
        <MenuItemView projectId={props.projectId} src={items.members} />
        <MenuItemView projectId={props.projectId} src={items.invitations} />
        {props.plan?.type !== PlanType.Trial &&
          <MenuItemView projectId={props.projectId} src={items.disks} />
        }
      </MenuGroup>

      <MenuGroup title="Monitor">
        <MenuItemView projectId={props.projectId} src={items.jobs} badge={signals.scheduledJobs} />
        <MenuItemView projectId={props.projectId} src={items.auditLog} />
        <MenuItemView projectId={props.projectId} src={items.usage} badge={signals.exceededQuotas} />
      </MenuGroup>

      <MenuGroup title="Support">
        <MenuItemView projectId={props.projectId} src={items.inquiry} />
      </MenuGroup>

      {(!platform.public || session?.privileged) && (
        <>
          <MenuGroup title="Debug">
            <MenuItemView projectId={props.projectId} src={items.debug} />
            <MenuItemView projectId={props.projectId} src={items.recorder} />
          </MenuGroup>

          <Box color="#777" fontSize="8pt" marginTop="auto" padding="1.5rem 0 0.25rem 0.5rem">
            ver <Link to={CastifyRoute.about.path}>{version}</Link>
          </Box>
        </>
      )}

    </VBox>
  )
}

export function ProjectDashboard(): ReactElement {

  const { projectId } = CastifyRoute.projects.project.usePath()

  const [project] = useLoader(async () => {
    const project = await app.api.getProject(projectId)
    if (project.id !== app.$project.value?.id) {
      app.$project.next(project)
    }
    return {project}
  }, [projectId])

  const [plan] = useLoader(async () => {
    const plan = await app.api.getPlanBind(projectId)
    app.$plan.next(plan)
    return plan
  }, [projectId])

  const hidden = !useBehavior(navigation.$showsSideMenu)

  if (!project?.value || !plan?.value) {
    return <Box height="100%" {...letterBox}>
      <Spinner />
    </Box>
  }

  return (
    <div className="cas-sidebar position-relative d-flex" style={{ minHeight: "inherit" }}>
      <div className="menu" hidden={hidden}>
        <MenuList projectId={projectId} plan={plan?.value} />
      </div>

      <Box
        className="main"
        flexGrow={1}
        position="relative"
        overflow="auto"
      >
        <Routes>
          <Route path="*" element={<Navigate to={CastifyRoute.projects.project.overview.getPath(projectId)} replace={true} />} />
          <Route path={CastifyRoute.projects.project.newBroadcast.path} element={<BroadcastNewView projectId={projectId} plan={plan?.value} />} />
          <Route path={CastifyRoute.projects.project.newBroadcast.import.path} element={<BroadcastImportView projectId={projectId} plan={plan?.value} />} />
          <Route path={CastifyRoute.projects.project.broadcasts.broadcast.path} element={<BroadcastDetailView projectId={projectId} />} />
          <Route path={CastifyRoute.projects.project.webhooks.webhook.path} element={<WebhookDetailView projectId={projectId} />} />
          <Route path={CastifyRoute.projects.project.auditLogs.auditLog.path} element={<AuditLogScreen projectId={projectId} />} />
          
          <Route 
            path="disks/:disk/:file" 
            element={<DiskEntryView projectId={projectId} />} 
          />
          <Route 
            path="disks/:disk" 
            element={<DiskEntryList />} 
          />
          
          {Object.values(items).map((e) => (
            <Route key={e.name} path={e.path.path} element={e.component(projectId)} />
          ))}
        </Routes>

        <Outlet />

        <Box
          position="fixed"
          right="0.5rem"
          bottom="0.5rem"
          className="cas-floating-player"
        >
          <FloatingPlayer />
        </Box>
      </Box>
      <div
        onMouseDown={navigation.toggleSideMenu}
        className="backdrop"
        hidden={hidden}
      />
    </div>
  )
}
