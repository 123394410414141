import { PredefinedRole } from "../api/msg/role"
import { WebhookKind } from "../api/msg/webhook"

export type CastifyEnv = "prod" | "dev" | "local"

export type CastifyPlatform = {
  env: CastifyEnv
  public?: boolean
  baseUrl: string
  mediaCluster: {
    api: string
  }
  firebaseOptions: object
  stripe: {
    apiKey: string
  }
  documentUrl: string
  reCAPTCHA: {
    siteKey: string
  }
}

const reCAPTCHA = {
  siteKey: "6LdTtngiAAAAAEd6d67_CHnbCaBPC591H-LDkPgu"
}

export namespace CastifyPlatform {
  export const prod: CastifyPlatform = {
    env: "prod",
    public: true,
    baseUrl: "https://api.castify.jp",
    mediaCluster: {
      api: "https://edge-api.castify.jp"
    },
    firebaseOptions: {
      apiKey: "AIzaSyCW5Y9DrQmNvRfb-o2BOALzs2SY3N3ZcDc",
      authDomain: "castify-prod.firebaseapp.com",
      databaseURL: "https://castify-prod.firebaseio.com",
      projectId: "castify-prod",
      storageBucket: "castify-prod.appspot.com",
      messagingSenderId: "335071829472",
      appId: "1:335071829472:web:90d6621a31784c097c5f85",
    },
    stripe: {
      apiKey: "pk_live_r4tRumXJzvqz0nGZoJ6A0zRV"
    },
    documentUrl: "https://doc.castify.jp/",
    reCAPTCHA
  }

  export const dev: CastifyPlatform = {
    env: "dev",
    baseUrl: "https://api.dev.castify.jp",
    mediaCluster: {
      api: "https://edge-api.dev.castify.jp"
    },
    firebaseOptions: {
      apiKey: "AIzaSyAquRIgnrumiyM1IMaBxPLtIV2nSZbdusE",
      authDomain: "evln-lab.firebaseapp.com",
      databaseURL: "https://evln-lab.firebaseio.com",
      projectId: "evln-lab",
      storageBucket: "evln-lab.appspot.com",
      messagingSenderId: "564149903511",
      appId: "1:564149903511:web:c332a6372c9b9dbc3009d2",
    },
    stripe: {
      apiKey: "pk_test_eiqHIFZTzXlF4vyQtIi5f13S"
    },
    documentUrl: "https://doc.dev.castify.jp/",
    reCAPTCHA
  }

  export const local: CastifyPlatform = {
    env: "local",
    baseUrl: "http://localhost:8080",
    mediaCluster: {
      api: "http://localhost:4201"
    },
    stripe: {
      apiKey: "pk_test_eiqHIFZTzXlF4vyQtIi5f13S"
    },
    firebaseOptions: {
      projectId: "local",

      // https://stackoverflow.com/a/72839398/2681195
      appId: 'local',
      apiKey: 'local',
      authDomain: "localhost"
    },
    documentUrl: "https://doc.dev.castify.jp/",
    reCAPTCHA
  }
}

type BackendServiceInfo = {
  title: string
}

export const castifyBackendApps: { [name: string]: BackendServiceInfo } = {
  "cas-sink": {
    title: "Origin",
  },
  "cas-edge": {
    title: "Edge",
  },
  "cas-transcoder-gpu": {
    title: "Transcoder",
  },
  "cas-transcoder-cpu": {
    title: "Transcoder",
  },
  "cas-api": {
    title: "API",
  },
  "cas-coordinator": {
    title: "Coordinator",
  },
  "cas-bot-helper": {
    title: "Batch",
  },
}

export const castifyEvents: { [code: number]: string } = {
  0x00_00_00_00: "LOG",

  0x00_00_01_01: "MEMBER_CREATED",
  0x00_00_01_02: "MEMBER_DELETED",

  0x00_00_02_01: "WEBHOOK_CREATED",
  0x00_00_02_02: "WEBHOOK_DELETED",
  0x00_00_02_03: "WEBHOOK_UPDATED",

  0x00_00_03_01: "API_TOKEN_CREATED",
  0x00_00_03_02: "API_TOKEN_DELETED",

  0x00_00_04_01: "BROADCAST_CREATED",
  0x00_00_04_02: "BROADCAST_DELETED",
  0x00_00_04_10: "BROADCAST_STOPPED",
}

export const castifyWebhooks = [
  WebhookKind.BROADCAST_CREATE,
  WebhookKind.BROADCAST_CREATED,
  WebhookKind.BROADCAST_DELETED,
  WebhookKind.BROADCAST_FINISHED,
  WebhookKind.PLAYBACK_CREATE,
  WebhookKind.PLAYBACK_CREATED,
  WebhookKind.RTMP_PUBLISH,
  WebhookKind.RUN_CREATED,
  WebhookKind.RUN_STOPPED,
]

export const castifyRoles = [
  PredefinedRole.OWNER,
  PredefinedRole.ADMIN,
  PredefinedRole.VIEWER,
  PredefinedRole.FINANCE,
  PredefinedRole.OUTSIDER,
]

export const castifyDevPortalUrl = "https://github.com/castify"

export const castifyLogo =
  "https://storage.googleapis.com/public.castify.jp/img/castify.svg"
