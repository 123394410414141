// noinspection JSUnusedGlobalSymbols
export type WebhookGetResult = {
  /**
   * Webhook を一意に特定する ID です。
   */
  id: string
  /**
   * Webhook が所属するプロジェクトの ID です。
   */
  projectId: string
  /**
   * この Webhook の名前です。人間的な意味づけにのみ利用され、システムからは利用されません。
   */
  name: string
  /**
   * この Webhook が対象とするイベントの種別です。
   * これらのイベントが発生した際に、この Webhook が実行されます。
   */
  kinds: WebhookKind[]
  /**
   * この Webhook の送信先となる URL です。
   */
  uri: string
  /**
   * この Webhook で利用される、秘密鍵となるトークンです。
   * この情報は秘匿情報として取り扱ってください。
   */
  token: string
  /**
   * この Webhook の作成時刻です。
   */
  createdAt: number
  /**
   * この Webhook の最終更新時刻です。
   */
  updatedAt: number
}

export enum WebhookKind {
  BROADCAST_CREATE = "BROADCAST_CREATE",
  BROADCAST_CREATED = "BROADCAST_CREATED",
  BROADCAST_FINISHED = "BROADCAST_FINISHED",
  BROADCAST_DELETED = "BROADCAST_DELETED",

  PLAYBACK_CREATE = "PLAYBACK_CREATE",
  PLAYBACK_CREATED = "PLAYBACK_CREATED",

  RUN_CREATED = "RUN_CREATED",
  RUN_STOPPED = "RUN_STOPPED",

  RTMP_PUBLISH = "RTMP_PUBLISH",
}

// noinspection JSUnusedGlobalSymbols
export type WebhookCreate = {
  /**
   * この Webhook の名前です。人間的な意味づけにのみ利用され、システムからは利用されません。
   *
   * @annotations { "kt": ["field:javax.validation.constraints.Size(min = 2, max = 64)"] }
   */
  name: string
  /**
   * この Webhook が対象とするイベントの種別です。
   * これらのイベントが発生した際に、この Webhook が実行されます。
   */
  kinds: WebhookKind[]
  /**
   * この Webhook の送信先となる URL です。
   */
  uri: string
}

// noinspection JSUnusedGlobalSymbols
export type WebhookUpdate = {
  /**
   * この Webhook が対象とするイベントの種別です。
   * これらのイベントが発生した際に、この Webhook が実行されます。
   */
  kinds: WebhookKind[]
  /**
   * この Webhook の送信先となる URL です。
   */
  uri: string
}
