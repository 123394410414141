import { BehaviorSubject } from "rxjs"
import { projectChange } from "./core"

class Player {
  readonly $request = new BehaviorSubject<string | null>(null)

  play(broadcastId: string) {
    this.$request.next(broadcastId)
  }

  stop() {
    this.$request.next(null)
  }
}

export const player = new Player()

projectChange.subscribe(() => {
  player.$request.next(null)
})
