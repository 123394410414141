import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { app } from "~context/core"
import { player } from "~context/player"
import { useBehavior } from "~util/form"
import { useLoader } from "~util/hook"
import { Box, VBox } from "../box"
import { InlinePlayer } from "./player"
import { CastifyRoute } from "~core/castify-route"

export function FloatingPlayer(): ReactElement {
  const id = useBehavior(player.$request)

  const [ott] = useLoader(() => {
    if (id !== null) {
      return app.api.createOTT(id)
    }
  }, [id])

  if (id === null || !ott?.value) {
    return <></>
  }
  return (
    <VBox>
      <Box alignSelf="flex-end" marginBottom="3px">
        <Link
          to={CastifyRoute.player.getPath(id)}
          target="_blank"
          onClick={() => player.stop()}
          style={{ marginRight: 3 }}
        >
          <button type="button" className="btn btn-sm btn-success">
            <i className="fas fa-external-link-alt" />
          </button>
        </Link>
        <button
          onClick={() => player.stop()}
          type="button"
          className="btn btn-sm btn-success"
        >
          <i className="fas fa-compress-alt" />
        </button>
      </Box>
      <InlinePlayer broadcastId={id} ott={ott.value} width={320} height={180} />
    </VBox>
  )
}
