import React, { ReactElement } from "react"
import { app } from "~context/core"
import { BroadcastGetResult } from "~core/castify/api"
import { UsageKind } from "~core/castify/api/msg/usage"
import { Box, HBox, VBox } from "~parts/box"
import { JSONViewer } from "~parts/code"
import { SenderDialog } from "~parts/dialog"
import { waitFor } from "~parts/spinner"
import { useBinder } from "~util/form"
import { useLoader, useSender } from "~util/hook"

export function BroadcastUsageCreateDialog(props: { id: string }): ReactElement {

  const targets = [
    {
      label: "Recorder Traffic",
      unit: "GiB",
      kind: UsageKind.V2_RECORDER_TRAFFIC,
      form: useBinder("0"),
      scale: 1024 ** 3,
    },
    {
      label: "Streamer Traffic",
      unit: "GiB",
      kind: UsageKind.V2_STREAMER_TRAFFIC,
      form: useBinder("0"),
      scale: 1024 ** 3,
    },
    {
      label: "Transcode Time (Audio)",
      unit: "hour",
      kind: UsageKind.TRANSCODE_AUDIO,
      form: useBinder("0"),
      scale: 60 * 60 * 1000,
    },
    {
      label: "Transcode Time (h264/30fps/240p)",
      unit: "hour",
      kind: UsageKind.TRANSCODE_H264_30F_P240,
      form: useBinder("0"),
      scale: 60 * 60 * 1000,
    },
    {
      label: "Transcode Time (h264/30fps/480p)",
      unit: "hour",
      kind: UsageKind.TRANSCODE_H264_30F_P480,
      form: useBinder("0"),
      scale: 60 * 60 * 1000,
    },
    {
      label: "Transcode Time (h264/30fps/720p)",
      unit: "hour",
      kind: UsageKind.TRANSCODE_H264_30F_P720,
      form: useBinder("0"),
      scale: 60 * 60 * 1000,
    },
    {
      label: "Transcode Time (h264/30fps/1080p)",
      unit: "hour",
      kind: UsageKind.TRANSCODE_H264_30F_P1080,
      form: useBinder("0"),
      scale: 60 * 60 * 1000,
    },
    {
      label: "Transcode Time (h264/30fps/1440p)",
      unit: "hour",
      kind: UsageKind.TRANSCODE_H264_30F_P1440,
      form: useBinder("0"),
      scale: 60 * 60 * 1000,
    },
  ]

  const sender = useSender(async () => {

    const tasks: Promise<void>[] = []

    Object.values(targets).forEach(x => {
      const { kind, form, scale } = x
      const value = parseInt(form.value)
      if (!isNaN(value) && value > 0) {
        tasks.push(app.api.createBroadcastUsage(props.id, {
          kind,
          value: value * scale,
        }))
      }
    })

    await Promise.all(tasks)
  })

  return <SenderDialog title="利用量の追加" sender={sender}>
    <VBox className="p-4" minWidth="27rem">
      {targets.map(target =>
        <HBox key={target.kind} alignItems="center">
          <Box className="small text-monospace" color="#666" width="8rem">
            {target.label}
          </Box>
          <Box flexGrow={1}>
            <div className="input-group">
              <input {...target.form} type="number" className="form-control" />
              <span className="input-group-text">{target.unit}</span>
            </div>
          </Box>
        </HBox>,
      )}
    </VBox>
  </SenderDialog>

}

export function BroadcastDebugView(props: { src: BroadcastGetResult }): ReactElement {
  const [playableRanges] = useLoader(async () => {
    return app.api.listPlayableRanges(props.src.broadcastId)
  })

  const [extents] = useLoader(async () => {
    return app.api.getExtentMatrix(props.src.broadcastId)
  })
  
  const [status] = useLoader(async () => {
    return app.api.getTranscodeStatus(props.src.broadcastId)
  })

  const [queues] = useLoader(async () => {
    return app.api.listMediaQueues(props.src.broadcastId)
  })

  return (
    <VBox>
      <div>
        <h4>Playable Ranges</h4>
        <div className="mt-3">
          {waitFor(playableRanges, (src) => (
            <JSONViewer src={src} />
          ))}
        </div>
      </div>

      <div className="mt-4">
        <h4>Media Queues</h4>
        <div className="mt-3">
          {waitFor(queues, (src) => (
            <JSONViewer src={src} />
          ))}
        </div>
      </div>

      <div>
        <h4>Transcode Status</h4>
        <div className="mt-3">
          {waitFor(status, (src) => (
            <JSONViewer src={src} />
          ))}
        </div>
      </div>

      <div className="mt-4">
        <h4>Extents</h4>
        <div className="mt-3">
          {waitFor(extents, (src) => (
            <JSONViewer src={src} />
          ))}
        </div>
      </div>
    </VBox>
  )
}
