import React, { ReactElement } from "react"
import { Link } from "react-router-dom"
import { app } from "~context/core"
import { PageBody, PageHeader } from "~parts/header"
import { Empty } from "~parts/placeholder"
import { waitForList } from "~parts/spinner"
import { useLoader } from "~util/hook"
import { Dropdown, DropdownItem } from "~parts/dropdown"


// noinspection JSUnusedLocalSymbols
export function DiskList(): ReactElement {
  const [list] = useLoader(() => app.api.listDisks())

  return (
    <div className="position-relative">
      <PageHeader title="ストレージ">
        <Dropdown title="操作">
          <DropdownItem disabled>
            ディスクを追加
          </DropdownItem>
        </Dropdown>
      </PageHeader>
      <PageBody>
      {
        waitForList(list, src => (
          <table className="cas-table">
            <thead>
              <tr>
                <th>名前</th>
                <th>作成日時</th>
                <th />
              </tr>
            </thead>
            <tbody>
              { src.map(e =>
                <tr key={e}>
                  <td><Link to={`./${e}`}>{e}</Link></td>
                  <td>-</td>
                  <td className="cas-cell-shrink">
                    <button className={"btn btn-sm"} disabled={e === 'default'}>
                      <i className="fas fa-trash text-secondary" />
                    </button>
                  </td>
                </tr>
              ) }
            </tbody>
          </table>
        )) ?? <Empty />
      }
      </PageBody>
    </div>
  )
}
