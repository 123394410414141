const substitutions = [
  {
    in: "",
    out: ""
  },

  //---
  // cas-sink
  //---
  {
    in: "[%<track>] Base timestamp: %<value>",
    out: "[%<track>] タイムスタンプのリセット: %<value>"
  },
  {
    in: "Timestamp-gap to the master track is too wide.",
    out: "トラック間のタイムスタンプの差が大き過ぎるため処理が続行できません。"
  },
  {
    in: "Unstable media reception.",
    out: "メディアの受信ペースが不安定です。"
  },
  {
    in: "Negative timestamp, ignored.",
    out: "タイムスタンプの逆行を検出しました。"
  },
  {
    in: "Long keyframe interval: %<interval>ms",
    out: "長いキーフレーム間隔を検出: %<interval>ms"
  },
  {
    in: "[%<track>] Connecting to a transcoder: %<url>",
    out: "[%<track>] トランスコーダーに接続します: %<url>"
  },
  {
    in: "[%<track>] Disconnected from a transcoder.",
    out: "[%<track>] トランスコーダーから切断されました。"
  },
  {
    in: "Recorder is stopping due to an error from downstream.",
    out: "録画が停止されました。"
  },
  {
    in: "Recorder is stopping due to an error from upstream.",
    out: "ネットワーク側の問題により録画が停止されました。"
  },
  
  //---
  // cas-transcoder
  //---
  {
    in: "Transcode aborted.",
    out: "トランスコード処理がエラーにより停止しました。"
  },

  //---
  // cas-coordinator
  //---
  {
    in: "Starting a recording session.",
    out: "レコーディングが開始しました。"
  },
  {
    in: "Stopping a recording session.",
    out: "レコーディングを停止しました。"
  },
  {
    in: "Stopping an inactive recorder.",
    out: "しばらく接続されていないため配信を終了します。"
  },
  {
    in: "Stopped a recorder.",
    out: "配信を終了しました。"
  },
  {
    in: "Invalid token.",
    out: "認証トークンが不正です。"
  },

  //---
  // cas-api
  //---
  {
    in: "The user has already been an member of the account.",
    out: "そのユーザーは既に請求先アカウントに登録済みです。"
  },
  {
    in: "Cannot delete the last one user.",
    out: "請求先アカウントの最後のユーザーは削除できません。"
  },
  {
    in: "Transcoding is unavailable for the trial mode.",
    out: "トランスコード機能はトライアルモードでは利用できません。"
  },
  {
    in: "There must not be two variants with the same name.",
    out: "バリアントの名前はユニークである必要があります。"
  },
  {
    in: "There must not be two previews with the same name.",
    out: "プレビューの名前はユニークである必要があります。"
  },
  {
    in: "Too many ongoing lives for trial mode.",
    out: "トライアルモードでは同時配信は制限されています。"
  },
  {
    in: "Already stopped.",
    out: "既に停止しています。"
  },
  {
    in: "The media source is too large to export.",
    out: "ソースのメディアが大き過ぎるためエクスポートできません。"
  },
  {
    in: "There are active subscriptions associated to the account.",
    out: "有効なサブスクリプションが残っているため請求先アカウントを削除できません。"
  },
  {
    in: "There are unsettled charges in the account.",
    out: "未支払いの請求が残っているため請求先アカウントを削除できません。"
  },
  {
    in: "No valid card numbers registered.",
    out: "有効なクレジットカードが未登録です。"
  },
  {
    in: "There is an active subscription associated to the project.",
    out: "有効なサブスクリプションが残っているためプロジェクトを削除できません。"
  },
  {
    in: "Cannot leave the project as the sole owner of it.",
    out: "プロジェクトの唯一のオーナーはプロジェクトから離脱できません。"
  },
  {
    in: "Same account.",
    out: "移行先が同一の請求先アカウントです。"
  },
  {
    in: "Too many broadcasts for trial mode.",
    out: "トライアルモードではこれ以上配信を作成できません。"
  }
]

export const localizedMessages = new Map<string, string>()

for (const e of substitutions) {
  localizedMessages.set(e.in, e.out)
}
