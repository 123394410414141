import { ReactElement } from "react"
import { app } from "~context/core"
import { modal } from "~context/modal"
import { PlanType } from "~core/castify/api/msg/plan"
import { spinner } from "~parts/spinner"
import { useLoader } from "~util/hook"
import { PlanChangeDialog } from "./dialog"

// noinspection JSUnusedLocalSymbols
export function CatalogPage(props: {projectId: string}): ReactElement {

  const [plan] = useLoader(() => app.api.getPlanBind())

  if (plan === undefined) {
    return spinner
  }
  const trial = plan.value?.type === PlanType.Trial

  return <div className="container">
    <div className="my-5 text-center">
      <h1>料金プラン一覧</h1>
      <div className="mt-3 text-muted">
        以下よりご希望の料金プランをご選択ください。
      </div>
    </div>
    <div className="row justify-content-around align-items-stretch g-3">
      <div className="col-12 col-md-4">
        <div className="h-100 shadow border-0 bg-white p-4">
          <h4>Trial</h4>
          <div className="mt-2">
            トライアル用途の制限あり配信
          </div>
          <ul className="mt-2 py-2">
            <li>無料</li>
            <li>制限された配信</li>
            <li>制限されたアーカイブ保存</li>
          </ul>
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="h-100 shadow border-0 bg-white p-4">
          <h4>Basic</h4>
          <div className="mt-2">
            小〜中規模プラットフォームの配信
          </div>
          <ul className="mt-2 py-2">
            <li>無制限の配信量</li>
            <li>無制限のアーカイブ保存</li>
            <li>トランスコード</li>
          </ul>
          {
            trial && (
              <button
                className="btn btn-primary"
                onClick={modal.bind(<PlanChangeDialog type={PlanType.Basic} />)}
              >
                切り替える
              </button>
            )
          }
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="h-100 shadow border-0 bg-white p-4">
          <h4>Enterprise</h4>
          <div className="mt-2">
            大規模プラットフォームの配信
          </div>
          <ul className="mt-2 py-2">
            <li>無制限の配信量</li>
            <li>無制限のアーカイブ保存</li>
            <li>トランスコード</li>
          </ul>
          ご希望の場合は<a href="https://docs.google.com/forms/d/e/1FAIpQLSeO264P8KKXVyFCb7Vdps0DCf423t_QJrtAe0Fc58FuDxp7nw/viewform">こちら</a>からお問合せください。
        </div>
      </div>
    </div>
  </div>
}
