import React, { ReactElement } from "react"
import { app } from "~context/core"
import { modal } from "~context/modal"
import { APIToken } from "~core/castify/api/msg/tokens"
import { Box, VBox } from "~parts/box"
import { ActionDialog } from "~parts/dialog"
import { PageBody, PageHeader } from "~parts/header"
import { Mask } from "~parts/mask"
import { EmptyIndicator } from "~parts/placeholder"
import { PopOverList } from "~parts/popover-list"
import { waitForList } from "~parts/spinner"
import { formatDateTime } from "~util/format"
import { useLoader } from "~util/hook"
import { APITokenCreateDialog } from "./editor/create"

function ListItem(props: { onEnd(): void, src: APIToken }): ReactElement {
  function onDelete() {
    modal.show(
      <ActionDialog
        action={() => app.api.deleteAPIToken(props.src.id)}
        onEnd={props.onEnd}
      >
        API トークン &apos;{props.src.name}&apos; を削除します。よろしいですか？
      </ActionDialog>
    )
  }
  return (
    <tr>
      <td className="cas-cell-shrink">
        <Box paddingRight="0.33rem">
          <input type="checkbox" />
        </Box>
      </td>
      <td>{props.src.name}</td>
      <td>
        <PopOverList>{props.src.roles}</PopOverList>
      </td>
      <td>{formatDateTime(props.src.createdAt)}</td>
      <td>
        <Box minWidth="12rem">
          <Mask>
            <code>{props.src.token}</code>
          </Mask>
        </Box>
      </td>
      <td className="cas-cell-shrink">
        <button onClick={onDelete} className="btn btn-sm">
          <i className="fas fa-trash text-secondary" />
        </button>
      </td>
    </tr>
  )
}

// noinspection JSUnusedLocalSymbols
export function APITokenList(props: {projectId: string}) {
  const [list, reloadList] = useLoader(() => app.api.listAPITokens())

  return (
    <VBox>
      <PageHeader title="API トークンの管理">
        <button
          onClick={modal.bind(<APITokenCreateDialog onEnd={reloadList} />)}
          type="button"
          className="btn btn-primary btn-sm"
        >
          <i className="fas fa-plus" />
        </button>
      </PageHeader>
      <PageBody>
      {
        waitForList(list, (src) => (
          <table className="cas-table">
            <thead>
              <tr>
                <th />
                <th>名前</th>
                <th>ロール</th>
                <th>作成日時</th>
                <th>トークン文字列</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {src.map((e) => (
                <ListItem key={e.id} src={e} onEnd={reloadList} />
              ))}
            </tbody>
          </table>
        )) ?? <EmptyIndicator />
      }
      </PageBody>
    </VBox>
  )
}
