import React, { ReactElement } from "react"

export function ProgressBar(props: { rate: number, stable?: boolean }): ReactElement {
  const percent = Math.floor(props.rate * 100)
  const className = [
    "bg-warning",
    "progress-bar",
    "progress-bar-striped"
  ]
  if (!props.stable) {
    className.push("progress-bar-animated")
  }
  return (
    <div className="progress position-relative">
      <div className={className.join(" ")} style={{ width: percent + "%" }} />
      <strong className="position-absolute" style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        {percent}%
      </strong>
    </div>
  )
}
