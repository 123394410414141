import { ReactElement, ReactNode } from "react"
import { app } from "~context/core"
import { formatDecimal, formatPeriod } from "~util/format"
import { waitForList } from "~parts/spinner"
import { useLoader } from "~util/hook"
import { Link } from "react-router-dom"
import { modal } from "~context/modal"
import { PageBody, PageHeader } from "~parts/header"
import { planTitles } from "~core/castify/api/msg/plan"
import { ChargeFixDialog, PaymentUpdateDialog } from "./dialog"
import { ProjectLink } from "~parts/link"
import { Charge, ChargeStatus } from "~core/castify/api/msg/payment"
import { Empty } from "~parts/placeholder"
import { CastifyRoute } from "~core/castify-route"

type ChargeProps = {
  accountId: string
  src: Charge
  onChange?: () => void
}

function InvoiceListItem(props: ChargeProps): ReactElement {

  let status: ReactNode
  let action: ReactNode = <></>
  switch (props.src.status) {
    case ChargeStatus.INITIAL: {
      status = <span className="text-muted">金額の計算中…</span>
      break
    }
    case ChargeStatus.WIP: {
      status = <span className="text-muted">課金の処理中…</span>
      break
    }
    case ChargeStatus.CLOSED: {
      status = <span className="text-muted">処理済み</span>
      break
    }
    case ChargeStatus.WAIT_FOR_USER: {
      status = <span className="text-danger">課金エラー</span>
      action = <button className="btn btn-sm btn-danger py-0" onClick={modal.bind(<PaymentUpdateDialog {...props} />)}>
        お支払い
      </button>
      break
    }
    case ChargeStatus.WAIT_FOR_ADMIN: {
      status = <span className="text-danger">処理待ち</span>
      if (app.session.privileged) {
        action = <button className="btn btn-sm btn-dark py-0" onClick={modal.bind(<ChargeFixDialog {...props} />)}>
          ステータス変更
        </button>
      }
      break
    }
  }

  return (
    <tr>
      <td className="text-nowrap">
        <Link to={CastifyRoute.accounts.account.invoices.invoice.getPath(props.accountId, props.src.chargeId)}>
          <code className="mx-2">{props.src.chargeId.toString().padStart(4, "0")}</code>
        </Link>
      </td>
      <td>
        {planTitles[props.src.issuer.plan]}
      </td>
      <td className="cas-cell-shrink">
        <ProjectLink id={props.src.issuer.projectId} />
        <br />
        <span>({props.src.issuer.projectId})</span>
      </td>
      <td className="font-monospace">
        {formatPeriod(props.src.term)}
      </td>
      <td className="cas-numeric">
        {props.src.bill ? formatDecimal(props.src.bill.total) + " JPY" : "-"}
      </td>
      <td className="cas-cell-shrink px-3" align="right">
        {status}
      </td>
      <td className="cas-cell-shrink px-2">
        {action}
      </td>
    </tr>
  )
}

function InvoiceList(props: { accountId: string }): ReactElement {

  const [charges, reload] = useLoader(() => app.api.listCharges(props.accountId))

  return <>{
    waitForList(charges, src => {
      return (
        <table className="cas-table">
          <thead>
          <tr>
            <th colSpan={1}>
              ID
            </th>
            <th>契約プラン名</th>
            <th>対象</th>
            <th>期間</th>
            <th style={{ textAlign: "center" }}>
              請求金額
            </th>
            <th style={{ textAlign: "center" }}>
              ステータス
            </th>
            <th />
          </tr>
          </thead>
          <tbody>
          {src.map((e, i) => (
            <InvoiceListItem key={i} accountId={props.accountId} src={e} onChange={reload} />
          ))}
          </tbody>
        </table>
      )
    }) ?? <Empty />
  }</>
}

export function InvoiceListPage(props: { accountId: string }): ReactElement {

  return (
    <>
      <PageHeader title="請求履歴" />
      <PageBody>
        <InvoiceList accountId={props.accountId} />
      </PageBody>
    </>
  )
}
