import React, { ReactElement, useEffect, useState } from "react"
import { app } from "~context/core"
import { HttpLoadError, MediaDuration } from "~core/castify/api"
import { Spinner } from "~parts/spinner"
import {
  CancellationToken,
  Failure,
  sleep,
  Success,
  Try,
} from "~util/async"
import { toHoursString } from "~util/format"
import { useLoader } from "~util/hook"
import { letterBox } from "~util/style"

async function loadImage(broadcastId: string, token: CancellationToken): Promise<Blob> {
  let limit = 3
  for (;;) {
    if (token.cancelled) {
      throw new Error("cancelled")
    }
    try {
      return await app.api.getPreview(broadcastId)
    }
    catch (error) {
      const canRetry = --limit > 0 && error instanceof HttpLoadError && error.status == 503
      if (!canRetry) {
        throw error
      }
    }
    await sleep(500)
  }
}

function Image(props: { url: Try<string>, alt: string }): ReactElement {
  const { value, error } = props.url
  if (error !== undefined) {
    const noVisualContent = (error instanceof HttpLoadError && error.status == 410)
    if (noVisualContent) {
      return <i className="text-secondary fas fa-music" />
    }
    else {
      return <i className="text-secondary fas fa-exclamation-circle" />
    }
  }
  return (
    <img src={value} style={{ maxWidth: "100%", maxHeight: "100%" }} alt={props.alt} />
  )
}

export function BroadcastPreview(props: { id: string, duration: MediaDuration }): ReactElement {

  const [image] = useLoader(token => loadImage(props.id, token), [props.id])

  const [url, setUrl] = useState<Try<string>>()

  useEffect(() => {
    if (image) {
      const { value, error } = image
      if (value) {
        setUrl(new Success(URL.createObjectURL(image.value)))
      }
      else {
        setUrl(new Failure(error))
      }
    }
  }, [image])

  useEffect(() => {
    if (url?.value) {
      return () => URL.revokeObjectURL(url.value)
    }
  }, [url])

  return (
    <div className="position-relative w-100 h-100" style={{ backgroundColor: "#000" }} >
      <div className="position-absolute w-100 h-100" style={letterBox}>
        { url ? <Image url={url} alt={props.id + " のサムネイル"} /> : <Spinner /> }
      </div>
      <div className="position-absolute end-0 bottom-0 m-1 p-1 text-white" style={{ fontSize: "8pt", backgroundColor: "rgba(0, 0, 0, 0.33)" }}>
        {toHoursString(Math.ceil(props.duration.seconds))}
      </div>
    </div>
  )
}
