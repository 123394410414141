export enum MeterType {

  // "Usage" compatible meters
  CDNCacheFill    = 0,
  CDNCacheLoad    = 1,
  RecorderTraffic = 2,
  StreamerTraffic = 3,
  StorageUsage    = 4,

  // global object count limitations
  Broadcast = 0x01_00_00,
  Playback  = 0x01_00_01,
  Recorder  = 0x01_00_02,

  // per object limitations
  RecorderLengthSec = 0x02_00_00,
}

export type Meter = {
  type: MeterType,
  value: number
};

export enum QuotaHost {
  Project = 0,
  Quota   = 1
}

export type Quota = {
  type: MeterType,
  host: QuotaHost,
  limit: number,
};
