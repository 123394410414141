import React, { ReactElement } from "react"
import { app } from "~context/core"
import { modal } from "~context/modal"
import { Box, VBox } from "~parts/box"
import { Dropdown, DropdownItem } from "~parts/dropdown"
import { TabbedPageHeader } from "~parts/header"
import { waitFor } from "~parts/spinner"
import { TabMenuItem } from "~parts/tab"
import { useLoader } from "~util/hook"
import { WebhookInfo } from "./parts/info"
import { WebhookUpdateDialog } from "./parts/update"
import { CastifyRoute } from "~core/castify-route"
import { LogIndex } from "~parts/log"
import { WebhookDeleteDialog } from "~pages/webhook/parts/delete"

export function WebhookDetailView(props: { projectId: string }): ReactElement {

  const { projectId } = props
  const { webhookId } = CastifyRoute.projects.project.webhooks.webhook.usePath()

  const [item, reload] = useLoader(
    () => app.api.getWebhook(webhookId),
    [webhookId],
  )

  const content = waitFor(item, (src) => {
    const items = [
      new TabMenuItem(
        "情報",
        {
          route: CastifyRoute.projects.project.webhooks.webhook.overview.path,
          built: CastifyRoute.projects.project.webhooks.webhook.overview.getPath(projectId, webhookId),
        },
        <WebhookInfo src={src} />,
      ),
      new TabMenuItem(
        "ログ",
        {
          route: CastifyRoute.projects.project.webhooks.webhook.logs.path,
          built: CastifyRoute.projects.project.webhooks.webhook.logs.getPath(projectId, webhookId),
        },
        <LogIndex projectId={projectId} cols={[["args", "responseJson", "kind"]]} query={{ type: "webhook", id: src.id }} />,
      ),
    ]
    const title = (
      <VBox>
        <Box
          alignSelf="flex-start"
          marginBottom="0.33rem"
          padding="2px 5px"
          color="#777"
          fontSize="10pt"
          border="1px solid #777"
        >
          Webhook
        </Box>
        {src.id}
      </VBox>
    )
    return (
      <TabbedPageHeader title={title} src={items}>
        <Dropdown title="操作">
          <DropdownItem
            onClick={modal.bind(
              <WebhookUpdateDialog src={src} onEnd={reload} />,
            )}
          >
            Webhook の編集
          </DropdownItem>
          <DropdownItem
            onClick={modal.bind(
              <WebhookDeleteDialog id={src.id} />
            )}
            danger
          >
            Webhook の削除
          </DropdownItem>
        </Dropdown>
      </TabbedPageHeader>
    )
  })

  return <>{content}</>
}
