import React, { ReactElement } from "react"
import { app } from "~context/core"
import { modal } from "~context/modal"
import { Member } from "~core/castify/api/msg/project"
import { Box, VBox } from "~parts/box"
import { ActionDialog } from "~parts/dialog"
import { PageBody, PageHeader } from "~parts/header"
import { waitForList } from "~parts/spinner"
import { formatDateTime } from "~util/format"
import { useLoader } from "~util/hook"

function ListItem(props: { onDelete: undefined | (() => void); src: Member }): ReactElement {
  return (
    <tr key={props.src.id}>
      <td className="cas-cell-shrink">
        <Box paddingRight="0.33rem">
          <input type="checkbox" />
        </Box>
      </td>
      <td>{props.src.user.displayName}</td>
      <td>
        <a href={"mailto:" + props.src.user.email}>{props.src.user.email}</a>
      </td>
      <td>{props.src.role}</td>
      <td>{formatDateTime(props.src.createdAt)}</td>
      <td className="cas-cell-shrink">
        <button onClick={props.onDelete} className={"btn btn-sm" + (props.onDelete === undefined ? " d-none" : "")}>
          <i className="fas fa-trash text-secondary" />
        </button>
      </td>
    </tr>
  )
}

// noinspection JSUnusedLocalSymbols
export function MemberList(props: { projectId: string }): ReactElement {
  const [list, reloadList] = useLoader(() => app.api.listMembers())

  function deleteMember(list: Member[], src: Member) {
    if (list.length <= 1) {
      return;
    }
    modal.show(
      <ActionDialog
        action={() => app.api.deleteMember(src.id)}
        onEnd={reloadList}
      >
        &quot;{src.user.displayName}&quot; さんをプロジェクトから削除します。本当によろしいですか？
      </ActionDialog>,
    )
  }

  return (
    <VBox position="relative">
      <PageHeader title="メンバーの管理" />
      <PageBody>
      {
        waitForList(list, (src) => (
          <table className="cas-table">
            <thead>
            <tr>
              <th />
              <th>表示名</th>
              <th>メールアドレス</th>
              <th>ロール</th>
              <th>参加日時</th>
              <th />
            </tr>
            </thead>
            <tbody>
            {src.map((e) => (
              <ListItem key={e.id} src={e} onDelete={src.length <= 1 ? undefined : () => deleteMember(src, e)} />
            ))}
            </tbody>
          </table>
        ))
      }
      </PageBody>
    </VBox>
  )
}
