import React, { ChangeEvent, ReactElement, useRef } from "react"
import { app } from "~context/core"
import { upload } from "~context/upload"
import { DiskEntry } from "~core/castify/api/msg/disk"
import { ActionDialog, SenderDialog } from "~parts/dialog"
import { useSender } from "~util/hook"

export function DiskEntryDeleteDialog(props: { src: DiskEntry, onChange?: () => void }): ReactElement {
  return (
    <ActionDialog action={() => app.api.deleteDiskEntry(props.src.disk, props.src.path)}  onEnd={props.onChange}>
      このファイルを削除します。本当によろしいですか？
    </ActionDialog>
  )
}

export function DiskEntryCreateDialog(props: { disk: string, onFinish?(): void }): ReactElement {

  const [path, setPath] = React.useState<string>("")
  const file = useRef<HTMLInputElement>(null)

  const sender = useSender(async () => {
    const blob = file.current?.files?.item(0)
    if (!blob) {
      return
    }
    // TODO validation
    const value = path.trim()
    if (value === "") {
      throw new Error("名前を入力して下さい。")
    }
    const { url } = await app.api.createULLink(props.disk, value)
    return upload(value, url, blob).then(result => {
      if (result === false || result !== 200) {
        throw new Error("ファイルアップロードに失敗しました。")
      }
      if (props.onFinish) {
        props.onFinish()
      }
    })
  })

  const onChange = (value: ChangeEvent<HTMLInputElement>) => {
    if (value.target.files === null || value.target.files.length <= 0) {
      return;
    }
    const file = value.target.files.item(0);
    if (file === null) {
      return;
    }
    if (path === "") {
      setPath(file.name.trim())
    }
  }

  return (
    <SenderDialog title="アップロード" sender={sender}>
      <div className="mx-3 mt-3">
        <label className="form-label">ファイル</label>
        <input ref={file} type="file" accept="video/*,audio/*" className="form-control" onChange={onChange} />
      </div>

      <div className="mx-3 mt-3">
        <label className="form-label">ファイル名</label>
        <input
          value={path}
          onChange={e => setPath(e.target.value)}
          type="text"
          className="form-control"
          placeholder="ファイル名"
        />
      </div>
    </SenderDialog>
  )
}
