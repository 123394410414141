import React, { ReactElement } from "react"
import { app } from "~context/core"
import { modal } from "~context/modal"
import { VBox } from "~parts/box"
import { PageBody, PageHeader } from "~parts/header"
import { Panel } from "~parts/panel"
import { PropertyList, PropertyListItem } from "~parts/property-list"
import { SpinnerButton, waitFor } from "~parts/spinner"
import { formatDateTime, formatDecimal } from "~util/format"
import { useLoader2, useSender } from "~util/hook"
import { DiskEntryDeleteDialog } from "./dialog"
import { CastifyRoute } from "~core/castify-route"
import { Link, useParams } from "react-router-dom"

export function DiskEntryView(props: { projectId: string }): ReactElement {
  const { disk, file } = useParams()
  if (disk == undefined || file === undefined) {
    throw 0
  }
  const [entry] = useLoader2((...args) => app.api.getDiskEntry(...args), [disk, file])
  const dl = useSender(() => app.api.createDLLink(disk, file))

  const title = (
    <div className="d-flex flex-column align-items-start">
      <div className="mb-1 border text-muted p-1" style={{ fontSize: "10pt" }}>
        File
      </div>
      <div>{file}</div>
    </div>
  )

  return (
    <VBox position="relative">
      <PageHeader title={title} />
      <PageBody>
        {
          waitFor(entry, src => (<>
              <Panel title="プロパティ">
                <PropertyList>
                  <PropertyListItem name="サイズ">
                    {formatDecimal(src.size)} byte(s)
                  </PropertyListItem>
                  <PropertyListItem name="作成日時">
                    <span className="font-monospace">{formatDateTime(src.createdAt)}</span>
                  </PropertyListItem>
                  <PropertyListItem name="更新日時">
                    <span className="font-monospace">{formatDateTime(src.updatedAt)}</span>
                  </PropertyListItem>
                  <PropertyListItem name="ダウンロード URL">
                    {
                      dl.result?.value
                        ? <a href={dl.result.value.url} download={src.path}>ダウンロード</a>
                        : <SpinnerButton className="btn btn-sm btn-primary ms-1" onClick={dl.send} animated={!dl.loaded}>リンクの作成</SpinnerButton>
                    }
                  </PropertyListItem>
                </PropertyList>
              </Panel>

              {entry?.value && (
                <Panel title="操作">
                  <PropertyList>
                    <PropertyListItem name="このファイルから配信を作成">
                      <Link to={CastifyRoute.projects.project.newBroadcast.import.getPath(props.projectId, entry.value)}>
                        <button className="btn btn-primary btn-sm">フォームへ移動</button>
                      </Link>
                    </PropertyListItem>
                    <PropertyListItem name="このファイルを削除">
                      <button className="btn btn-danger btn-sm" onClick={modal.bind(<DiskEntryDeleteDialog src={entry.value} />)}>
                        削除
                      </button>
                    </PropertyListItem>
                  </PropertyList>
                </Panel>
              )}
            </>
          ))
        }
      </PageBody>
    </VBox>
  )
}
