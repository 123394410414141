import React, { ReactElement } from "react"
import { WebhookGetResult } from "~core/castify/api/msg/webhook"
import { Box, VBox } from "~parts/box"
import { Mask } from "~parts/mask"
import { Panel } from "~parts/panel"
import { PropertyList, PropertyListItem } from "~parts/property-list"
import { formatDateTime } from "~util/format"

export function WebhookInfo(props: { src: WebhookGetResult }): ReactElement {
  const events = props.src.kinds.map((e) => <li key={e}>{e}</li>)
  return (
    <VBox>
      <Panel title="Properties">
        <PropertyList>
          <PropertyListItem name="ID">{props.src.id}</PropertyListItem>

          <PropertyListItem name="名前">{props.src.name}</PropertyListItem>

          <PropertyListItem name="URL">{props.src.uri}</PropertyListItem>

          <PropertyListItem name="作成日時">
            {formatDateTime(props.src.createdAt)}
          </PropertyListItem>

          <PropertyListItem name="更新日時">
            {formatDateTime(props.src.updatedAt)}
          </PropertyListItem>

          <PropertyListItem name="署名キー">
            <Mask>
              <code>{props.src.token}</code>
            </Mask>
          </PropertyListItem>

          <PropertyListItem name="Event(s)">
            <Box fontFamily="monospace">
              <ul>{events}</ul>
            </Box>
          </PropertyListItem>
        </PropertyList>
      </Panel>
    </VBox>
  )
}
