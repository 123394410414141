import React, { useEffect, useState } from "react"
import { BehaviorSubject, Subscription } from "rxjs"

export interface Binder {
  readonly value: string

  onChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void
}

export function useBinder(init = ""): Binder {
  const [value, setValue] = useState(init)
  useEffect(() => { setValue(init) }, [init])
  return {
    value,
    onChange(e) { setValue(e.target.value) },
  }
}

export function useBoolean(init = false) {
  const [checked, setChecked] = useState(init)
  useEffect(() => { setChecked(init) }, [init])
  return {
    checked,
    onChange(e: React.ChangeEvent<HTMLInputElement>) {
       setChecked(e.target.checked)
    },
  }
}

interface CheckboxBinder {
  readonly checked: boolean

  onChange(event: React.ChangeEvent<HTMLInputElement>): void
}

interface Checkbox<K> {
  readonly value: Set<K>

  bind(key: K): CheckboxBinder
}

export function useCheckbox<K = string>(init: Set<K> = new Set()): Checkbox<K> {
  const [value, setValue] = useState(init)
  return {
    bind(key: K) {
      return {
        get checked(): boolean {
          return value.has(key)
        },
        onChange(event: React.ChangeEvent<HTMLInputElement>) {
          const checked = event.target.checked
          if (checked != value.has(key)) {
            const out = new Set(value)
            if (checked) {
              out.add(key)
            } else {
              out.delete(key)
            }
            setValue(out)
          }
        },
      }
    },
    value,
  }
}

export function useBehavior<T>(src: BehaviorSubject<T>): T {
  const [value, setValue] = useState(src.value)
  useEffect(() => toFunctor(src.subscribe(setValue)), [src])
  return value
}

function toFunctor(src: Subscription): () => void {
  return src.unsubscribe.bind(src)
}
