import { ReactElement } from "react"
import { app } from "~context/core"
import { modal } from "~context/modal"
import { BillItem, Charge, ChargeStatus } from "~core/castify/api/msg/payment"
import { planTitles } from "~core/castify/api/msg/plan"
import { Dropdown, DropdownItem } from "~parts/dropdown"
import { PageBody, PageHeader } from "~parts/header"
import { ProjectLink } from "~parts/link"
import { Panel } from "~parts/panel"
import { PropertyList, PropertyListItem } from "~parts/property-list"
import { waitFor } from "~parts/spinner"
import { formatDecimal, formatPeriod } from "~util/format"
import { useLoader2 } from "~util/hook"
import { ChargeFixDialog, PaymentUpdateDialog } from "./dialog"
import { CastifyRoute } from "~core/castify-route"

function InvoiceLineView(props: { src: BillItem }): ReactElement {

  return (
    <tr>
      <td>
        {props.src.product.description}
      </td>
      <td style={{ fontFamily: "monospace" }}>
        {formatPeriod(props.src.targetPeriod)}
      </td>
      <td className="cas-numeric">
        {formatDecimal(props.src.count)}
      </td>
      <td className="px-2">
        {props.src.product.unit ?? ""}
      </td>
      <td className="cas-numeric">
        {formatDecimal(props.src.price, 1)}
      </td>
    </tr>
  )
}

export function InvoiceDetailPage(props: { accountId: string }): ReactElement {

  const { accountId } = props
  const { chargeId } = CastifyRoute.accounts.account.invoices.invoice.usePath()
  const [charge, reload] = useLoader2(id => app.api.getCharge(accountId, id), [chargeId])

  // TODO
  function pay(src: Charge) {
    modal.show(<PaymentUpdateDialog accountId={accountId} src={src} onChange={reload} />)
  }

  const subtotal = charge?.value?.bill?.items.reduceRight((acc, e) => acc + e.price, 0)

  return <>{waitFor(charge, src => (
    <>
      <PageHeader title="明細">
        <Dropdown title="操作">
          {src.status == ChargeStatus.WAIT_FOR_USER && (
            <>
              <DropdownItem onClick={() => pay(src)}>
                お支払い
              </DropdownItem>
            </>
          )}
          {src.status != ChargeStatus.CLOSED && app.session.privileged && (
            <DropdownItem onClick={modal.bind(<ChargeFixDialog src={src} accountId={accountId} onChange={reload} />)}>
              ステータス変更
            </DropdownItem>
          )}
        </Dropdown>
      </PageHeader>
      <PageBody>
        <Panel>
          <PropertyList>
            <PropertyListItem name="ID">
              <code>{src.chargeId}</code>
            </PropertyListItem>
            <PropertyListItem name="契約プラン">
              {planTitles[src.issuer.plan]}
            </PropertyListItem>
            <PropertyListItem name="対象プロジェクト">
              <ProjectLink id={src.issuer.projectId} />
              &nbsp;
              <span>({src.issuer.projectId})</span>
            </PropertyListItem>
            <PropertyListItem name="対象期間">
              {formatPeriod(src.term)}
            </PropertyListItem>
          </PropertyList>
        </Panel>
        <Panel title="内訳" className="mt-3">
          <table className="table table-sm">
            <thead>
            <tr>
              <th>内容</th>
              <th>対象期間</th>
              <th style={{ textAlign: "right" }}>利用量</th>
              <th />
              <th style={{ textAlign: "right" }}>料金</th>
              <th />
            </tr>
            </thead>
            <tbody>
            {src.bill?.items.filter(e => e.price != 0).map((e, i) => (
              <InvoiceLineView key={i} src={e} />
            ))}
            </tbody>
          </table>
          <div className="p-3 row justify-content-end">
            <div className="col-auto">
              <table>
                <tbody>
                <tr>
                  <td>小計</td>
                  <td className="cas-numeric">
                    ¥{formatDecimal(subtotal ?? 0)}
                  </td>
                </tr>
                <tr>
                  <td>税額</td>
                  <td className="cas-numeric">
                    ¥{formatDecimal(src.bill?.tax ?? 0)}
                  </td>
                </tr>
                <tr>
                  <th>ご請求金額</th>
                  <td className="cas-numeric" style={{ minWidth: "8rem" }}>
                    ¥{formatDecimal(src.bill?.total ?? 0)}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Panel>
      </PageBody>
    </>
  ))}</>
}
