export type UsageSummary = {
  storage: number
  viewers: number
}

export type TimeUsageData = {
  x: number[]
  y: number[]
}

export type UsageMetric = {

  /**
   * 集計値の種類を特定する番号です。
   */
  type: UsageKind,

  /**
   * 集計値の単位です。
   */
  unit: string,

  /**
   * 集計値です。
   */
  value: number,
};

export enum UsageKind {

  STORAGE_USAGE = 0x05,

  // v1
  V1_RECORDER_TRAFFIC = 0x02,
  V1_STREAMER_TRAFFIC = 0x03,

  // v2
  V2_RECORDER_TRAFFIC = 0x0c,
  V2_STREAMER_TRAFFIC = 0x0d,

  // Transcoders v2
  TRANSCODE_H264_30F_P240  = 0x10_01_0a,
  TRANSCODE_H264_30F_P480  = 0x20_01_0a,
  TRANSCODE_H264_30F_P720  = 0x30_01_0a,
  TRANSCODE_H264_30F_P1080 = 0x40_01_0a, 
  TRANSCODE_H264_30F_P1440 = 0x50_01_0a,
  TRANSCODE_AUDIO          = 0x0b,
  
  // CDN
  CDN1_EGRESS    = 0x01_01_09,
  CDN1_CACHE_GET = 0x02_01_09,
  CDN1_CACHE_PUT = 0x03_01_09,

  CDN2_EGRESS    = 0x01_02_09,
  CDN2_CACHE_GET = 0x02_02_09,
  CDN2_CACHE_PUT = 0x03_02_09,

  CDN3_EGRESS    = 0x01_03_09,
  CDN3_CACHE_GET = 0x02_03_09,
  CDN3_CACHE_PUT = 0x03_03_09,
};

export type BroadcastUsageCreate = {
  kind: UsageKind
  value: number
}
