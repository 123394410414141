import React, { ReactElement } from "react"
import { app } from "~context/core"
import { TimeUsageData } from "~core/castify/api/msg/usage"
import { Box, VBox } from "~parts/box"
import { Coord, LineChart } from "~parts/chart"
import { Loader } from "~parts/loader"
import { letterBox } from "~util/style"

function Plotter(props: { src: TimeUsageData }): ReactElement {
  const out: Coord[] = []
  for (let i = 0; i < props.src.x.length; ++i) {
    out.push({
      x: props.src.x[i] as number,
      y: props.src.y[i] as number,
    })
  }
  if (out.length === 0) {
    return (
      <VBox {...letterBox} color="#999" padding="1rem">
        <h1>
          <i className="fas fa-minus-circle" />
        </h1>
        <Box className="h3" marginTop="0.5rem">
          No recent data.
        </Box>
      </VBox>
    )
  }
  return (
    <Box overflow="auto" display="flex" flexDirection="row-reverse">
      <Box>
        <LineChart data={[out]} w={700} h={170} xTicks={9} yTicks={5} />
      </Box>
    </Box>
  )
}

export function UsageChart(props: { type: number }): ReactElement {
  return (
    <Loader
      id
      of={() => app.api.getUsageData(props.type)}
      then={(e) => <Plotter src={e} />}
    />
  )
}
