import { ReactElement, ReactNode, useState } from "react"
import Chart from "react-apexcharts"
import { useLoader } from "~util/hook"
import { app } from "~context/core"
import { waitFor } from "~parts/spinner"
import { ApexOptions } from "apexcharts"
import { Box, HBox } from "~parts/box"

type DurationChoice = {
  label: string,
  duration: number | "live"
}

// noinspection PointlessArithmeticExpressionJS
const durationChoices: DurationChoice[] = [
  { label: "LIVE", duration: "live" },
  { label: "1 hour", duration: 1 * 60 * 60 },
  { label: "2 hours", duration: 2 * 60 * 60 },
  { label: "4 hours", duration: 4 * 60 * 60 },
  { label: "8 hours", duration: 8 * 60 * 60 },
]

export function PlaybackStatsGraph(props: {
  broadcastId: string,
  width?: string | number,
  height?: string | number
}): ReactElement {
  const [duration, setDuration] = useState<number | "live">("live")

  const [stats] = useLoader(
    () => app.api.getPlaybackStats(props.broadcastId, duration),
    [duration],
  )

  const chartOptions: ApexOptions = {
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
        datetimeFormatter: {
          year: "yyyy",
          month: "MM",
          day: "dd",
          hour: "HH",
          minute: "mm",
        },
      },
    },
    tooltip: {
      x: {
        show: false,
        format: "yyyy/MM/dd HH:mm",
      },
      y: {
        title: {
          formatter: () => "視聴者数",
        },
      },
    },
    chart: {
      animations: {
        enabled: false,
      },
      toolbar: {
        tools: {
          zoomin: false,
          zoomout: false,
          download: false,
        },
      },
    },
    stroke: {
      width: 1,
    },
  }

  const durationSelector = durationChoices.map((c) =>
    <LabelSelector
      key={c.duration}
      selected={duration == c.duration}
      onSelect={() => setDuration(c.duration)}
    >
      {c.label}
    </LabelSelector>,
  )

  const chart = waitFor(stats, s => {
    const series = s.entries.flatMap(e => [
      [e.startsAt, e.count],
      [e.endsAt, e.count],
    ])
    if (series[0] && series[0][0]) {
      series.unshift([series[0][0] - 1, 0])
      series.unshift([s.since, 0])
    }
    return <Chart type="line" width={props.width} height={props.height} options={chartOptions} series={[{ data: series }]} />
  })

  return <Box>
    <HBox>
      {durationSelector}
    </HBox>
    <Box height={props.height}>
      {chart}
    </Box>
    <Box height={15}></Box>
  </Box>
}

function LabelSelector(props: { children?: ReactNode, selected?: boolean, onSelect: () => void }) {
  return <div style={{
    borderRadius: "8px",
    margin: "0 5px",
    padding: "2px 5px",
    cursor: "pointer",
    fontWeight: "bold",
    backgroundColor: props.selected ? "white" : "",
  }} onClick={props.onSelect}>
    {props.children}
  </div>
}
