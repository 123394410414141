import { PlanType } from "./plan"

export type Sub = {
  subscriptionId: string
  currentTerm: TimeSpan
  createdAt: number
  cancelled: boolean
  plan: PlanType
  projectId?: string
}

export type SubCreate = {
  plan: PlanType
  projectId: string
  automated?: boolean
  startedAt?: number // ms
}

export type Charge = {
  chargeId: number
  term: TimeSpan
  bill?: Bill,
  issuer: Sub
  status: ChargeStatus
}

export enum ChargeStatus {
  INITIAL        = 0,
  WIP            = 1,
  CLOSED         = 2,
  WAIT_FOR_ADMIN = 3,
  WAIT_FOR_USER  = 4,
}

export type Bill = {
  total: number
  tax: number
  items: BillItem[]
}

export type BillItem = {
  product: Product
  count: number
  price: number
  targetPeriod: TimeSpan
}

export type TimeSpan = {
  since: number
  until: number
}

export type Product = {
  description: string
  unit?: string
  value: string
}
