import React, { ReactElement } from "react"
import { app } from "~context/core"
import { modal } from "~context/modal"
import { AccountMember } from "~core/castify/api/msg/account"
import { Box, VBox } from "~parts/box"
import { ActionDialog, SenderDialog } from "~parts/dialog"
import { PageBody, PageHeader } from "~parts/header"
import { waitForList } from "~parts/spinner"
import { useBinder } from "~util/form"
import { formatDateTime } from "~util/format"
import { useLoader, useSender } from "~util/hook"

type AccountMemberListItemProps = {
  src: AccountMember
  onChange?: () => void
}

function AccountMemberListItem(props: AccountMemberListItemProps): ReactElement {

  return (
    <tr>
      <td className="cas-cell-shrink">
        <Box paddingRight="0.33rem">
          <input type="checkbox" />
        </Box>
      </td>
      <td>{props.src.user.displayName}</td>
      <td>
        <a href={"mailto:" + props.src.user.email}>{props.src.user.email}</a>
      </td>
      <td>{formatDateTime(props.src.createdAt * 1000)}</td>
      <td className="cas-cell-shrink">
        <button onClick={modal.bind(<AccountMemberDeleteDialog {...props} />)} className="btn btn-sm">
          <i className="fas fa-trash text-secondary" />
        </button>
      </td>
    </tr>
  )
}

function AccountMemberCreateDialog(props: { accountId: string, onChange?(): void }) {

  const mail = useBinder()

  const sender = useSender(async () => {
    await app.api.createAccountMember(props.accountId, mail.value)
    props.onChange?.()
  })

  return (
    <SenderDialog title="管理メンバーの追加" sender={sender} onEnd={props.onChange}>
      <VBox minWidth="24rem">
        <VBox margin="1rem 1.5rem 0 1.5rem">
          <Box color="#666">
            メールアドレス
          </Box>
          <Box marginTop="0.66rem">
            <input
              {...mail}
              type="text"
              className="form-control"
              placeholder="xxx@example.com"
            />
          </Box>
        </VBox>
      </VBox>
    </SenderDialog>
  )
}

function AccountMemberDeleteDialog(props: AccountMemberListItemProps) {
  return (
    <ActionDialog
      action={() => app.api.deleteAccountMember(props.src.accountMemberId)}
    >
      ユーザー &apos;{props.src.user.displayName}&apos; を請求アカウントの管理メンバーから削除します。本当によろしいですか？
    </ActionDialog>
  )
}

export function AccountMemberList(props: { accountId: string }): ReactElement {
  const { accountId } = props
  const [members, reloadMembers] = useLoader(() => app.api.listAccountMembers(accountId))

  return (
    <VBox position="relative">
      <PageHeader title="管理メンバー">
        <button
          onClick={modal.bind(<AccountMemberCreateDialog accountId={accountId} onChange={reloadMembers} />)}
          type="button"
          className="btn btn-primary btn-sm"
        >
          <i className="fas fa-plus" />
        </button>
      </PageHeader>
      <PageBody>{
        waitForList(members, (src) => (
          <table className="cas-table">
            <thead>
            <tr>
              <th />
              <th>表示名</th>
              <th>メールアドレス</th>
              <th>参加日時</th>
              <th />
            </tr>
            </thead>
            <tbody>
            {src.map((e, i) => (
              <AccountMemberListItem key={i} src={e} onChange={reloadMembers} />
            ))}
            </tbody>
          </table>
        ))
      }</PageBody>
    </VBox>
  )
}
