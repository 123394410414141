import { app } from "~context/core"
import { Sub } from "~core/castify/api/msg/payment"
import { ActionDialog, SenderDialog } from "~parts/dialog"
import { useLoader, useSender } from "~util/hook"
import { useBinder } from "~util/form"
import { waitFor } from "~parts/spinner"


type SubscriptionProps = {
  accountId: string
  src: Sub
  onChange?(): void
}

export function SubscriptionCancelDialog(props: SubscriptionProps) {
  return (
    <ActionDialog
      action={() => app.api.cancelSub(props.accountId, props.src.subscriptionId)}
      onEnd={props.onChange}
    >
      指定したプランを解約します。本当によろしいですか？
      <div className="mx-3 mt-3 alert alert-warning small">
        <ul className="mx-2 my-0">
          <li>
            解約後にプロジェクトは Trial モードに戻ります。
          </li>
          <li>
            無料枠を超える利用は停止され、超過分の配信は削除されます。
            <ul>
              <li>必要に応じて予め<a href="#">エクスポート</a>で保存を行ってください。</li>
            </ul>
          </li>
        </ul>
      </div>
    </ActionDialog>
  )
}

export function SubscriptionTransferDialog(props: SubscriptionProps) {

  const [accounts] = useLoader(() => app.api.listAccounts())

  const account = useBinder()

  const sender = useSender(async () => {
    const accountId = account.value
    if (!accountId) {
      throw new Error("請求先アカウントが未選択です。")
    }
    await app.api.transferSub(
      props.accountId,
      props.src.subscriptionId,
      accountId
    )
    props.onChange?.()
  })

  return (
    <SenderDialog title="請求先アカウントの変更" sender={sender}>
      <div style={{ minWidth: "300px" }}>
        <div className="mt-4 mx-4">
          <select {...account} className="mt-3 form-control form-select">
            <option value="" disabled hidden>
              引継ぎ先のアカウントを選択
            </option>
            { waitFor(accounts, src =>
              src
                .filter(e => e.accountId !== props.accountId)
                .map(e =>
                  <option key={e.accountId} value={e.accountId}>
                    {e.name}
                  </option>
                )
            )}
          </select>
        </div>
        <div className="mx-4 mt-3 alert alert-warning small">
          この操作により次回以降の請求は引継ぎ先のアカウント側に送付されます。
        </div>
      </div>
    </SenderDialog>
  )
}
