import { Size } from "./dimension"
import { Preview } from "./preview"
import { RtcOffer } from "./rtc"

export type BroadcastRecorderInfo = {
  /**
   * 配信のアップロード先の URL です。
   */
  uploadUrl: string

  /**
   * 受信中のメディアのビットレートです。未算出の場合は -1 になります。
   */
  bitrate: number

  /**
   * RTMP による配信のアップロード先を示すオブジェクトです。
   */
  rtmp: RTMPEndpoint

  /**
   * RTMPS による配信のアップロード先を示すオブジェクトです。
   */
  rtmps?: RTMPEndpoint
}

export type RTMPEndpoint = {
  /**
   * 配信先 URL のうち、サーバ部分を示す値です。
   * 例えば OBS においては、設定ダイアログ内、配信メニューの "サーバー" 欄にこの値を入れます。
   */
  url: string

  /**
   * 配信先 URL のうち、ストリームキー部分を示す値です。
   * 例えば OBS においては、設定ダイアログ内、配信メニューの "ストリームキー" 欄にこの値を入れます。
   */
  streamKey: string
}

export enum AudioTranscoderPreset {
  None = "None",
  PassThrough = "PassThrough",
  HLSCompat = "HLSCompat",
  AAC_64K = "AAC_64K",
  AAC_96K = "AAC_96K",
  AAC_128K = "AAC_128K",
  AAC_192K = "AAC_192K",
  AAC_384K = "AAC_384K",
}

export type H264VideoOutput = {
  /**
   * @constant "h264"
   */
  type: "h264"

  /**
   * 出力ストリームのビットレートを設定します。
   */
  bps: number

  /**
   * 出力映像のフレームレートを設定します。
   *
   * @default 30
   */
  fps?: number

  /**
   * 出力映像の解像度です。
   */
  bounds: Size
}

export enum VideoTranscoderPreset {
  None = "None",
  PassThrough = "PassThrough",
  HLSCompat = "HLSCompat",
  H264_128K = "H264_128K",
  H264_256K = "H264_256K",
  H264_512K = "H264_512K",
  H264_1M = "H264_1M",
  H264_3M = "H264_3M",
}

export type AACAudioOutput = {
  /**
   * @constant "aac"
   */
  type: "aac"

  /**
   * 出力ストリームのビットレートを設定します。
   */
  bps: number
}

export type AudioOutput = PresetAudioOutput | AACAudioOutput

export type PresetAudioOutput = {
  type: "preset"
  preset: AudioTranscoderPreset
}

export type VideoOutput = PresetVideoOutput | H264VideoOutput

export type PresetVideoOutput = {
  type: "preset"
  preset: VideoTranscoderPreset
}

export type HLSOptions = {
  /**
   * HLS プレイリストに列挙されるメディアセグメントの長さを秒数で指定します。
   *
   * @annotations { "kt": ["field:javax.validation.constraints.Size(min = 1, max = 3)"] }
   */
  segmentSeconds?: number

  /**
   * HLS プレイリストに列挙されるメディアセグメントの数です。
   *
   * @annotations { "kt": ["field:javax.validation.constraints.Size(min = 3, max = 10)"] }
   */
  segmentsInList?: number
}

export type AVBroadcastVariant = {
  /**
   * この配信バリエーションの識別名です。
   *
   * @annotations { "kt": ["field:Pattern(regexp = \"^\\\\w{1,32}$\")"] }
   */
  name: string

  /**
   * 音声の出力設定です。
   *
   * @annotations { "kt": ["field:Valid"] }
   */
  audio: AudioOutput

  /**
   * 映像の出力設定です。
   *
   * @annotations { "kt": ["field:Valid"] }
   */
  video: VideoOutput

  /**
   * この配信バリエーションにより配信されるメディアのビットレートの目安を設定します。
   */
  bandwidth?: number

  /**
   * この配信バリエーションに対応した HTTP Live-Streaming 固有のオプションです。
   */
  hlsOptions?: HLSOptions
}

// export type AVBroadcastInputConstraint = 0

export type BroadcastOptions = {
  /**
   * 作成する Broadcast のタイプです。
   */
  type: "av"

  /**
   * 配信されるメディアストリームの配信バリエーションを定義します。
   *
   * @annotations { "kt": ["field:javax.validation.constraints.Size(min = 0, max = 10)"] }
   * @annotations { "kt": ["field:Valid"] }
   */
  variants?: AVBroadcastVariant[]

  /**
   * プレビュー画像(サムネイル)の設定です。
   *
   * @annotations { "kt": ["field:javax.validation.constraints.Size(min = 0, max = 10)"] }
   * @annotations { "kt": ["field:Valid"] }
   */
  previews?: Preview[]

  live?: boolean
}

// noinspection JSUnusedGlobalSymbols
export type BroadcastCreate = {
  /**
   * Broadcast に紐づけて保存される任意の文字列です。
   *
   * @annotations { "kt": ["field:javax.validation.constraints.Size(min = 0, max = 1024)"] }
   */
  customData?: string

  /**
   * この Broadcast に対する最後のメディアストリームのアップロードの停止後、この値に指定した秒数を超えても新たメディアストリームがアップロードされない場合、この Broadcast は自動で停止します。
   */
  inactiveTTL?: number

  /**
   * ここに指定した文字列はこの Broadcast に紐づく形でコンソールに表示されます。
   *
   * @annotations { "kt": ["field:javax.validation.constraints.Size(min = 0, max = 255)"] }
   */
  name?: string

  /**
   * ここに指定した URL はこの Broadcast に紐づく形でコンソールからリンクされます。
   *
   * @annotations { "kt": ["field:javax.validation.constraints.Size(min = 0, max = 255)"] }
   */
  link?: string

  /**
   * メディアストリームに関するオプションです。
   *
   * @annotations { "kt": ["field:Valid"] }
   */
  options?: BroadcastOptions

  /**
   *
   */
  deferred?: boolean
}

export type BroadcastCreateResult = {
  /**
   * 生成された Broadcast の ID です。
   */
  broadcastId: string

  /**
   * この配信へメディアストリームをアップロードするための情報です。
   */
  recorder: BroadcastRecorderInfo

  /**
   * webhook:broadcastCreate が設定されている場合、そのレスポンス内容が入ります。
   */
  webhookReply?: string

  /**
   * この Broadcaster のオーナーであることを証明するトークンです。
   */
  token: string
}

export type BroadcastGetResult = {
  /**
   * この Broadcast の ID です。
   */
  broadcastId: string

  /**
   * 作成時にクライアントにより設定されたカスタムデータです。
   */
  customData?: string

  /**
   * 作成時に設定された、この Broadcast の名前です。
   */
  name?: string

  /**
   * 作成時に設定された、この Broadcast の URL です。
   */
  link?: string

  /**
   * 作成時に設定された、この Broadcast のメディアストリームに関するオプションです。
   */
  options?: BroadcastOptions

  /**
   * この Broadcast が受信するメディアストリームに関する情報を格納します。
   */
  recorder: BroadcastRecorderInfo

  /**
   * この Broadcaster のオーナーであることを証明するトークンです。
   */
  token: string

  /**
   * この Broadcast が開始された時刻の UNIX タイムスタンプ(ms)です。
   */
  startedAt: number

  /**
   * この Broadcast が停止されている場合、停止された時刻の UNIX タイムスタンプ(ms)が入ります。
   */
  stoppedAt?: number
}

export type TrackExtentList = {
  /**
   * このトラックを識別する番号です。
   */
  track: number

  /**
   * このトラックに属する Extent のリストです。
   */
  extents: TrackExtentListItem[]
}

export type TrackExtentListItem = {
  /**
   * この Extent の ID です。
   */
  extentId: number

  /**
   * この Extent に対応した録画が開始された時刻の UNIX TIME がミリ秒で入ります。
   */
  startedAt: number

  /**
   * この Extent に対応した録画が停止された時刻の UNIX TIME がミリ秒で入ります。
   */
  stoppedAt: number | undefined

  /**
   * この Extent に記録されたメディアに関する情報です。
   */
  info?: any
}

export type MediaQueueStatus = {
  track: number
  bitrate: number
  packets: number
  delay_ms: number
}

export type PlaybackCount = {
  currentPlaybackCount: number
  overallPlaybackCount: number
}

export type PlayableRangesListResult = {
  /**
   * 再生可能なメディアを含む時間の範囲のリストです。
   */
  ranges: PlayableRange[]
}

export type PlayableRange = {
  /**
   * 再生可能なメディアを含む範囲の開始時間がミリ秒で入ります。
   */
  start: number

  /**
   * 再生可能なメディアを含む範囲の終了時間がミリ秒で入ります。
   */
  endExclusive?: number
}

export type MediaDuration = {
  seconds: number
  fixed: boolean
}

export type BroadcastListRow = {
  broadcastId: string
  name?: string
  link?: string
  startedAt: number
  stoppedAt?: number | null
  currentViewCount: number
  overallViewCount: number
  duration: MediaDuration
}

export type OTT = {
  sig: string
  ttl: string
}

export type BroadcastRTCOfferCreate = {
  /**
   * 配信先の Broadcast の ID です。
   *
   */
  broadcastId: string

  /**
   * 配信先の Broadcast の認証トークンです。
   */
  token: string

  /**
   * WebRTC Agent が生成した offer 情報です。
   */
  offer: RtcOffer
}

export type MediaImportRequest = {
  disk: string
  path: string
  name?: string
  link?: string
  options: BroadcastOptions
}

export type BroadcastOptionsForm = {

  options: BroadcastOptions,

}

type Transcode = {
  transcodeId: string
  track: number
  stream: number
  time_us: number
  worker?: string
}

export type TranscodeStatus = {
  stoppedAt?: number
  transcodes: Transcode[]
}
