import React, { ReactElement, ReactNode } from "react"
import { Link } from "react-router-dom"
import { Box } from "~parts/box"

const pagesAround = 2

export type PaginatorProps = {
  base: string
  index: number
  total: number
}

function PageLink(
  props: PaginatorProps & { to: number; children?: ReactNode }
): ReactElement {
  const valid = 0 <= props.to && props.to < props.total
  if (valid && props.index !== props.to) {
    return (
      <li className="page-item">
        <Link to={props.base + `?page=${props.to}`} className="page-link">
          {props.children}
        </Link>
      </li>
    )
  } else {
    return (
      <li className="page-item disabled">
        <a className="page-link" href="#">
          {props.children}
        </a>
      </li>
    )
  }
}

export function Paginator(props: PaginatorProps): ReactElement {
  const items: ReactNode[] = []
  let rhs = props.index + pagesAround + 1
  let lhs = props.index - pagesAround
  if (lhs < 0) {
    rhs -= lhs
    lhs = 0
  }
  if (rhs > props.total) {
    lhs -= rhs - props.total
    rhs = props.total
  }
  if (lhs < 0) {
    lhs = 0
  }
  for (let i = lhs; i < rhs; ++i) {
    items.push(
      <PageLink key={i} {...props} to={i}>
        <Box fontFamily="monospace">{i + 1}</Box>
      </PageLink>
    )
  }
  return (
    <nav>
      <ul className="pagination">
        <PageLink {...props} to={0}>
          <Box fontFamily="monospace">&laquo;</Box>
        </PageLink>
        {items}
        <PageLink {...props} to={props.total - 1}>
          <Box fontFamily="monospace">&raquo;</Box>
        </PageLink>
      </ul>
    </nav>
  )
}
