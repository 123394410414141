import React, { Fragment, ReactElement, ReactNode } from "react"
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom"
import { app } from "~context/core"
import { navigation } from "~context/nav"
import { Account } from "~core/castify/api/msg/account"
import { Box, HBox, VBox } from "~parts/box"
import { Spinner } from "~parts/spinner"
import { useBehavior } from "~util/form"
import { AccountMemberList } from "./pages/member"
import { PaymentMethodPage } from "./pages/payment_method"
import { CastifyRoute } from "~core/castify-route"
import { InvoiceListPage } from "~pages/account/pages/invoice/list"
import { SubscriptionListPage } from "~pages/account/pages/subscription/list"
import { InvoiceDetailPage } from "~pages/account/pages/invoice/main"
import { SubscriptionInfoPage } from "~pages/account/pages/subscription/main"
import { Navigate, Route, Routes } from "react-router"

type MenuItemProps = {
  accountId: string
  name: string
  path: string
  getPath: (accountId: string) => string
  icon: string
  badge?: number
}

const pages = {
  invoices: {
    name: "請求書",
    path: CastifyRoute.accounts.account.invoices.path,
    getPath: CastifyRoute.accounts.account.invoices.getPath,
    icon: "fas fa-file-invoice",
    node: function x(accountId: string) {
      return <InvoiceListPage accountId={accountId} />
    },
  },
  paymentMethods: {
    name: "お支払い方法",
    path: CastifyRoute.accounts.account.paymentMethods.path,
    getPath: CastifyRoute.accounts.account.paymentMethods.getPath,
    icon: "far fa-credit-card",
    node: function x(accountId: string) {
      return <PaymentMethodPage accountId={accountId} />
    },
  },
  subscriptions: {
    name: "課金ステータス",
    path: CastifyRoute.accounts.account.subscriptions.path,
    getPath: CastifyRoute.accounts.account.subscriptions.getPath,
    icon: "far fa-calendar-alt",
    node: function x(accountId: string) {
      return <SubscriptionListPage accountId={accountId} />
    },
  },
  members: {
    name: "管理メンバー",
    path: CastifyRoute.accounts.account.members.path,
    getPath: CastifyRoute.accounts.account.members.getPath,
    icon: "fas fa-users",
    node: function x(accountId: string) {
      return <AccountMemberList accountId={accountId} />
    },
  },
}

function Badge(props: { children: ReactNode }) {
  return (
    <span className="position-absolute top-0 my-3 mx-3 translate-middle badge badge-small rounded-pill bg-danger">
      {props.children}
    </span>
  )
}

function MenuItem(props: MenuItemProps): ReactElement | null {
  return (
    <NavLink
      className={({ isActive }) => "cas-sidebar-item position-relative" + (isActive ? " cas-sidebar-item-active" : "")}
      to={props.getPath(props.accountId)}
    >
      <VBox color="#aaa" width="3rem" alignItems="center">
        <i className={props.icon} />
      </VBox>
      {props.name}
      {props.badge && (
        <Badge>{props.badge}</Badge>
      )}
    </NavLink>
  )
}

function MenuList(props: { src: Account }): ReactElement {
  return (
    <VBox position="fixed" width="240px" overflow="auto" height="calc(100% - 60px)">
      <Box color="white" margin="1.5rem">
        <Box>
          <HBox alignItems="center">
            {props.src.name}
            <Link to={CastifyRoute.accounts.path} className="btn btn-sm text-light">
              <i className="fas fa-sign-out-alt" />
            </Link>
          </HBox>
        </Box>
      </Box>
      <Fragment>
        <Box color="#bbb" padding="0.5rem 1rem">
          Billing Account
        </Box>
        <MenuItem accountId={props.src.accountId} {...pages.subscriptions} />
        <MenuItem accountId={props.src.accountId} {...pages.invoices} />
      </Fragment>
      <Fragment>
        <Box color="#bbb" padding="0.5rem 1rem" marginTop="1rem">
          Settings
        </Box>
        <MenuItem accountId={props.src.accountId} {...pages.paymentMethods} />
        <MenuItem accountId={props.src.accountId} {...pages.members} />
      </Fragment>
    </VBox>
  )
}

export function AccountDashboard(): ReactElement {

  const { accountId } = CastifyRoute.accounts.account.usePath()
  const hidden = !useBehavior(navigation.$showsSideMenu)
  const navigate = useNavigate()

  const account = app.useAccount(accountId)
  if (account === null) {
    navigate(CastifyRoute.accounts.path)
    return <></>
  }

  return (
    <HBox className="cas-sidebar" minHeight="inherit" position="relative">
      <div className="menu" hidden={hidden}>
        {account === "loading" ? <Spinner /> : <MenuList src={account} />}
      </div>
      <Box
        className="main"
        flexGrow={1}
        position="relative"
        overflow="auto"
      >
        <Routes>
          <Route path="*" element={<Navigate to={CastifyRoute.accounts.account.subscriptions.getPath(accountId)} replace={true} />} />
          <Route path={CastifyRoute.accounts.account.invoices.invoice.path} element={<InvoiceDetailPage accountId={accountId} />} />
          <Route path={CastifyRoute.accounts.account.subscriptions.subscription.path} element={<SubscriptionInfoPage accountId={accountId} />} />
          {Object.values(pages).map((e) => (
            <Route key={e.name} path={e.path} element={e.node(accountId)} />
          ))}
        </Routes>

        <Outlet />
      </Box>
      <div
        onMouseDown={navigation.toggleSideMenu}
        className="backdrop"
        hidden={hidden}
      />
    </HBox>
  )
}
