import React from "react"
import { Operation } from "~core/castify/api/msg/project"
import { AuditLogsScreen } from "~pages/audit_log/list"
import { OverviewScreen } from "~pages/overview"
import { UsageScreen } from "../usage/list"
import { RecorderScreen } from "../recorder"
import { CatalogPage } from "../catalog"
import { DebugPage } from "../debug"
import { JobListScreen } from "../job"
import { BroadcastList } from "~pages/broadcast/list"
import { WebhookList } from "~pages/webhook/list"
import { CastifyRoute } from "~core/castify-route"
import { APITokenList } from "~pages/token/list"
import { MemberList } from "~pages/member/list"
import { InvitationList } from "~pages/invitation/list"
import { InquiryView } from "~pages/supports/inquiry"
import { BroadcastNewView } from "~pages/broadcast/new"
import { DiskList } from "~pages/disk/list"

export class MenuItem {
  constructor(
    public readonly name: string,
    public readonly path: {
      path: string,
      getPath: (projectId: string) => string,
    },
    public readonly icon: string,
    public readonly component: (projectId: string) => React.ReactElement,
    public readonly requiredScopes: Operation[] = [],
  ) {
  }
}

export const items = {
  overview: new MenuItem(
    "プロジェクト",
    CastifyRoute.projects.project.overview,
    "fa-home",
    (projectId: string) => <OverviewScreen projectId={projectId} />,
  ),
  broadcasts: new MenuItem(
    "配信リスト",
    CastifyRoute.projects.project.broadcasts,
    "fa-broadcast-tower",
    (projectId: string) => <BroadcastList projectId={projectId} />,
    [Operation.BROADCAST_GET_LESS],
  ),
  broadcastNew: new MenuItem(
    "配信を作成",
    CastifyRoute.projects.project.newBroadcast,
    "fa-plus",
    (projectId: string) => <BroadcastNewView projectId={projectId} />,
    [Operation.BROADCAST_UPDATE],
  ),
  recorder: new MenuItem(
    "配信ツール",
    CastifyRoute.projects.project.recorder,
    "fa-video",
    (projectId: string) => <RecorderScreen projectId={projectId} />,
    [Operation.BROADCAST_GET],
  ),
  webhooks: new MenuItem(
    "Webhook",
    CastifyRoute.projects.project.webhooks,
    "fa-plug",
    (projectId: string) => <WebhookList projectId={projectId} />,
    [Operation.WEBHOOK_LIST],
  ),
  tokens: new MenuItem(
    "API トークン",
    CastifyRoute.projects.project.tokens,
    "fa-key",
    (projectId: string) => <APITokenList projectId={projectId} />,
    [Operation.API_TOKEN_LIST],
  ),
  jobs: new MenuItem(
    "ジョブ",
    CastifyRoute.projects.project.jobs,
    "fa-hourglass",
    (projectId: string) => <JobListScreen projectId={projectId} />,
  ),
  auditLog: new MenuItem(
    "監査ログ",
    CastifyRoute.projects.project.auditLogs,
    "fa-stethoscope",
    (projectId: string) => <AuditLogsScreen projectId={projectId} />,
  ),
  members: new MenuItem(
    "メンバーの管理",
    CastifyRoute.projects.project.members,
    "fa-users",
    (projectId: string) => <MemberList projectId={projectId} />,
  ),
  invitations: new MenuItem(
    "メンバーの招待",
    CastifyRoute.projects.project.invitations,
    "fa-user-plus",
    (projectId: string) => <InvitationList projectId={projectId} />,
    [Operation.MEMBER_ADD],
  ),
  usage: new MenuItem(
    "利用状況",
    CastifyRoute.projects.project.usage,
    "fa-tachometer-alt",
    (projectId: string) => <UsageScreen projectId={projectId} />,
    [Operation.PROJECT_UPDATE],
  ),
  catalog: new MenuItem(
    "カタログ",
    CastifyRoute.projects.project.catalog,
    "fa-yen-sign",
    (projectId: string) => <CatalogPage projectId={projectId} />,
    [Operation.PROJECT_UPDATE],
  ),
  disks: new MenuItem(
    "ストレージ",
    CastifyRoute.projects.project.disk,
    "fa-database",
    (_projectId: string) => <DiskList />,
  ),
  inquiry: new MenuItem(
    "お問い合わせ",
    CastifyRoute.projects.project.supports.inquiry,
    "fa-headset",
    (projectId: string) => <InquiryView projectId={projectId} />,
  ),
  debug: new MenuItem(
    "デバッグ",
    CastifyRoute.projects.project.debug,
    "fa-bug",
    (projectId: string) => <DebugPage projectId={projectId} />,
    [Operation.PROJECT_UPDATE],
  ),
}
