import firebase from "firebase/compat/app"
import React, { useEffect, useRef } from "react"
import { devDependencies } from "../../../package.json"

/**
 * firebase-uiをCDNで読み込むとグローバルに定義される値。
 */
declare const firebaseui: {
  auth: {
    AuthUI: {
      getInstance(appId?: string): firebaseui.auth.AuthUI | null
      new (auth: firebase.auth.Auth, appId?: string): firebaseui.auth.AuthUI
    }
  }
}

export const FirebaseAuth = React.lazy(async () => {
  // ローカライズ版のfirebase-uiはnpmにはなく（有志のサードパーティならあるが）、CDNのみの提供。
  // しかしCDN版のfirebase-uiを使うにはグローバルにfirebase変数が生えている必要がある。
  // （つまりFirebase SDKを全部CDN経由で読み込んでいる前提になっている）
  // SDKを全部CDN経由にするのはTypeScriptの型補完的に面倒なので、UI SDKだけ騙して使う。
  // @ts-expect-error
  globalThis.firebase = firebase

  const version = devDependencies["firebaseui"]

  await Promise.all([
    new Promise<unknown>((resolve, reject) => {
      const link = document.createElement("link")
      link.rel = "stylesheet"
      link.href = `https://www.gstatic.com/firebasejs/ui/${version}/firebase-ui-auth.css`
      link.onload = resolve
      link.onerror = reject

      document.head.appendChild(link)
    }),

    new Promise<unknown>((resolve, reject) => {
      const script = document.createElement("script")
      script.src = `https://www.gstatic.com/firebasejs/ui/${version}/firebase-ui-auth__ja.js`
      script.onload = resolve
      script.onerror = reject

      document.head.appendChild(script)
    }),
  ])

  if (typeof firebaseui?.auth?.AuthUI?.getInstance !== "function") {
    throw new Error(
      "FirebaseUI SDK が正常に読み込まれなかった可能性があります。"
    )
  }

  return { default: _FirebaseAuth }
})

/**
 * @example
 * <FirebaseAuth uiConfig={firebaseUIConfig} firebaseAuth={firebase.auth()} />
 */
function _FirebaseAuth({
  uiConfig,
  firebaseAuth,
}: {
  uiConfig: firebaseui.auth.Config
  firebaseAuth: firebase.auth.Auth
}) {
  const ui$ = useRef(
    firebaseui.auth.AuthUI.getInstance(firebaseAuth.app.name) ??
      new firebaseui.auth.AuthUI(firebaseAuth)
  )

  const div$ = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!div$.current) return

    const ui = ui$.current
    ui.start(div$.current, uiConfig)

    return () => {
      ui.reset()
    }
  }, [uiConfig])

  return <div ref={div$} />
}
