import React, { ReactElement } from "react"
import { app } from "~context/core"
import { modal } from "~context/modal"
import { player } from "~context/player"
import { PlanType } from "~core/castify/api/msg/plan"
import { Box, VBox } from "~parts/box"
import { ActionDialog, SenderDialog } from "~parts/dialog"
import { Dropdown, DropdownItem } from "~parts/dropdown"
import { TabbedPageHeader } from "~parts/header"
import { waitFor } from "~parts/spinner"
import { TabMenuItem } from "~parts/tab"
import { useBehavior } from "~util/form"
import { useLoader, useSender } from "~util/hook"
import { BroadcastDebugView, BroadcastUsageCreateDialog } from "./parts/debug"
import { ExportDialog } from "./parts/export"
import { BroadcastMetadataView } from "./parts/info"
import { CastifyRoute } from "~core/castify-route"
import { LogIndex } from "~parts/log"
import { useNavigate } from "react-router-dom"

export function BroadcastDetailView(props: { projectId: string }): ReactElement {

  const { projectId } = props
  const { broadcastId } = CastifyRoute.projects.project.broadcasts.broadcast.usePath()
  const navigate = useNavigate()

  const [item, reloadItem] = useLoader(() => app.api.getBroadcast(broadcastId))
  const session = useBehavior(app.$session)
  const [bind] = useLoader(() => app.api.getPlanBind(), [])

  const deletion = useSender(async () => {
    await app.api.deleteBroadcast(broadcastId)
  })

  if (deletion?.result?.failed === false) {
    navigate(CastifyRoute.projects.project.broadcasts.getPath(app.project.id))
    return <></>
  }

  function deleteBroadcast() {
    modal.show(
      <SenderDialog sender={deletion}>
        <div className="px-4 pt-4">
          選択した配信を削除します。本当によろしいですか？
        </div>
      </SenderDialog>,
    )
  }

  function stopRecording() {
    modal.show(
      <ActionDialog
        action={() => app.api.stopRecording(broadcastId)}
        onEnd={reloadItem}
      >
        録画を停止します。本当によろしいですか？
      </ActionDialog>,
    )
  }

  function sendCommand(name: string) {
    modal.show(
      <ActionDialog
        action={() => app.api.sendCommandToActiveSession(broadcastId, name)}
        onEnd={reloadItem}
      >
        特殊コマンド <code>{name}</code> を送信します。
      </ActionDialog>,
    )
  }

  return <>
    {waitFor(item, src => {
      const items = [
        new TabMenuItem(
          "情報",
          {
            route: CastifyRoute.projects.project.broadcasts.broadcast.overview.path,
            built: CastifyRoute.projects.project.broadcasts.broadcast.overview.getPath(projectId, broadcastId),
          },
          <BroadcastMetadataView src={src} />,
        ),
        new TabMenuItem(
          "ログ",
          {
            route: CastifyRoute.projects.project.broadcasts.broadcast.logs.path,
            built: CastifyRoute.projects.project.broadcasts.broadcast.logs.getPath(projectId, broadcastId),
          },
          <LogIndex projectId={projectId} query={{ type: "broadcast", id: broadcastId }} />,
        ),
      ]
      if (session?.privileged === true) {
        items.push(
          new TabMenuItem(
            "Debug",
            {
              route: CastifyRoute.projects.project.broadcasts.broadcast.debug.path,
              built: CastifyRoute.projects.project.broadcasts.broadcast.debug.getPath(projectId, broadcastId),
            },
            <BroadcastDebugView src={src} />,
          ),
        )
      }
      const title = (
        <VBox>
          <Box
            alignSelf="flex-start"
            marginBottom="0.33rem"
            padding="2px 5px"
            color="#777"
            fontSize="10pt"
            border="1px solid #777"
          >
            Broadcast
          </Box>
          {src.broadcastId}
        </VBox>
      )
      return (
        <TabbedPageHeader title={title} src={items}>
          <Dropdown title="操作">
            <DropdownItem onClick={() => player.play(broadcastId)}>
              プレイヤーで開く
            </DropdownItem>
            <DropdownItem disabled={!src.stoppedAt || bind?.value?.type === PlanType.Trial} onClick={modal.bind(<ExportDialog id={broadcastId} />)}>
              エクスポート
            </DropdownItem>
            {
              !src.stoppedAt && (
                <DropdownItem onClick={stopRecording}>
                  録画を停止
                </DropdownItem>
              )
            }
            <DropdownItem danger onClick={deleteBroadcast}>
              配信を削除
            </DropdownItem>
            {
              session?.privileged && <>
                <div className="dropdown-divider" />
                <DropdownItem onClick={modal.bind(<BroadcastUsageCreateDialog id={broadcastId} />)}>
                  利用量を追加
                </DropdownItem>
                <DropdownItem disabled={!!src.stoppedAt} onClick={() => sendCommand("crash")} danger>
                  録画をクラッシュ
                </DropdownItem>
                <DropdownItem disabled={!!src.stoppedAt} onClick={() => sendCommand("renew")}>
                  録画をリニューアル
                </DropdownItem>
              </>
            }
          </Dropdown>
        </TabbedPageHeader>
      )
    })
    }
  </>
}
