import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { platform } from "~context/core"
import { navigation } from "~context/nav"
import { Box, HBox } from "./box"
import { CastifyRoute } from "~core/castify-route"

const baseUrl = new URL(platform.baseUrl)

const namespace = baseUrl.host.replace(/^api\.([^.]+)\.dev\.castify\.jp$/, "$1")

export function NavigationBar(props: {height: string}): ReactElement {
  return (
    <Box height={props.height}>
      <HBox
        className="cas-nav"
        position="fixed"
        zIndex={3}
        top={0}
        width="100%"
        height={props.height}
        backgroundColor="#222"
        alignItems="center"
      >
        <Box className="sidebar-toggle">
          <button className="btn btn-dark" onClick={navigation.toggleSideMenu}>
            <i className="fas fa-bars" />
          </button>
        </Box>
        <Box color="white">
          <Link to={CastifyRoute.projects.path}>
            <img
              style={{ height: "46px" }}
              src="https://storage.googleapis.com/public.castify.jp/img/logo/text-h-white.svg"
              alt="Castify logo"
            />
          </Link>
          {!platform.public && (
            <span className="d-none d-sm-inline" style={{ color: "#999", fontSize: "0.75rem" }}>[{namespace}]</span>
          )}
        </Box>
        <Box className="control">
          <Link to={CastifyRoute.me.path} className="btn text-light" style={{ marginLeft: "4px" }}>
            <i className="fas fa-user-circle text-light" />
          </Link>
        </Box>
      </HBox>
    </Box>
  )
}
