import React, { CSSProperties, ReactElement } from "react"
import { Box } from "./box"
import Bundle from "../../package.json"

function toHTML(json: any): ReactElement {
  switch (typeof json) {
    case "boolean":
    case "number":
      return <code>{`${json}`}</code>
    case "string":
      return <span style={{ color: "#00a000" }}>&quot;{json}&quot;</span>
    default: {
      throw new Error()
    }
    case "object": {
      if (json === null) {
        return <span style={{ color: "#ff0000" }}>null</span>
      }
      if (json instanceof Array) {
        if (json.length === 0) {
          return <span style={{ fontWeight: "bold" }}>{"[]"}</span>
        }
        return (
          <ol>
            {json.map((e, i) => (
              <li key={i}>{toHTML(e)}</li>
            ))}
          </ol>
        )
      } else {
        const aux = Object.entries(json)
        if (aux.length === 0) {
          return <span style={{ fontWeight: "bold" }}>{"{}"}</span>
        }
        return (
          <ul>
            {aux.map(([key, value]) => (
              <li key={key}>
                <b>{key}</b> {toHTML(value)}
              </li>
            ))}
          </ul>
        )
      }
    }
  }
}

const textAreaStyles: CSSProperties = {
  backgroundColor: "#fafafa",
  fontSize: "10pt",
  fontFamily: "monospace",
  border: "1px solid #f0f0f0",
  padding: "0.5rem",
}

export function JSONViewer(props: { src: any }): ReactElement {
  return (
    <Box height="100%" overflow="auto" {...textAreaStyles}>
      {toHTML(props.src)}
    </Box>
  )
}

export function BundleJSONViewer(): ReactElement {
  const bundle = { ...Bundle, scripts: {} }
  return <JSONViewer src={bundle} />
}
