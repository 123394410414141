import { ReactElement, ReactNode } from "react"
import { Box, HBox, VBox } from "./box"
import { TabMenuItem, TabView } from "./tab"

export function PageHeader(props: {
  title?: ReactNode
  children?: ReactNode
}): ReactElement {
  return (
    <HBox
      alignItems="center"
      padding="1rem"
      backgroundColor="#fff"
      boxShadow="0 1px 3px rgba(0,0,0,0.2)"
    >
      {props.title && (
        <Box marginLeft="1rem" className="h2">
          {props.title}
        </Box>
      )}
      <Box marginLeft="auto">{props.children}</Box>
    </HBox>
  )
}

type TabbedPageHeaderProps = {
  title?: ReactNode
  src: TabMenuItem[]
  children?: ReactNode
}

export function TabbedPageHeader(props: TabbedPageHeaderProps): ReactElement {
  return (
    <Box>
      <VBox padding="1.5rem" backgroundColor="white">
        <HBox alignItems="center">
          <Box marginLeft="1rem" className="h2">
            {props.title}
          </Box>
          <HBox flexGrow={1} justifyContent="flex-end">
            {props.children}
          </HBox>
        </HBox>
      </VBox>
      <TabView src={props.src} />
    </Box>
  )
}

export function PageBody(props: { children?: ReactNode }): ReactElement {
  return (
    <Box className="px-2 px-md-4 py-4" maxWidth="100%" overflowY="auto">
      {props.children}
    </Box>
  )
}
