import React, { ReactElement } from "react"
import { Link } from "react-router-dom"
import { app } from "~context/core"
import { player } from "~context/player"
import { BroadcastListRow } from "~core/castify/api"
import { Box, HBox, VBox } from "~parts/box"
import { PageBody, PageHeader } from "~parts/header"
import { usePage } from "~parts/paged"
import { Paginator } from "~parts/paginator"
import { waitFor } from "~parts/spinner"
import { formatDateTime } from "~util/format"
import { useLoader } from "~util/hook"
import { elevation } from "~util/style"
import { BroadcastPreview } from "./parts/preview"
import { CastifyRoute } from "~core/castify-route"

const liveBadge = (
  <HBox
    alignItems="center"
    color="red"
    fontSize="8pt"
    padding="0 3px"
    border="1px solid red"
    borderRadius="5px"
  >
    Live
  </HBox>
)

function ListItem(props: { projectId: string; src: BroadcastListRow }) {

  const live = !props.src.duration.fixed

  return (
    <HBox backgroundColor="white" marginBottom="1rem" {...elevation}>
      <Box minWidth="88px">
        <BroadcastPreview id={props.src.broadcastId} duration={props.src.duration} />
      </Box>

      <VBox
        justifyContent="space-around"
        fontSize="10pt"
        width="5rem"
        padding="1rem 0.66rem"
        borderRight="1px solid #eee"
      >
        <HBox justifyContent="space-between" alignItems="center">
          <VBox width="15px" alignItems="center">
            <i className="fa-solid fa-arrow-trend-up"></i>
          </VBox>
          <Box fontFamily="monospace">{props.src.currentViewCount}</Box>
        </HBox>
        <HBox justifyContent="space-between" alignItems="center">
          <VBox width="15px" alignItems="center">
            <i className="fas fa-eye" />
          </VBox>
          <Box fontFamily="monospace">{props.src.overallViewCount}</Box>
        </HBox>
      </VBox>

      <VBox padding="0.5rem 1rem">
        <HBox
          color={props.src.name ? "inherit" : "#666"}
          alignItems="center"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Link to={CastifyRoute.projects.project.broadcasts.broadcast.getPath(props.projectId, props.src.broadcastId)}>
            <span>{props.src.name ?? "(Untitled)"}</span>
          </Link>

          {props.src.link && (
            <a href={props.src.link} target="_blank" rel="noopener noreferrer">
              <i className="ms-2 fas fa-external-link" />
            </a>
          )}
          {live && <Box marginLeft="0.33rem">{liveBadge}</Box>}
        </HBox>

        <HBox fontSize="8pt" color="#888">
          {formatDateTime(props.src.startedAt)}
        </HBox>

        <Box
          marginTop="0.5rem"
          fontSize="10pt"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {props.src.startedAt && (
            <button className="btn p-0 me-2" onClick={() => player.play(props.src.broadcastId)}>
              <i className="fas fa-play-circle" />
            </button>
          )}

          ID: {props.src.broadcastId}
        </Box>
      </VBox>
    </HBox>
  )
}

function Placeholder(): ReactElement {
  return (
    <VBox className="text-secondary" alignItems="center">
      <Box>
        <h1>
          <i className="fas fa-broadcast-tower"></i>
        </h1>
      </Box>

      <Box className="h4" marginTop="0.5rem">
        No broadcasts!
      </Box>

      <Box marginTop="0.5rem">
        既存の
        <a
          href={`${app.config.documentUrl}advanced/broadcast_without_sdk.html`}
        >
          配信ツール
        </a>
        または{" "}
        <a href={`${app.config.documentUrl}quickstart/broadcast.html`}>
          Castify SDK
        </a>{" "}
        を利用してライブ配信を開始できます。
      </Box>
    </VBox>
  )
}

const itemsInPage = 10

export function BroadcastList(props: {projectId: string}): ReactElement {

  const { projectId } = props
  const pageNo = usePage()
  const [page] = useLoader(() => app.api.listBroadcasts(pageNo * itemsInPage), [pageNo])

  return (
    <VBox>
      <PageHeader title="配信リスト">
        <Link to={CastifyRoute.projects.project.newBroadcast.getPath(props.projectId)}>
          <button
            type="button"
            className="btn btn-primary btn-sm"
          >
            <i className="fas fa-plus" />
          </button>
        </Link>
      </PageHeader>

      <PageBody>
        {waitFor(page, (page) => {
          if (page.total === 0) {
            return <Placeholder />
          }
          const paginator = (
            <Paginator
              base={CastifyRoute.projects.project.broadcasts.getPath(projectId)}
              index={pageNo}
              total={Math.ceil(page.total / itemsInPage)}
            />
          )
          return (
            <React.Fragment>
              {paginator}
              {page.values.map((e) => (
                <ListItem key={e.broadcastId} src={e} projectId={projectId} />
              ))}
              {paginator}
            </React.Fragment>
          )
        })}
      </PageBody>
    </VBox>
  )
}
