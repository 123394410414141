import { BehaviorSubject } from "rxjs"

class Navigation {
  readonly $showsSideMenu = new BehaviorSubject<boolean>(true)

  readonly toggleSideMenu = () => {
    this.$showsSideMenu.next(!this.$showsSideMenu.value)
  }
}

export const navigation = new Navigation()
