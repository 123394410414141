import React, { ReactElement } from "react"
import { Link, useParams } from "react-router-dom"
import { app } from "~context/core"
import { modal } from "~context/modal"
import { DiskEntry } from "~core/castify/api/msg/disk"
import { PageBody, PageHeader } from "~parts/header"
import { Empty } from "~parts/placeholder"
import { waitForList } from "~parts/spinner"
import { formatBytes, formatDateTime } from "~util/format"
import { useLoader2 } from "~util/hook"
import { DiskEntryCreateDialog, DiskEntryDeleteDialog } from "./dialog"
import { Dropdown, DropdownItem } from "~parts/dropdown"

function ListItem(props: { onChange?: () => void, src: DiskEntry }): ReactElement {
  return (
    <tr>
      <td className="cas-cell-shrink">
        <input className="mx-2" type="checkbox" />
      </td>
      <td>
        <Link to={`/projects/${app.project.id}/disks/${props.src.disk}/${encodeURIComponent(props.src.path)}`}>{props.src.path}</Link>
      </td>
      <td>
        {formatBytes(props.src.size)}
      </td>
      <td>
        {formatDateTime(props.src.createdAt)}
      </td>
      <td className="cas-cell-shrink">
        <button className="btn btn-sm" onClick={modal.bind(<DiskEntryDeleteDialog {...props}  />)}>
          <i className="fas fa-trash text-secondary" />
        </button>
      </td>
    </tr>
  )
}

// noinspection JSUnusedLocalSymbols
export function DiskEntryList(): ReactElement {
  const { disk } = useParams()
  if (disk == undefined) {
    throw 0
  }
  const [list, reloadList] = useLoader2((disk) => app.api.listDiskEntries(disk), [disk])
  const title = (
    <div className="d-flex flex-column align-items-start">
      <div className="mb-1 border text-muted p-1" style={{ fontSize: "10pt" }}>
        Disk
      </div>
      <div>{disk}</div>
    </div>
  )
  return (
    <div className="position-relative">
      <PageHeader title={title}>
        <Dropdown title="操作">
          <DropdownItem onClick={modal.bind(<DiskEntryCreateDialog disk={disk} onFinish={reloadList} />)}>
            アップロード
          </DropdownItem>
        </Dropdown>
      </PageHeader>
      <PageBody>
      {
        waitForList(list, src => (
          <table className="cas-table">
            <thead>
              <tr>
                <th />
                <th>名前</th>
                <th>サイズ</th>
                <th>作成日時</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {src.sort((a, b) => b.createdAt - a.createdAt).map(e => <ListItem key={e.path} src={e} onChange={reloadList} />)}
            </tbody>
          </table>
        )) ?? <Empty />
      }
      </PageBody>
    </div>
  )
}
