import { Source } from "@castify-inc/castify-player"
import { ReactElement } from "react"
import { player } from "~context/player"
import { BroadcastGetResult } from "~core/castify/api"
import { VBox } from "~parts/box"
import { JSONViewer } from "~parts/code"
import { Panel } from "~parts/panel"
import { PropertyList, PropertyListItem } from "~parts/property-list"
import { formatDateTime } from "~util/format"
import {PlaybackStatsGraph} from "~pages/broadcast/parts/playback-stats";
import { BroadcastUsageSummary } from "./usage"
import { useLoader2 } from "~util/hook"
import { app } from "~context/core"
import { waitFor } from "~parts/spinner"

export function BroadcastMetadataView(props: { src: BroadcastGetResult }): ReactElement {
  const playbackUrl = new Source(props.src.broadcastId).url.replace(/[?]$/, "")
  
  const [count, reloadCount] = useLoader2(id => app.api.getPlaybackCount(id), [props.src.broadcastId])

  return (
    <VBox>
      <PlaybackStatsGraph broadcastId={props.src.broadcastId} height={300}/>
      <Panel title="プロパティ">
        <PropertyList>
          <PropertyListItem name="ID">{props.src.broadcastId}</PropertyListItem>

          {props.src.name && (
            <PropertyListItem name="名前">{props.src.name}</PropertyListItem>
          )}

          {props.src.customData && (
            <PropertyListItem name="Custom Data">
              {props.src.customData}
            </PropertyListItem>
          )}

          <PropertyListItem name="開始時刻">
            <span className="font-monospace">{formatDateTime(props.src.startedAt)}</span>
          </PropertyListItem>

          <PropertyListItem name="終了時刻">
            <span className="font-monospace">{formatDateTime(props.src.stoppedAt)}</span>
          </PropertyListItem>

          <PropertyListItem name="視聴リンク">
            <a href={playbackUrl}>{playbackUrl}</a>
            <button
              onClick={() => player.play(props.src.broadcastId)}
              className="mx-2 btn btn-sm btn-outline-secondary"
            >
              <i className="fas fa-play" />
            </button>
          </PropertyListItem>

          <PropertyListItem name="視聴数(現在)">
            { waitFor(count, (src) => 
              <div>
                <i className="fa-solid fa-arrow-trend-up"></i>
                <span className="ms-2 text-monospace">{src.currentPlaybackCount}</span>
              </div>
            )}
          </PropertyListItem>

          <PropertyListItem name="視聴数(累計)">
            { waitFor(count, (src) => 
              <div>
                <i className="fas fa-eye" />
                <span className="ms-2 text-monospace">{src.overallPlaybackCount}</span>
              </div>
            )}
          </PropertyListItem>
              
          <PropertyListItem name="アップロード先">
            <JSONViewer src={{
              "rtmp": props.src.recorder.rtmp,
              "rtmps": props.src.recorder.rtmps,
            }} />
          </PropertyListItem>

          <PropertyListItem name="設定">
            <JSONViewer src={props.src.options} />
          </PropertyListItem>
        </PropertyList>
      </Panel>
      <Panel title="データ使用量" className="mt-4">
        <div className="mx-3 my-1">
          <BroadcastUsageSummary {... props} />
        </div>
      </Panel>
    </VBox>
  )
}
