import React, { ReactElement } from "react"
import { Link } from "react-router-dom"
import { app } from "~context/core"
import { CastifyRoute } from "~core/castify-route"
import { modal } from "~context/modal"
import { WebhookGetResult } from "~core/castify/api/msg/webhook"
import { VBox } from "~parts/box"
import { PageBody, PageHeader } from "~parts/header"
import { Mask } from "~parts/mask"
import { EmptyIndicator } from "~parts/placeholder"
import { PopOverList } from "~parts/popover-list"
import { waitForList } from "~parts/spinner"
import { useLoader } from "~util/hook"
import { WebhookCreateDialog } from "~pages/webhook/parts/create"
import { WebhookUpdateDialog } from "~pages/webhook/parts/update"
import { WebhookDeleteDialog } from "~pages/webhook/parts/delete"

function ListItem(props: {
  onEdit: () => void
  onDelete: () => void
  projectId: string
  src: WebhookGetResult
}): ReactElement {
  return (
    <tr key={props.src.id}>
      <td>
        <Link to={CastifyRoute.projects.project.webhooks.webhook.getPath(props.projectId, props.src.id)}>
          {props.src.name}
        </Link>
        </td>
      <td>
        <PopOverList>{props.src.kinds}</PopOverList>
      </td>
      <td className="text-nowrap">{props.src.uri}</td>
      <td className="text-nowrap">
        <Mask>
          <code>{props.src.token}</code>
        </Mask>
      </td>
      <td className="cas-cell-shrink">
        <button onClick={props.onEdit} className="btn btn-sm">
          <i className="fas fa-edit text-secondary" />
        </button>
        <button onClick={props.onDelete} className="btn btn-sm">
          <i className="fas fa-trash text-secondary" />
        </button>
      </td>
    </tr>
  )
}

export function WebhookList(props: { projectId: string }): ReactElement {

  const { projectId } = props
  const [list, reloadList] = useLoader(() => app.api.listWebhooks())

  return (
    <VBox>
      <PageHeader title="Webhook">
        <button
          className="btn btn-primary btn-sm"
          onClick={modal.bind(<WebhookCreateDialog onEnd={reloadList} />)}
        >
          <i className="fas fa-plus" />
        </button>
      </PageHeader>
      <PageBody>
        {
          waitForList(list, (src) => (
            <table className="cas-table">
              <thead>
              <tr>
                <th>名前</th>
                <th>フック種別</th>
                <th>URL</th>
                <th>署名キー</th>
                <th />
              </tr>
              </thead>
              <tbody>
              {src.map((e) => (
                <ListItem
                  key={e.id}
                  projectId={projectId}
                  src={e}
                  onDelete={modal.bind(<WebhookDeleteDialog id={e.id} onEnd={reloadList} />)}
                  onEdit={modal.bind(<WebhookUpdateDialog src={e} onEnd={reloadList} />)}
                />
              ))}
              </tbody>
            </table>
          )) ?? <EmptyIndicator />
        }
      </PageBody>
    </VBox>
  )
}
