import React, { ReactElement } from "react"
import { Box, VBox } from "~parts/box"
import { PageHeader } from "~parts/header"
import { LogEntry } from "~parts/log"
import { CastifyRoute } from "~core/castify-route"

export function AuditLogScreen(props: { projectId: string }): ReactElement {

  const { projectId } = props
  const { logId } = CastifyRoute.projects.project.auditLogs.auditLog.usePath()

  return (
    <VBox>
      <PageHeader title="監査ログ" />
      <Box padding="2rem">
        <LogEntry projectId={projectId} logId={logId} />
      </Box>
    </VBox>
  )
}
