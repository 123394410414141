import "@fortawesome/fontawesome-free/css/all.css"
import "./index.scss"
import React from "react"
import { app } from "~context/core"
import { Navigate } from "react-router"
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom"
import { createRoot } from "react-dom/client"
import { CastifyRoute } from "~core/castify-route"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { DialogHost } from "~context/modal"
import { PlayerView } from "~pages/player"
import { ProjectDashboard } from "~pages/dashboard/project"
import { SessionGuard } from "~guards/session"
import { AccountDashboard } from "~pages/account/dashboard"
import { AccountListPage } from "~pages/account/list"
import { RegisterPage } from "~pages/auth/register"
import { UserPage } from "~pages/user"
import { ProjectListScreen } from "~pages/projects"
import { BundleJSONViewer } from "~parts/code"
import { NavigationBar } from "~parts/nav"
import { Box } from "~parts/box"
import { UploaderUI } from "~context/upload"

const stripe = loadStripe(app.config.stripe.apiKey)

const Nav = () => {
  return (
    <>
      <NavigationBar height="60px" />
      <Box minHeight="calc(100vh - 60px)" backgroundColor="#f3f3f3">
        <Outlet />
      </Box>
    </>
  )
}

const root = (
  <BrowserRouter>
    <Elements stripe={stripe}>
      <Routes>
        <Route path="/" element={<Navigate to={CastifyRoute.projects.path} replace={true} />} />
        <Route path={CastifyRoute.about.path} element={<BundleJSONViewer />} />
        <Route path={CastifyRoute.register.path} element={<RegisterPage />} />
        <Route path="/" element={<SessionGuard />}>
          <Route path={CastifyRoute.player.path} element={<PlayerView />} />
          <Route path="/" element={<Nav />}>
            <Route path="*" element={<Navigate to={CastifyRoute.projects.path} replace={true} />} />
            <Route path={CastifyRoute.me.path} element={<UserPage />} />
            <Route path={CastifyRoute.me.invitations.path} element={<Navigate to={CastifyRoute.projects.path} replace={true} />} />
            <Route path={CastifyRoute.accounts.path} element={<AccountListPage />} />
            <Route path={CastifyRoute.accounts.account.path} element={<AccountDashboard />} />
            <Route path={CastifyRoute.projects.path} element={<ProjectListScreen />} />
            <Route path={CastifyRoute.projects.project.path} element={<ProjectDashboard />} />
          </Route>
        </Route>
      </Routes>
      <UploaderUI />
      <DialogHost />
    </Elements>
  </BrowserRouter>
)

createRoot(document.getElementById("screen")!).render(root)
