import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { app } from "~context/core"
import { modal } from "~context/modal"
import { CastifyRoute } from "~core/castify-route"
import { MeterType } from "~core/castify/api/msg/quota"
import { Box, VBox } from "~parts/box"
import { ActionDialog, AlertText } from "~parts/dialog"
import { Dropdown, DropdownItem } from "~parts/dropdown"
import { PageBody, PageHeader } from "~parts/header"
import { waitFor } from "~parts/spinner"
import { chooseUnitPrefix } from "~util/format"
import { useLoader } from "~util/hook"

type UnitName = {
  normal: string
  suffix: string
  precision?: number
};

const units = {
  none: {
    normal: "-",
    suffix: ""
  },
  byte: {
    normal: "Byte",
    suffix: "iB",
    precision: 1
  }
};

type MeterTypeInfo = {
  meter: MeterType
  label: string
  unit: UnitName
  strict?: boolean
}

type QuotaEntry = {
  info: MeterTypeInfo
  value: number
  limit: number
}

const meterTypes: ReadonlyArray<MeterTypeInfo> = [
  {
    meter: MeterType.CDNCacheLoad,
    label: "CDN キャッシュ読み込み",
    unit: units.byte,
    strict: true
  },
  {
    meter: MeterType.CDNCacheFill,
    label: "CDN キャッシュ書き込み",
    unit: units.byte,
    strict: true
  },
  {
    meter: MeterType.RecorderTraffic,
    label: "ネットワーク配信量 (入力)",
    unit: units.byte,
    strict: true
  },
  {
    meter: MeterType.StreamerTraffic,
    label: "ネットワーク配信量 (出力)",
    unit: units.byte,
    strict: true
  },
  {
    meter: MeterType.StorageUsage,
    label: "ディスク使用量",
    unit: units.byte,
    strict: true
  },
  {
    meter: MeterType.Broadcast,
    label: "配信数",
    unit: units.none
  },
  {
    meter: MeterType.Recorder,
    label: "配信数 (ライブ)",
    unit: units.none
  }
];

function ListItem(props: { src: QuotaEntry }): ReactElement {
  let usage = props.src.value / props.src.limit
  if (usage > 1) {
    usage = 1
  }
  const progressBarClass =
    usage >= 0.9 ? "bg-danger"  :
    usage >= 0.7 ? "bg-warning" : ""

  const { name, rate } = chooseUnitPrefix(Math.max(props.src.value, props.src.limit))

  return (
    <tr>
      <td style={{ padding: "0 1rem" }}>{props.src.info.label}</td>
      <td className="cas-numeric">
        { props.src.value === -1 ? "-" : (props.src.value * rate).toFixed(props.src.info.unit.precision ?? 0)}
      </td>
      <td className="cas-numeric">
        { props.src.limit === -1 ? "-" : (props.src.limit * rate).toFixed(props.src.info.unit.precision ?? 0) }
      </td>
      <td style={{ textAlign: "center" }}>
        { name ? name
          + props.src.info.unit.suffix
          : props.src.info.unit.normal }
      </td>
      <td>{
        props.src.limit === -1 ? "n/a" : (
          <Box className="progress small" minWidth="120px">
            <div className={"progress-bar " + progressBarClass} role="progressbar" style={{ width: (usage * 100 | 0) + '%' }}>
              { usage > 0.7 && (usage * 100 | 0) + '%' }
            </div>
          </Box>
        )
      }
      </td>
    </tr>
  )
}

// noinspection JSUnusedLocalSymbols
export function UsageScreen(props: {projectId: string}) {

  const [items, reload] = useLoader(async () => {

    const meters = await app.api.getMeters()
    const quotas = await app.api.getQuotas()

    const out: QuotaEntry[] = []

    for (const info of meterTypes) {
      const meter = meters.find(e => e.type === info.meter)
      const quota = quotas.find(e => e.type === info.meter)
      out.push({
        info,
        limit: quota?.limit ?? -1,
        value: meter?.value ??  0
      });
    }
    return out;
  })

  const restricted = !! items?.value?.find(e => e.info.strict && e.limit !== -1 && e.limit <= e.value)

  return (
    <VBox>
      <PageHeader title="利用状況">
        { app.session.privileged && (
          <Dropdown title="操作">
            { !restricted ?
              (
                <DropdownItem onClick={() => showAlert("クォータを強制付与します。", () => app.api.createTrialQuotas(), reload)} danger>
                  トライアル用クォータを付与
                </DropdownItem>
              ) : (
                <DropdownItem onClick={() => showAlert("クォータを強制削除します。", () => app.api.deleteTrialQuotas(), reload)}>
                  トライアル用クォータを削除
                </DropdownItem>
              )
            }
          </Dropdown>
        )}
      </PageHeader>
      <PageBody>
        {waitFor(items, items =>
          <>
            { restricted &&
              <div className="mb-3">
                <AlertText>
                  ご利用量がトライアル利用の規定値に達したため配信機能が停止されています。この制限を回避するにはご加入中のプランの<Link to={CastifyRoute.projects.project.catalog.getPath(props.projectId)}>アップグレード</Link>が必要です。
                </AlertText>
              </div>
            }
            <table className="cas-table" style={{ minWidth: "50%" }}>
              <thead>
                <tr>
                  <th>種別</th>
                  <th style={{ textAlign: "right" }}>使用</th>
                  <th style={{ textAlign: "right" }}>最大</th>
                  <th style={{ textAlign: "center", minWidth: "3rem" }}>単位</th>
                  <th style={{ textAlign: "center" }}>到達率</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {items.map((e) => (
                  <ListItem key={e.info.meter} src={e} />
                ))}
              </tbody>
            </table>
          </>
        )}
        <Box className="small mt-4">
          <ul>
            <li>到達率は毎週リセットされます</li>
            <li>到達率が 100% に達すると視聴／配信が不可能になります</li>
          </ul>
        </Box>
      </PageBody>
    </VBox>
  )
}

function showAlert(message: string, action: () => Promise<void>, onEnd?: () => void) {
  modal.show(
    <ActionDialog action={action} onEnd={onEnd}>{message}</ActionDialog>
  )
}
