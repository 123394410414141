import React, { ReactElement, useId, useState } from "react"
import { app } from "~context/core"
import { VBox } from "~parts/box"
import { PageBody, PageHeader } from "~parts/header"
import { useForm } from "react-hook-form"

type InquiryForm = {
  email: string,
  name: string,
  companyName: string,
  broadcastId: string,
  message: string,
}

export function InquiryView(props: { projectId: string }): ReactElement {

  const [id1, id2, id3, id4, id5] = [useId(), useId(), useId(), useId(), useId()]
  const { register, handleSubmit, reset, formState: { errors } } = useForm<InquiryForm>({
    mode: "onBlur",
    defaultValues: {
      name: app.session.user?.displayName ?? undefined,
      email: app.session.user?.email ?? undefined,
    },
  })
  const [status, setStatus] = useState<"INITIAL" | "OK" | "PROCESSING" | "ERROR">("INITIAL")

  const onSubmit = (value: InquiryForm) => {
    setStatus("PROCESSING")

    app.api.sendInquiry(
      `コンソールからのお問い合わせ (ProjectID: ${app.project.id}, UID: ${app.session.user?.uid})`,
      `お問い合わせを受け取りました。

■Project ID
${props.projectId}
■User ID
${app.session.user?.uid}
■Broadcast ID
${value.broadcastId.length <= 0 ? "(記載なし)" : value.broadcastId}
■名前
${value.name}
■会社名
${value.companyName.length <= 0 ? "(記載なし)" : value.companyName}
■メールアドレス
${value.email}
■お問い合わせ本文
${value.message}
`
    ).then(() => {
      reset()
      setStatus("OK")
    }).catch(() => {
      setStatus("ERROR")
    })
  }

  return (
    <VBox>
      <PageHeader title="お問い合わせ" />
      <PageBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            Castify へのお問い合わせについては本ページよりお送り下さい。
          </div>

          <div className="mb-3">
            <label htmlFor={id1} className="form-label">メールアドレス (必須)</label>
            <input type="email" className="form-control" disabled={status === "PROCESSING"} id={id1} placeholder="name@example.com" {...register("email", {
              required: {
                value: true,
                message: "必須要素です。",
              },
            })} />
            {errors.email ? <p className="text-danger">{errors.email?.message}</p> : <></>}
          </div>

          <div className="mb-3">
            <label htmlFor={id2} className="form-label">お名前 (必須)</label>
            <input type="text" className="form-control" disabled={status === "PROCESSING"} id={id2} placeholder="田中 一郎" {...register("name", {
              required: {
                value: true,
                message: "必須要素です。",
              },
            })} />
            {errors.name ? <p className="text-danger">{errors.name?.message}</p> : <></>}
          </div>

          <div className="mb-3">
            <label htmlFor={id3} className="form-label">会社名 (Optional)</label>
            <input type="text" className="form-control" disabled={status === "PROCESSING"} id={id3} placeholder="株式会社Castify" {...register("companyName")} />
            {errors.companyName ? <p className="text-danger">{errors.companyName?.message}</p> : <></>}
          </div>

          <div className="mb-3">
            <label htmlFor={id4} className="form-label">お問い合わせ対象の配信ID (Optional)</label>
            <input type="text" className="form-control" disabled={status === "PROCESSING"} id={id4} placeholder="bc_XXXXXXXXXXX" {...register("broadcastId")} />
            {errors.broadcastId ? <p className="text-danger">{errors.broadcastId?.message}</p> : <></>}
          </div>

          <div className="mb-3">
            <label htmlFor={id5} className="form-label">お問い合わせ内容 (必須)</label>
            <textarea className="form-control" id={id5} disabled={status === "PROCESSING"} rows={3} {...register("message", {
              required: {
                value: true,
                message: "必須要素です。",
              },
            })} />
            {errors.message ? <p className="text-danger">{errors.message?.message}</p> : <></>}
          </div>

          <div className="mb-3">
            <button type="submit" className="btn btn-primary" disabled={status === "PROCESSING"}>送信</button>
            {status === "ERROR" ? <p className="text-danger">お問い合わせ送信中にエラーが発生しました。<a href="mailto:support@castify-inc.com">support@castify-inc.com</a> までお問い合わせ下さい。</p> : <></>}
            {status === "OK" ? <p className="text-primary">お問い合わせを受け付けました。数日待っても返信がない場合は <a href="mailto:support@castify-inc.com">support@castify-inc.com</a> までお問い合わせ下さい。</p> : <></>}
          </div>
        </form>
      </PageBody>
    </VBox>
  )
}
