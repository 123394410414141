import React, { ReactElement, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { app, getDB } from "~context/core"
import { VBox } from "~parts/box"
import { PageBody, PageHeader } from "~parts/header"
import { dropSignal } from "~context/signal"
import { spinner } from "~parts/spinner"
import { Empty } from "~parts/placeholder"
import { formatDateTime } from "~util/format"
import { CastifyRoute } from "~core/castify-route"
import { ProgressBar } from "~parts/progress"
import { ActionDialog } from "~parts/dialog"
import { modal } from "~context/modal"

const db = getDB()

type MediaExportExtra = {
  path: string
}

const phases = {
  Initial: "スケジュール済み",
  Executed: "実行中",
  Failure: "失敗",
  Success: "完了",
  Cancelled: "キャンセル済み"
}

type Phase = keyof typeof phases

type JobStatus = {
  id: string
  type: string
  phase: Phase
  extra: MediaExportExtra
  completion?: number
  updatedAt: number
  createdAt: number
}

function isTerminal(src: Phase) {
  switch (src) {
  case "Success":
  case "Failure":
  case "Cancelled":
    return true
  default:
    return false
  }
}

function CancelDialog(props: { src: JobStatus }): ReactElement {
  return (
    <ActionDialog
      action={() => app.api.deleteJob(props.src.id)}
      title="ジョブの中止"
    >
      指定したジョブ <code>{props.src.id}</code> の実行を中止します。よろしいですか？
    </ActionDialog>
  )
}

function JobListItem(props: { src: JobStatus }): ReactElement {
  const rate = props.src.phase === "Success" ? 1: props.src.completion ?? 0
  return (
    <tr>
      <td className="d-none d-md-table-cell font-monospace">
        {props.src.id}
      </td>
      <td className="text-nowrap">
        ファイル生成
      </td>
      <td className="text-nowrap" style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
        { props.src.phase === "Success"
          ? <Link to={CastifyRoute.projects.project.disk.file.getPath(app.project.id, props.src.extra.path)}>{props.src.extra.path}</Link>
          : <div>{props.src.extra.path}</div>
        }
      </td>
      <td className="text-nowrap">
        <code>{phases[props.src.phase]}</code>
      </td>
      <td>
        <ProgressBar rate={rate} stable={props.src.phase !== "Executed"} />
      </td>
      <td className="text-nowrap">
        {formatDateTime(props.src.createdAt, true)}
      </td>
      <td>
        <button className="btn btn-danger btn-sm text-nowrap" disabled={isTerminal(props.src.phase)} onClick={modal.bind(<CancelDialog {... props} />)}>
          中止
        </button>
      </td>
    </tr>
  )
}

function JobList(): ReactElement {

  useEffect(() => { dropSignal("scheduledJobs") }, [])

  const [jobs, setJobs] = useState<JobStatus[]>()

  const oldest = useMemo(() => Date.now() - 60_000 * 5, [])

  useEffect(() =>
    db.collection(`projects/${app.project.id}/jobs`).where("updatedAt", ">=", oldest).onSnapshot(snapshot => {
      const jobs: JobStatus[] = []
      snapshot.forEach(doc => {
        const data = doc.data() as any
        jobs.push({
          id: doc.id,
          type: data.type,
          phase: data.phase,
          extra: data.extra,
          completion: data.completion,
          updatedAt: data.updatedAt,
          createdAt: data.createdAt
        })
      })
      setJobs(jobs.sort((a, b) => a.createdAt - b.createdAt))
    }),
    [oldest]
  )

  if (jobs === undefined) {
    return spinner
  }
  if (jobs.length === 0) {
    return <Empty />
  }
  return (
    <table className="cas-table w-100">
      <thead>
        <tr>
          <th className="d-none d-md-table-cell">ID</th>
          <th>内容</th>
          <th>対象</th>
          <th>状態</th>
          <th className="text-nowrap">
            進行状況
          </th>
          <th>開始時刻</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {jobs.map(e => <JobListItem key={e.id} src={e} />)}
      </tbody>
    </table>
  )
}

// noinspection JSUnusedLocalSymbols
export function JobListScreen(props: {projectId: string}): ReactElement {
  return (
    <VBox position="relative">
      <PageHeader title="ジョブ一覧" />
      <PageBody>
        <JobList />
      </PageBody>
    </VBox>
  )
}
