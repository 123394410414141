import { ReactElement } from "react"
import { app } from "~context/core"
import { PredefinedRole } from "~core/castify/api/msg/role"
import { castifyRoles } from "~core/castify/config"
import { Box, VBox } from "~parts/box"
import { SenderDialog } from "~parts/dialog"
import { useBinder, useCheckbox } from "~util/form"
import { useSender } from "~util/hook"

export function APITokenCreateDialog(props: { onEnd?(): void }): ReactElement {
  const roles = useCheckbox<PredefinedRole>()
  const name = useBinder()

  const sender = useSender(async () => {
    if (name.value.trim() === "") {
      throw new Error("識別名を入力してください。")
    }
    if (roles.value.size === 0) {
      throw new Error("ロールが未選択です。")
    }
    await app.api.createAPIToken({ name: name.value, roles: [...roles.value] })
  })

  const checks = castifyRoles.map((name) => (
    <Box
      key={name}
      className="form-check"
      fontSize="10pt"
      fontFamily="monospace"
    >
      <label className="form-check-label">
        <input
          {...roles.bind(name)}
          className="form-check-input"
          type="checkbox"
        />
        {name}
      </label>
    </Box>
  ))
  return (
    <SenderDialog
      title="API トークンの新規作成"
      sender={sender}
      onEnd={props.onEnd}
    >
      <VBox minWidth="24rem">
        <Box margin="1.5rem 1.5rem 0 1.5rem">
          <input
            {...name}
            type="text"
            className="form-control"
            placeholder="識別名"
          />
        </Box>
        <VBox margin="1rem 1.5rem 0 1.5rem">
          <Box fontSize="10pt" color="#666">
            このトークンに割り当てるロール
          </Box>
          <Box marginLeft="0.5rem" marginTop="0.5rem">
            {checks}
          </Box>
        </VBox>
      </VBox>
    </SenderDialog>
  )
}
