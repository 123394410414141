import React, {
  CSSProperties,
  ReactElement,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react"
import { Box } from "~parts/box"
import { toHoursString } from "~util/format"
import { useThrottledState } from "~util/hook"
import { bounds, noInteraction } from "~util/style"

type SeekerProps = {
  value?: number
  width: number | string
  height: number
  duration: number
  onChange?: (time: number) => void
  getPreview?: (time: number) => Promise<Blob>
}

const timeIndicatorStyle: CSSProperties = {
  ...noInteraction,
  position: "absolute",
  zIndex: 1,
  bottom: "100%",
  backgroundColor: "rgba(0,0,0,0.66)",
  borderRadius: "5px",
  padding: "2px 2px",
  color: "white",
  fontFamily: "monospace",
  fontSize: "8pt",
  textAlign: "center",
}

const timePreviewWidth = 120
const timePreviewMaxHeight = 100

const timePreviewStyle: CSSProperties = {
  ...noInteraction,
  position: "absolute",
  zIndex: 1,
  bottom: "300%",
  border: "1px solid rgba(255,255,255,0.66)",
  backgroundColor: "rgba(0,0,0,0.66)",
  width: timePreviewWidth,
  maxHeight: timePreviewMaxHeight, //縦長すぎるサムネが来たときの安全弁
  objectFit: "cover" //縦長のときに全体像を見せたいなら "contain" のほうが良いかも
}

export function Seeker(props: SeekerProps): ReactElement {
  const seekerBoxElm = useRef<HTMLDivElement>(null)

  const [hover, setHover] = useState(false)
  const [value, setValue] = useState(props.value || 0)
  const [mouse, setMouse] = useState(0)
  const throttledMouse = useThrottledState(mouse, 300)

  useEffect(() => {
    setValue(props.value || 0)
  }, [props.value])

  const { width, height } = props

  const boldWeight = hover ? 0.25 * 1.5 : 0.25

  function calculateMouseValue(e: React.MouseEvent): number | undefined {
    const elm = seekerBoxElm.current
    if (!elm) {
      return undefined
    }
    // Calculate mouse offsetX which is based on SeekerBox element
    // https://qiita.com/yukiB/items/cc533fbbf3bb8372a924
    const targetRect = elm.getBoundingClientRect()
    const result = (e.clientX - targetRect.left) / elm.offsetWidth
    return result < 0 || result > 1 ? undefined : result
  }

  function doubleToPercentStr(v: number) {
    return `${v * 100}%`
  }

  /**
   * left基準で指定した percent の位置で中央揃えになるような CSS を生成する
   * 要素の横幅を加味して、左右ともにはみ出ないように調整する
   */
  function generateCenteredCss(percent: number, elementWidth: number) {
    if (percent < 0.5) {
      return {
        left: `max(${elementWidth / 2}px, ${doubleToPercentStr(percent)})`,
        transform: "translate(-50%, 0)",
      }
    } else {
      return {
        right: `max(${elementWidth / 2}px, ${doubleToPercentStr(1 - percent)})`,
        transform: "translate(50%, 0)",
      }
    }
  }

  function onClick(e: React.MouseEvent) {
    const src = calculateMouseValue(e)
    if (!src) return
    setValue(src)
    if (props.onChange) {
      props.onChange(src * props.duration)
    }
  }

  function onMouseMove(e: React.MouseEvent) {
    if (!hover) {
      return
    }
    const src = calculateMouseValue(e)
    if (!src) return
    setMouse(src)
  }

  type ImageData = {
    blob?: Blob
    time: number
  }

  /**
   * Reducer to prevent previews from overwriting by responses from previously executed requests
   * @see https://github.com/castify-inc/cas-console/pull/31#discussion_r637946371
   */
  function imageDataReducer(currentState: ImageData, newState: ImageData) {
    if (currentState && currentState.time > newState.time) {
      return currentState
    } else {
      return newState
    }
  }

  const [imageData, setImageData] = useReducer(imageDataReducer, {
    time: performance.now(),
  })

  useEffect(() => {
    ;(async () => {
      const newPreviewTime = throttledMouse * props.duration
      const time = performance.now()
      if (!props.getPreview) return
      try {
        const blob = await props.getPreview(newPreviewTime)
        setImageData({ blob, time })
      } catch (e) {
        setImageData({ time })
      }
    })()
  }, [throttledMouse])

  const [previewUrl, setPreviewUrl] = useState<string>()
  useEffect(() => {
    if (imageData.blob !== undefined) {
      const url = URL.createObjectURL(imageData.blob)
      setPreviewUrl(url)
      return URL.revokeObjectURL.bind(null, url)
    } else {
      setPreviewUrl(undefined)
    }
  }, [imageData])

  const timeIndicatorString = toHoursString((props.duration * mouse) | 0)
  // あまりにも不要な余白が発生しないように文字数に応じて横幅を調整する
  const timeIndicatorWidth = timeIndicatorString.length * 8

  return (
    <div style={{ position: "relative", width: width, height: height }}>
      <div
        style={{
          position: "absolute",
          overflow: "hidden",
          width: "100%",
          height: height,
          cursor: "pointer",
        }}
        ref={seekerBoxElm}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onMouseMove={onMouseMove}
      >
        <ProgressBar
          value={value}
          boldWeight={boldWeight}
          style={{ width: "100%", height: height, position: "absolute" }}
        />

        {!hover ? null : (
          <svg
            style={{
              position: "absolute",
              top: height / 2,
              left: doubleToPercentStr(value),
              transform: "translate(-50%, -50%)",
              ...bounds(height, height),
            }}
            viewBox={`0, 0, ${height}, ${height}`}
          >
            <ellipse
              cx={height / 2}
              cy={height / 2}
              rx={height * 0.5}
              ry={height * 0.5}
              style={{ fill: "red" }}
            />
          </svg>
        )}
      </div>

      {!hover ? null : (
        <>
          {!previewUrl ? null : (
            <img style={{...timePreviewStyle, ...generateCenteredCss(mouse, timePreviewWidth)}} src={previewUrl} alt="" />
          )}
          <Box
            {...timeIndicatorStyle} {...generateCenteredCss(mouse, timeIndicatorWidth)}
            width={timeIndicatorWidth}
          >
            {toHoursString((props.duration * mouse) | 0)}
          </Box>
        </>
      )}
    </div>
  )
}

type ProgressBarProps = {
  value: number
  /**
   * Ratio of height occupied by the bar
   */
  boldWeight: number
  style: CSSProperties
}
function ProgressBar(props: ProgressBarProps): ReactElement {
  const { value, boldWeight, style } = props

  const w = 100
  const h = 10

  const H = h * boldWeight
  const Y = (h - H) / 2
  const L = w * value
  const R = w * (1 - value)

  return (
    <svg style={style} viewBox={`0, 0, ${w}, ${h}`} preserveAspectRatio="none">
      <rect y={Y} height={H} x={0} width={L} style={{ fill: "red" }} />
      <rect
        y={Y}
        height={H}
        x={L}
        width={R}
        style={{ fill: "rgba(180, 180, 180, 0.66)" }}
      />
    </svg>
  )
}
