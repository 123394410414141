import React, { ReactElement } from "react"
import { HBox } from "~parts/box"
import { Loader } from "~parts/loader"
import { formatDateTime } from "~util/format"

// TODO
const feedUrl = "https://api.castify.jp/console_api/v1/feed"

function FeedListContent(props: { src: FeedEntry[] }): ReactElement {
  const items = props.src.map((e) => (
    <a
      key={e.href}
      href={e.href}
      className="list-group-item list-group-item-action"
    >
      <HBox justifyContent="space-between">
        <h6 className="mb-1">{e.title}</h6>
        <small className="text-muted">{e.time}</small>
      </HBox>
      <small className="text-muted">{e.tags.join(", ")}</small>
    </a>
  ))
  return <div className="list-group list-group-flush small">{items}</div>
}

export function FeedList(): ReactElement {
  return (
    <Loader id={feedUrl} of={load} then={(e) => <FeedListContent src={e} />} />
  )
}

type FeedEntry = {
  title: string
  time: string
  href: string
  tags: string[]
}

async function load(url: string): Promise<FeedEntry[]> {
  const res = await fetch(url)
  if (res.status !== 200) {
    throw new Error()
  }
  const out: FeedEntry[] = []
  new DOMParser()
    .parseFromString(await res.text(), "text/xml")
    .querySelectorAll("channel>item")
    .forEach((item) => {
      const title = item.querySelector("title")?.textContent
      const href = item.querySelector("link")?.textContent
      let time = item.querySelector("pubDate")?.textContent
      if (typeof time === "string") {
        const parsed = Date.parse(time)
        if (!isNaN(parsed)) {
          time = formatDateTime(parsed)
        }
      }
      if (title && href && time) {
        const tags: string[] = []
        item.querySelectorAll("category").forEach((e) => {
          if (e.textContent !== null) {
            tags.push(e.textContent)
          }
        })
        out.push({ title, time, href, tags })
      }
    })

  return out
}
