import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { app } from "~context/core"
import { modal } from "~context/modal"
import { Account, AccountCreate } from "~core/castify/api/msg/account"
import { Box, VBox } from "~parts/box"
import { ActionDialog, SenderDialog } from "~parts/dialog"
import { PageBody, PageHeader } from "~parts/header"
import { EmptyIndicator } from "~parts/placeholder"
import { waitForList } from "~parts/spinner"
import { useBinder } from "~util/form"
import { formatDateTime } from "~util/format"
import { useLoader, useSender } from "~util/hook"
import { CastifyRoute } from "~core/castify-route"

export function AccountCreateDialog(props: { onCancel?: () => void, onChange?: () => any, src?: Account }): ReactElement {
  const name = useBinder(props.src?.name ?? "新しい請求先")
  const legalName = useBinder(props.src?.legalName)
  const country = useBinder(props.src?.country ?? "JP")
  const address = useBinder(props.src?.address)
  const phone = useBinder(props.src?.phone)

  const sender = useSender(async () => {
    const request: AccountCreate = {
      name: name.value,
      legalName: legalName.value,
      country: country.value,
      address: address.value,
      phone: phone.value
    }
    const target = props.src?.accountId
    if (target !== undefined) {
      await app.api.updateAccount(target, request)
    }
    else {
      await app.api.createAccount(request)
    }
    return props.onChange?.()
  })

  return (
    <SenderDialog
      title="請求先アカウント"
      sender={sender}
      onEnd={props.onChange}
      onCancel={props.onCancel}
    >
      <VBox minWidth="24rem">
        <Box className="mt-4 mx-4">
          <input
            {...name}
            type="text"
            className="form-control form-control-sm"
            autoComplete="none"
          />
        </Box>
        <h6 className="mt-4 mx-4">請求先の住所</h6>
        <Box className="mt-2 mx-4">
          <label className="text-muted small mb-1">氏名</label>
          <input
            {...legalName}
            type="text"
            className="form-control form-control-sm"
            autoComplete="name"
          />
        </Box>
        <Box className="mt-3 mx-4">
          <label className="text-muted small mb-1">国</label>
          <select {...country} className="form-select form-select-sm" autoComplete="country">
            <option value="JP">日本</option>
          </select>
        </Box>
        <Box className="mt-3 mx-4">
          <label className="text-muted small mb-1">住所</label>
          <textarea {...address} className="form-control form-control-sm" rows={2} autoComplete="address" />
        </Box>
        <Box className="mt-3 mx-4">
          <label className="text-muted small mb-1">TEL</label>
          <input
            {...phone}
            type="tel"
            className="form-control form-control-sm"
            autoComplete="tel"
          />
        </Box>

      </VBox>
    </SenderDialog>
  )
}

function AccountDeleteDialog(props: { src: Account, onChange?: () => void }): ReactElement {
  return (
    <ActionDialog
      action={() => app.api.deleteAccount(props.src.accountId).then(props.onChange)}
      title="請求先アカウントの削除"
    >
      請求先アカウント &quot;{props.src.name}&quot; を削除します。よろしいですか？
    </ActionDialog>
  )
}

function AccountListItem(props: { onChange?: () => void, src: Account }): ReactElement {
  return (
    <div className="col-xl-4 col-md-6 col-sm-12">
      <VBox className="card" margin="1rem 0.5rem">
        <Box className="card-body">
          <Box
            className="card-title h6 text-nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            <Link to={CastifyRoute.accounts.account.getPath(props.src.accountId)}>
              {props.src.name}
            </Link>
          </Box>
          <div className="card-text">
            <table cellPadding="2">
              <tbody>
              <tr>
                <th align="left" style={{ minWidth: "3rem" }}>氏名</th>
                <td className="text-muted">
                  {props.src.legalName}
                </td>
              </tr>
              <tr>
                <th align="left" style={{ minWidth: "3rem" }}>住所</th>
                <td className="text-muted">
                  {props.src.address}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className="btn-group btn-group-sm mt-3">
            <button onClick={modal.bind(<AccountCreateDialog {... props} />)} type="button" className="btn btn-primary">
              登録情報の変更
            </button>
            <button onClick={modal.bind(<AccountDeleteDialog {... props} />)} type="button" className="btn btn-dark">
              削除
            </button>
          </div>
        </Box>
        <div className="card-footer">
          <small className="text-muted">
            {formatDateTime(props.src.updatedAt * 1000)}
          </small>
        </div>
      </VBox>
    </div>
  )
}

export function AccountListPage(): ReactElement {

  const [accounts, reloadAccounts] = useLoader(() => app.api.listAccounts())

  return (
    <>
      <PageHeader title="請求先アカウント">
        <button
          onClick={modal.bind(<AccountCreateDialog onChange={reloadAccounts} />)}
          type="button"
          className="btn btn-primary btn-sm"
        >
          <i className="fas fa-plus" />
        </button>
      </PageHeader>
      <PageBody>
        <Box className="row g-0">
        {
          waitForList(accounts, src => src.map(e => <AccountListItem key={e.accountId} src={e} onChange={reloadAccounts} />)) ?? <EmptyIndicator />
        }
        </Box>
      </PageBody>
    </>
  )
}
