import React, { ReactElement, ReactNode } from "react"
import { Try } from "~util/async"
import { letterBox } from "~util/style"
import { Box } from "./box"

export function Spinner(props: { small?: boolean }): ReactElement {
  const className = props.small
    ? "spinner-border spinner-border-sm"
    : "spinner-border"
  return <div className={className} role="status" />
}

type SpinnerButtonProps = {
  className?: string
  onClick?: () => any
  disabled?: boolean
  animated?: boolean
  type?: "button" | "submit" | "reset"
  children?: ReactNode
}

export function SpinnerButton(props: SpinnerButtonProps): ReactElement {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled || props.animated}
      type={ props.type || "button"}
      className={props.className}
    >
      {props.animated ? <Spinner small={true} /> : props.children}
    </button>
  )
}

export const spinner = (
  <Box height="100%" {...letterBox}>
    <Spinner />
  </Box>
)

export function waitFor<T, E>(
  src: Try<T, E> | undefined,
  _then?: (value: T) => ReactNode,
  _else?: (error: E) => ReactNode
): ReactNode | undefined {
  if (src === undefined) {
    return spinner
  }
  if (!src.failed) {
    return _then?.(src.value)
  } else {
    return _else?.(src.error) ?? <Box>NG</Box>
  }
}

export function waitForList<T, E>(src: Try<T[], E> | undefined, then: (value: T[]) => ReactNode): ReactNode | undefined {
  return waitFor(src, src => {
    if (src.length === 0) {
      return
    } 
    else {
      return then(src)
    }
  })
}
