import { ReactElement, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { app } from "~context/core"
import { modal } from "~context/modal"
import { RecorderTarget, RecordingSession } from "~context/recorder"
import { Box, HBox, VBox } from "~parts/box"
import { PageBody, PageHeader } from "~parts/header"
import { waitFor } from "~parts/spinner"
import { useBinder } from "~util/form"
import { useLoader } from "~util/hook"
import { BroadcastCreateDialog } from "../broadcast/parts/create"

const videoConstraints: MediaTrackConstraintSet = {
  height: {
    ideal: 1080,
  },
  aspectRatio: 16 / 9,
}

const audioConstraints: MediaTrackConstraintSet = {}

type AVCouple = {
  audio?: MediaDeviceInfo
  video?: MediaDeviceInfo
}

function AVPreviewDisplay(props: { src?: MediaStream }): ReactElement {
  const preview = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (preview.current) {
      preview.current.srcObject = props.src ?? null
      preview.current.play()
    }
  }, [props.src, preview.current])

  return (
    <video
      ref={preview}
      width="100%"
      height="100%"
      muted
      style={{ background: "black" }}
    />
  )
}

function AVDeviceSelector(props: { onSelect: (_: AVCouple) => void }) {
  const [devices] = useLoader(async () => {
    // request permissions
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    })
    for (const track of stream.getTracks()) {
      track.stop()
    }
    const audio = new Map<string, MediaDeviceInfo>()
    const video = new Map<string, MediaDeviceInfo>()
    for (const device of await navigator.mediaDevices.enumerateDevices()) {
      switch (device.kind) {
        case "audioinput":
          audio.set(device.deviceId, device)
          break
        case "videoinput":
          video.set(device.deviceId, device)
          break
      }
    }
    return { audio: [...audio.values()], video: [...video.values()] }
  })

  const selectedAudio = useBinder()
  const selectedVideo = useBinder()

  useEffect(() => {
    const audio = devices?.value?.audio.find(
      (e) => e.deviceId === selectedAudio.value
    )
    const video = devices?.value?.video.find(
      (e) => e.deviceId === selectedVideo.value
    )
    props.onSelect({ audio, video })
  }, [selectedAudio.value, selectedVideo.value])

  return (
    <VBox>
      <HBox alignItems="center">
        <Box width="32px" textAlign="center">
          <i className="fas fa-microphone" />
        </Box>
        <Box width="4rem" marginLeft="4px">
          音声
        </Box>
        <Box flexGrow={1}>
          {waitFor(devices, (src) => (
            <select {...selectedAudio} className="form-control form-select">
              <option value="">未選択</option>
              {src.audio.map((e) => (
                <option key={e.deviceId} value={e.deviceId}>
                  {e.label}
                </option>
              ))}
            </select>
          ))}
        </Box>
      </HBox>
      <HBox alignItems="center" className="mt-2">
        <Box width="32px" textAlign="center">
          <i className="fas fa-video" />
        </Box>
        <Box width="4rem" marginLeft="4px">
          映像
        </Box>
        <Box flexGrow={1}>
          {waitFor(devices, (src) => (
            <select {...selectedVideo} className="form-control form-select">
              <option value="">未選択</option>
              {src.video.map((e) => (
                <option key={e.deviceId} value={e.deviceId}>
                  {e.label}
                </option>
              ))}
            </select>
          ))}
        </Box>
      </HBox>
    </VBox>
  )
}

function useNewBroadcast(): [
  RecorderTarget | null | undefined,
  () => Promise<RecorderTarget>
] {
  const location = useLocation()
  const navigate = useNavigate()

  const query = new URLSearchParams(location.search)

  // requested
  const targetId = query.get("target")

  // current
  const [target, setTarget] = useState<RecorderTarget | null>()

  // TODO error
  useEffect(() => {
    // reconcilation: requested > current > request/current (null)
    const current = target?.broadcastId
    if (current !== targetId) {
      if (targetId) {
        app.api.getBroadcast(targetId).then(setTarget)
      } else if (current) {
        navigate(`?target=${encodeURIComponent(current)}`, {replace: true})
      } else if (current === undefined) {
        setTarget(null);
      }
    }
  }, [target?.broadcastId, targetId])

  return [
    target,
    () =>
      new Promise((settledTo, cancel) =>
        modal.show(
          <BroadcastCreateDialog
            onResult={(src) => {
              if (src === undefined) {
                cancel()
              } else {
                setTarget(src)
                settledTo(src)
              }
              return true
            }}
          />
        )
      ),
  ]
}

function useMediaStream(src?: AVCouple): MediaStream | undefined {
  const audio = src?.audio?.deviceId
  const video = src?.video?.deviceId

  const [stream, setStream] = useState<MediaStream>()

  useEffect(() => {
    if (!(audio || video)) {
      setStream(undefined)
      return
    }
    const q: MediaStreamConstraints = {}
    if (audio) q.audio = { deviceId: audio, ...audioConstraints }
    if (video) q.video = { deviceId: video, ...videoConstraints }
    // TODO race cond
    navigator.mediaDevices.getUserMedia(q).then(setStream)
  }, [audio, video])

  useEffect(() => {
    if (stream) {
      return () => {
        for (const e of stream.getTracks()) e.stop()
      }
    }
  }, [stream])

  return stream
}

// noinspection JSUnusedLocalSymbols
export function RecorderScreen(props: {projectId: string}): ReactElement {
  const [target, createTarget] = useNewBroadcast()

  const [recording, setRecording] = useState(false)

  const [selection, setSelection] = useState<AVCouple>()

  const stream = useMediaStream(selection)

  const [health, setHealth] = useState<RTCPeerConnectionState>("closed")

  useEffect(() => {
    if (!recording || stream === undefined || target === undefined) {
      setHealth("closed")
      return
    }
    if (target === null) {
      // TODO race cond
      createTarget().catch(() => setRecording(false))
      return
    }
    const session = new RecordingSession(target, stream)
    session.start()
    session.onStateChange = () => {
      setHealth(session.state)
    }
    return () => session.close()
  }, [recording, stream, target])

  return (
    <VBox>
      <PageHeader title="配信ツール" />
      <PageBody>
        <div className="container mt-2">
          <div className="row g-3">
            <div className="col-sm-12 col-md-7">
              <Box position="relative">
                <AVPreviewDisplay src={stream} />
                <Box position="absolute" left="4px" top="4px">
                  <span className="badge bg-light text-dark">
                    {health.toUpperCase()}
                  </span>
                </Box>
              </Box>
            </div>
            <div className="col-sm-12 col-md-5">
              <VBox height="100%">
                <AVDeviceSelector onSelect={setSelection} />
                <Box marginTop="auto" marginBottom="16px">
                  <fieldset
                    disabled={stream === undefined || target === undefined}
                  >
                    {recording ? (
                      <button
                        className="btn btn-dark"
                        onClick={() => setRecording(false)}
                      >
                        配信を停止
                      </button>
                    ) : (
                      <button
                        className="btn btn-danger"
                        onClick={() => setRecording(true)}
                      >
                        配信を開始
                      </button>
                    )}
                  </fieldset>
                </Box>
              </VBox>
            </div>
          </div>
        </div>
      </PageBody>
    </VBox>
  )
}
