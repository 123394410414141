import React, { ReactElement, ReactNode } from "react"
import { Box, VBox } from "~parts/box"

export function Panel(props: {
  title?: string
  className?: string
  children?: ReactNode
}): ReactElement {
  return (
    <VBox backgroundColor="white" padding="1rem" className={props.className}>
      { props.title && <Box fontSize="18pt">{props.title}</Box> }
      <Box marginTop="0.66rem">{props.children}</Box>
    </VBox>
  )
}
