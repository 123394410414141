import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { app } from "~context/core"
import { formatDateTime } from "~util/format"
import { waitForList } from "~parts/spinner"
import { useLoader } from "~util/hook"
import { Sub } from "~core/castify/api/msg/payment"
import { PageBody, PageHeader } from "~parts/header"
import { planTitles } from "~core/castify/api/msg/plan"
import { ProjectLink } from "~parts/link"
import { modal } from "~context/modal"
import { Empty } from "~parts/placeholder"
import { SubscriptionCancelDialog } from "./dialog"
import { CastifyRoute } from "~core/castify-route"


type SubscriptionProps = {
  accountId: string
  src: Sub
  onChange?(): void
}

function SubscriptionListItem(props: SubscriptionProps): ReactElement {

  return (
    <tr>
      <td>
        <Link to={CastifyRoute.accounts.account.subscriptions.subscription.getPath(props.accountId, props.src.subscriptionId)} className="link-dark">
          {planTitles[props.src.plan]}
        </Link>
      </td>
      <td className="cas-cell-shrink">
        <ProjectLink id={props.src.projectId} />
        <br />
        <span>({props.src.projectId})</span>
      </td>
      <td>
        {formatDateTime(props.src.createdAt)}
      </td>
      <td className="cas-cell-shrink px-3">
        <button onClick={modal.bind(<SubscriptionCancelDialog {...props} />)} className="btn btn-sm btn-danger py-0">
          解約
        </button>
      </td>
    </tr>
  )
}

function SubscriptionList(props: { accountId: string }): ReactElement {

  const [subs, reloadSubs] = useLoader(() => app.api.listSubscriptions(props.accountId))

  const list = waitForList(subs, src => {
    return (
      <table className="cas-table">
        <thead>
        <tr>
          <th>内容</th>
          <th>対象</th>
          <th>開始日時</th>
          <th />
        </tr>
        </thead>
        <tbody>
        {
          src.map((e, i) => (
            <SubscriptionListItem key={i} accountId={props.accountId} src={e} onChange={reloadSubs} />
          ))
        }
        </tbody>
      </table>
    )
  })

  return <>{list ?? <Empty />}</>
}

export function SubscriptionListPage(props: { accountId: string }): ReactElement {
  return (
    <>
      <PageHeader title="課金ステータス" />
      <PageBody>
        <SubscriptionList accountId={props.accountId} />
      </PageBody>
    </>
  )
}
