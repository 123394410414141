import React, { ReactElement } from "react"
import { BehaviorSubject } from "rxjs"
import { ProgressBar } from "~parts/progress"
import { useBehavior } from "~util/form"

let idCounter = 0

class Upload {

  readonly id = ++ idCounter

  private xhr = new XMLHttpRequest

  readonly status = new BehaviorSubject<number | false>(false)

  readonly amount = new BehaviorSubject<number>(0)

  constructor(public name: string, dest: string, blob: Blob, private resolve: (value: (number | false | PromiseLike<number | false>)) => void) {
    this.xhr.addEventListener("loadend", this.loadEnd)
    this.xhr.addEventListener("abort", this.loadEnd)
    this.xhr.addEventListener("error", this.loadEnd)
    this.xhr.upload.addEventListener("progress", this.progress)
    this.xhr.open("PUT", dest, true)
    this.xhr.setRequestHeader("Content-Type", "application/octet-stream")
    this.xhr.send(blob)
  }

  private readonly progress = (e: ProgressEvent) => {
    this.amount.next(e.loaded / e.total)
  }

  private readonly loadEnd = () => {
    this.status.next(this.xhr.readyState === 4 && this.xhr.status)
    this.resolve(this.xhr.readyState === 4 && this.xhr.status)
  }

  close() {
    if (this.xhr.readyState !== 4) {
      this.xhr.abort()
    }
    this.xhr.removeEventListener("loadend", this.loadEnd)
    this.xhr.removeEventListener("abort", this.loadEnd)
    this.xhr.removeEventListener("error", this.loadEnd)
    this.xhr.upload.addEventListener("progress", this.progress)
  }
}

const $uploads = new BehaviorSubject<Upload[]>([])

export function upload(name: string, dest: string, blob: Blob): Promise<number | false> {
  return new Promise((resolve) => {
    $uploads.next(
      $uploads.value.concat(new Upload(name, dest, blob, resolve))
    )
  })
}

export function UploaderUI(): ReactElement {
  const uploads = useBehavior($uploads)
  if (uploads.length === 0) {
    return <React.Fragment />
  }
  return (
    <div className="position-fixed m-2" style={{ bottom: 0, right: 0, minWidth: 300 }}>
      <div className="text-white small px-2 py-1" style={{ background: "#000" }}>
        アップロード状況
      </div>
      <div className="shadow p-1" style={{ bottom: 0, right: 0, background: "#eee" }}>
        <table className="cas-table w-100">
          <thead>
            <tr>
              <th>名前</th>
              <th className="text-nowrap">
                進行状況
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            { uploads.map(e => <UploadListItem key={e.id} src={e} />) }
          </tbody>
        </table>
      </div>
    </div>
  )
}

function UploadListItem(props: { src: Upload }): ReactElement {

  const amount = useBehavior(props.src.amount)
  const status = useBehavior(props.src.status)

  function drop() {
    props.src.close()
    $uploads.next($uploads.value.filter(e => e !== props.src))
  }

  return (
    <tr>
      <td className="d-none d-md-table-cell font-monospace">
        {props.src.name}
      </td>
      <td>
        { status === false ? <ProgressBar rate={amount} stable={status === false} /> :
          status === 200
          ? <span className="badge bg-success">成功</span>
          : <span className="badge bg-danger" >失敗</span>
        }
      </td>
      <td>
        <button onClick={drop} className="btn btn-sm" type="button">
          <i className="fas fa-trash" />
        </button>
      </td>
    </tr>
  )
}
