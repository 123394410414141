import React, { ReactElement } from "react"
import { app } from "~context/core"
import { PredefinedRole } from "~core/castify/api/msg/role"
import { castifyRoles } from "~core/castify/config"
import { Box, VBox } from "~parts/box"
import { SenderDialog } from "~parts/dialog"
import { useBinder } from "~util/form"
import { useSender } from "~util/hook"

export function InvitationCreateDialog(props: {
  onEnd?: () => void
}): ReactElement {
  const role = useBinder(PredefinedRole.OWNER)
  const mail = useBinder()

  const sender = useSender(async () => {
    const email = mail.value
    if (email === "") {
      throw new Error("メールアドレスを入力してください。")
    }
    await app.api.createInvitation({ email, role: role.value })
  })

  return (
    <SenderDialog title="招待の送信" sender={sender} onEnd={props.onEnd}>
      <VBox minWidth="32rem">
        <VBox margin="1rem 1.5rem 0 1.5rem">
          <Box fontSize="10pt" color="#666">
            メールアドレス
          </Box>
          <Box marginLeft="0.5rem" marginTop="0.5rem">
            <input
              {...mail}
              type="text"
              className="form-control"
              placeholder="user@example.test"
            />
          </Box>
        </VBox>
        <VBox margin="1rem 1.5rem 0 1.5rem">
          <Box fontSize="10pt" color="#666">
            ロール
          </Box>
          <Box marginLeft="0.5rem" marginTop="0.5rem">
            <select {...role} className="form-select">
              {castifyRoles.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
          </Box>
        </VBox>
      </VBox>
    </SenderDialog>
  )
}
