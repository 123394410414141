import { TimeSpan } from "~core/castify/api/msg/payment"

type UnitPrefix = {
  name: string
  rate: number
}

const prefixes = ["", "K", "M", "G", "T", "P", "E", "Z", "Y"]

export function chooseUnitPrefix(src: number, kilo: number = 1024): UnitPrefix {
  if (src === 0) {
    return { name: "", rate: 1 }
  }
  const mag = Math.floor(Math.log(src) / Math.log(kilo))
  const name = prefixes[mag]
  if (name === undefined) {
    return { name: "?", rate: 1 }
  }
  return { name, rate: Math.pow(kilo, -mag) }
}

export function formatBytes(src: number, precision: number = 2): string {
  const { name, rate } = chooseUnitPrefix(src, 1024)
  return (src * rate).toFixed(precision) + name
}

function formatDateInternal(src: Date): string {
  return `${src.getFullYear()}-${(src.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${src.getDate().toString().padStart(2, "0")}`
}

export function formatDate(src?: number): string {
  if (!src) {
    return "-"
  } else {
    return formatDateInternal(new Date(src))
  }
}

export function formatDateTime(src?: number, short = false): string {
  if (!src) {
    return "-"
  } else {
    const aux = new Date(src)
    let sep = "-"
    let dateParts: number[]
    let timeParts: number[]
    if (short) {
      sep = "/"
      dateParts = [aux.getMonth() + 1, aux.getDate()]
      timeParts = [aux.getHours(), aux.getMinutes()]
    }
    else {
      dateParts = [aux.getFullYear(), aux.getMonth() + 1, aux.getDate()]
      timeParts = [aux.getHours(), aux.getMinutes(), aux.getSeconds()]
    }
    return dateParts.map(e => `${e}`.padStart(2, "0")).join(sep) + " " +
           timeParts.map(e => `${e}`.padStart(2, "0")).join(":")
  }
}

export function formatPeriod(src: TimeSpan): string {
  return formatDate(src.since) + " / " + formatDate(src.until);
}

/**
 * Converts time duration in seconds to h:mm:ss format (for the duration beyond 3600 seconds) or m:ss
 * @param srcInSeconds pass the time duration in seconds
 * @param padLeading set true to pad with 0 the first part of time.
 *        e.g.) For '1:03', if it is set true, the result will be '01:03'
 */
export function toHoursString(
  srcInSeconds: number,
  padLeading: boolean = false
): string {
  const s = (srcInSeconds | 0) % 60
  srcInSeconds /= 60
  const m = (srcInSeconds | 0) % 60
  srcInSeconds /= 60
  const h = srcInSeconds | 0
  let out = ""
  if (h > 0) {
    out += h.toString().padStart(padLeading ? 2 : 1, "0")
    out += ":" + m.toString().padStart(2, "0")
    out += ":" + s.toString().padStart(2, "0")
  } else {
    out += m.toString().padStart(padLeading ? 2 : 1, "0")
    out += ":" + s.toString().padStart(2, "0")
  }
  return out
}

export function formatDecimal(src: number | string, fractionDigits?: number) {
  if (typeof src === "number") {
    src = src.toFixed(fractionDigits);
  }
  return src.replace(/(.)(?=(\d{3})+(?!\d))/g, "$1,")
}
