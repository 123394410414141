import { ReactElement } from "react"
import { app } from "~context/core"
import { modal } from "~context/modal"
import { Invitation } from "~core/castify/api/msg/project"
import { Box, VBox } from "~parts/box"
import { ActionDialog } from "~parts/dialog"
import { PageBody, PageHeader } from "~parts/header"
import { EmptyIndicator } from "~parts/placeholder"
import { waitForList } from "~parts/spinner"
import { formatDateTime } from "~util/format"
import { useLoader } from "~util/hook"
import { InvitationCreateDialog } from "./create"

function ListItem(props: {
  onDelete: () => void
  src: Invitation
}): ReactElement {
  return (
    <tr key={props.src.id}>
      <td className="cas-cell-shrink">
        <Box paddingRight="0.33rem">
          <input type="checkbox" />
        </Box>
      </td>
      <td>
        <a href={"mailto:" + props.src.email}>{props.src.email}</a>
      </td>
      <td>{props.src.role}</td>
      <td>{props.src.user.displayName}</td>
      <td>{formatDateTime(props.src.createdAt)}</td>
      <td className="cas-cell-shrink">
        <button onClick={props.onDelete} className="btn btn-sm">
          <i className="fas fa-trash text-secondary" />
        </button>
      </td>
    </tr>
  )
}

// noinspection JSUnusedLocalSymbols
export function InvitationList(props: {projectId: string}): ReactElement {
  const [list, reloadList] = useLoader(() => app.api.listInvitation())

  function cancel(src: Invitation) {
    modal.show(
      <ActionDialog
        action={() => app.api.deleteInvitation(src.id)}
        onEnd={reloadList}
      >
        この招待を取り消します。本当によろしいですか？
      </ActionDialog>
    )
  }


  return (
    <VBox position="relative">
      <PageHeader title="未承認の招待">
        <button
          onClick={modal.bind(<InvitationCreateDialog onEnd={reloadList} />)}
          type="button"
          className="btn btn-primary btn-sm"
        >
          <i className="fas fa-plus" />
        </button>
      </PageHeader>
      <PageBody>
      {
        waitForList(list, (src) => (
          <table className="cas-table">
            <thead>
              <tr>
                <th />
                <th>メールアドレス</th>
                <th>ロール</th>
                <th>送信者</th>
                <th>送信日時</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {src.map((e) => (
                <ListItem key={e.id} src={e} onDelete={() => cancel(e)} />
              ))}
            </tbody>
          </table>
        )) ?? <EmptyIndicator />
      }
      </PageBody>
    </VBox>
  )
}
