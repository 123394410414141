import firebase from "firebase/compat/app"
import firebaseui from "firebaseui"
import React, { ReactElement, Suspense } from "react"
import { Link, useLocation } from "react-router-dom"
import { Box } from "~parts/box"
import { Spinner, SpinnerButton } from "~parts/spinner"
import { useBinder } from "~util/form"
import { useSender } from "~util/hook"
import { FirebaseAuth } from "./FirebaseAuth"
import { AlertBox, AuthBox, SuccessfulBox } from "./parts"
import { CastifyRoute } from "~core/castify-route"

const fbAuth = firebase.auth()

const firebaseUIConfig: firebaseui.auth.Config = {
  signInFlow: "popup",
  signInOptions: [
    // https://firebase.google.com/docs/auth/web/firebaseui
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        prompt: "select_account",
      },
    },
    {
      provider: firebase.auth.GithubAuthProvider.PROVIDER_ID,
      customParameters: {
        allow_signup: "true",
      },
    },
  ],
  callbacks: {
    signInSuccessWithAuthResult() {
      return false
    },
  },
}

function PasswordInputForm(): ReactElement {
  const username = useBinder()
  const password = useBinder()

  const empty = username.value === "" || password.value === ""

  const sender = useSender(async () => {
    // TODO: https://firebase.google.com/docs/auth/admin/errors?hl=ja
    await fbAuth.signInWithEmailAndPassword(username.value, password.value)
  })
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <fieldset disabled={!sender.loaded}>
        <Box className="input-group">
          <span className="input-group-text">
            <i className="fas fa-user" />
          </span>
          <input
            {...username}
            type="text"
            className="form-control"
            placeholder="メールアドレス"
          />
        </Box>
        <Box className="input-group" marginTop="0.66rem">
          <span className="input-group-text">
            <i className="fas fa-lock" />
          </span>
          <input
            {...password}
            type="password"
            className="form-control"
            placeholder="パスワード"
          />
        </Box>
        <Box marginTop="0.2rem" marginBottom="1rem" textAlign="right" fontSize="0.7rem">
          <Link to="?mode=reset">パスワードをお忘れの方</Link>
        </Box>
        <Box marginTop="0.5rem">
          <button
            onClick={() => sender.send()}
            disabled={empty}
            className="form-control btn btn-primary"
            type="submit"
          >
            ログイン
          </button>
        </Box>
        {sender.result?.failed && (
          <Box marginTop="1.5rem">
            <AlertBox>
              {sender.result?.error?.message ?? "Failed to login."}
            </AlertBox>
          </Box>
        )}
      </fieldset>
    </form>
  )
}

function PasswordResetForm(): ReactElement {
  const username = useBinder()

  const empty = username.value === ""

  const sender = useSender(async () => {
    await fbAuth.sendPasswordResetEmail(username.value)
  })

  if (sender.result?.failed === false) {
    return (
      <SuccessfulBox>
        パスワード再設定メールを送信しました。メールボックスを確認して、手続きを完了してください。
      </SuccessfulBox>
    )
  }

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Box className="input-group">
        <span className="input-group-text">
          <i className="fas fa-user" />
        </span>
        <input
          {...username}
          type="text"
          className="form-control"
          placeholder="メールアドレス"
        />
      </Box>
      <Box marginTop="1.5rem">
        <SpinnerButton
          onClick={sender.send}
          disabled={empty}
          animated={!sender.loaded}
          className="form-control btn btn-primary"
        >
          パスワード再設定メールを送信
        </SpinnerButton>
      </Box>
      {sender.result?.failed && (
        <Box marginTop="1.5rem">
          <AlertBox>
            {sender.result?.error.message || "メール送信に失敗しました。"}
          </AlertBox>
        </Box>
      )}
    </form>
  )
}

function LoginForm(props: { mode: string | null }): ReactElement {
  switch (props.mode) {
    case "password": {
      return (
        <React.Fragment>
          <Box marginTop="1rem">
            <PasswordInputForm />
          </Box>
          <Box marginTop="1rem" textAlign="center">
            <Link to="?">Google/GitHub アカウントでログイン</Link>
          </Box>
        </React.Fragment>
      )
    }
    case "reset": {
      return (
        <Box marginTop="1rem">
          <PasswordResetForm />
        </Box>
      )
    }
    default: {
      return (
        <React.Fragment>
          <Box marginTop="1rem">
            <Suspense
              fallback={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Spinner />
                </div>
              }
            >
              <FirebaseAuth uiConfig={firebaseUIConfig} firebaseAuth={fbAuth} />
            </Suspense>
          </Box>
          <Box marginTop="1rem" fontSize="0.7rem" textAlign="center">
            <Link to="?mode=password">パスワードでログイン</Link>
          </Box>
        </React.Fragment>
      )
    }
  }
}

export function LoginPage(): ReactElement {
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  return (
    <AuthBox>
      <LoginForm mode={query.get("mode")} />
      <hr />
      <Box fontSize="0.7rem">
        <p className="text-muted">
          Google/GitHub アカウントをご利用の場合、登録の手続きは不要です。
          メールアドレス／パスワードの登録をご希望の場合は
          <Link to={CastifyRoute.register.pathWithFrom(location.pathname)}>
            こちら
          </Link>
          。
        </p>
      </Box>
    </AuthBox>
  )
}
