import React, { ReactElement, ReactNode, useState } from "react"
import { Box } from "~parts/box"

export function PopOverList(props: { children: ReactNode[] }): ReactElement {
  const [hidden, setHidden] = useState(true)

  if (props.children.length <= 1) {
    return <Box>{props.children}</Box>
  }

  return (
    <Box position="relative">
      {props.children[0]}
      <Box fontSize="66%">
        <a
          className="anchor"
          href="#"
          onMouseEnter={() => setHidden(false)}
          onMouseLeave={() => setHidden(true)}
        >
          + {props.children.length - 1} item(s)
        </a>
      </Box>
      <ul
        hidden={hidden}
        className="position-fixed list-group border small"
        style={{ backgroundColor: "#fff" }}
      >
        {props.children.map((e, i) => (
          <li key={i} className="list-group-item border-0">
            {e}
          </li>
        ))}
      </ul>
    </Box>
  )
}
